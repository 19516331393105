import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { RcFile } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { FILE_API, UPLOAD_FILE_API, UPLOAD_FILE_PDF_API } from "shared/constants/api-endpoint";
import { IMAGES_FOLDER_ID } from "shared/constants/google-drive";
import { postHttp } from "shared/plugins/http-service";
import { redirectFileLocalUrl, redirectFileUrl } from "shared/plugins/string-helper";

interface UploadFilePDFProps {
  maxFileSize?: number; //MB
  defaultFileList: string[];
  multiple?: boolean;
  maxFileCount?: number;
  onChange: (newFileList: string[]) => void;
}

export function UploadFileLocalPdf(props: UploadFilePDFProps) {
  const {
    defaultFileList,
    maxFileSize,
    multiple,
    onChange,
  } = props;

  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng =
        file.type === "application/pdf";
      if (!isJpgOrPng) {
        message.error("Bạn chỉ có thể tải file PDF");
        return false;
      }
    if (maxFileSize) {
      const isLt2M = file.size / 1024 / 1024 < maxFileSize;
      if (!isLt2M) {
        message.error("Kích thước file nhỏ hơn 2MB");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setFileList(
      defaultFileList.map((m, index) => ({
        uid: index,
        name: m,
        status: "done",
        url: redirectFileLocalUrl(m),
      }))
    );
  }, [defaultFileList]);

  return (
      <Upload 
        multiple={multiple}
        fileList={fileList ?? []}
        defaultFileList={fileList ?? []}
        beforeUpload={beforeUpload}
        customRequest={({ onSuccess, onError, file }) => {
          const formData = new FormData();
          formData.append("uploadedFile", file);
          postHttp(`${process.env.REACT_APP_API_URI}${UPLOAD_FILE_PDF_API}`, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          }).then((result) => {
            onSuccess(result.data, file);
          });
        }}
        onChange={({ fileList }) => {
          setFileList(fileList.filter((m) => !!m.status));
          if (
            fileList.length === fileList.filter((m) => m.status === "done").length
          ) {
            onChange(fileList.filter(m=> (m.response !== undefined)).map((m) => m.response));
          }
        }}
        showUploadList={true}
      >
      <Button icon={<UploadOutlined />}>Tải file PDF</Button>
    </Upload>
  );
}

