import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
} from "antd";

import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";

import { useDebouncedCallback } from "use-debounce/lib";
import {
  RemovedStaticPageModel,
  SearchStaticPageRequestModel,
  StaticPageModel,
} from "states/static-page/static-page.models";
import {
  removeStaticPage,
  searchStaticPage,
} from "states/static-page/static-page.api";
import { Link } from "react-router-dom";

interface IStaticPageProps {}

const StaticPage: FC<IStaticPageProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedStaticPageModel>({
    idList: [],
  });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setpagedResults] = useState(
    {} as PagedResult<StaticPageModel>
  );
  const [filterModel, setFilterModel] = useState<SearchStaticPageRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);

  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchStaticPage({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setpagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={() => {
          removeStaticPage(selectedRowsKeys)
            .then(() => {
              notification.close(key);
              setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
              handleSearch.callback();
              message.success("Xóa thành công");
            })
            .catch(() => {
              message.error("Xóa thất bại");
            });
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: "Bạn có chắc chắn muốn xóa không?",
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<StaticPageModel> = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string, item) => (
        <React.Fragment>
          <Link to={`/static-page/update/${item.id}`}>{title}</Link>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="static-page">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.title}
            placeholder="Nhập tên trang cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, title: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Link to="/static-page/add">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Thêm trang
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default StaticPage;
