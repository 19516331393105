import React, { FC, useState } from "react";
import { Divider, Layout, Menu } from "antd";
import Icon, {
  ContactsOutlined,
  DollarOutlined,
  FileImageOutlined,
  FileTextOutlined,
  GiftOutlined,
  IdcardOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./layout-app.less";
import { ReactComponent as ProductOutlined } from "themes/icons/product.svg";
import { ReactComponent as CustomerOutlined } from "themes/icons/customer-o.svg";
import { ReactComponent as ShippingOutlined } from "themes/icons/shipping.svg";
import { Link } from "react-router-dom";
import HeaderApp from "./header-app";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "app.store";
import SubMenu from "antd/lib/menu/SubMenu";

const { Sider, Content } = Layout;
type LayoutAppProps = any;

const LayoutApp: FC<LayoutAppProps> = (props) => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const isLogin = useSelector(
    (state: RootState) => state.account.isAuthentication,
    shallowEqual
  );
  return isLogin ? (
    <Layout hasSider={true} className="layout-page">
      <Sider
        width="230px"
        className="main-sider"
        collapsible
        collapsed={menuCollapse}
        onCollapse={(collapsed) => {
          setMenuCollapse(collapsed);
        }}
      >
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
        </div>
        <Menu className="nav" mode="inline">
          <Menu.Item key="order" icon={<DollarOutlined />}>
            <Link to="/orders">Quản lí đơn hàng</Link>
          </Menu.Item>
          <Menu.Item key="contact" icon={<ContactsOutlined />}>
            <Link to="/contact">Liên hệ</Link>
          </Menu.Item>
          <SubMenu
            key="customersub"
            icon={<Icon component={CustomerOutlined} />}
            title="Khách hàng"
          >
            <Menu.Item>
              <Link to="/customers">Danh sách khách hàng</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/customer-group">Nhóm khách hàng</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="product-comment" icon={<StarOutlined />}>
            <Link to="/product-comment">Bình luận - Đánh giá</Link>
          </Menu.Item>
          <Menu.Item key="coupon" icon={<GiftOutlined />}>
            <Link to="/coupon">Mã giảm giá</Link>
          </Menu.Item>
          <SubMenu
            key="productsub"
            icon={<Icon component={ProductOutlined} />}
            title="Sản phẩm"
          >
            <Menu.Item>
              <Link to="/product">Danh sách sản phẩm</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-category">Danh mục sản phẩm</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-label">Nhãn sản phẩm</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-price-filter">Khoảng giá sản phẩm</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-attribute-group">Nhóm thuộc tính</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-attribute">Thuộc tính</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/product-attribute-value">Giá trị thuộc tính</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/tag">Tag</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu icon={<FileTextOutlined />} key="blogsub" title="Tin tức">
            <Menu.Item>
              <Link to="/blog">Bài viết</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/blog-category">Danh mục bài viết</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="static-page" icon={<FileTextOutlined />}>
            <Link to="/static-page">Trang tĩnh</Link>
          </Menu.Item>
          <Divider></Divider>

          <Menu.Item key="theme-setting" icon={<FileImageOutlined />}>
            <Link to="/theme-setting">Quản lí giao diện</Link>
          </Menu.Item>
          <Menu.Item key="notification-manager" icon={<NotificationOutlined />}>
            <Link to="/notification-managers">Quản lí thông báo</Link>
          </Menu.Item>
          <Menu.Item key="menu-setting" icon={<MenuUnfoldOutlined />}>
            <Link to="/menu-setting">Quản lí menu </Link>
          </Menu.Item>
          <Menu.Item key="website-config" icon={<FileImageOutlined />}>
            <Link to="/website-config">
              Cấu hình website
            </Link>
          </Menu.Item>
          <Menu.Item key="website-information" icon={<FileImageOutlined />}>
            <Link to="/website-information">
              Thông tin website
            </Link>
          </Menu.Item>
          <Menu.Item icon={<Icon component={ShippingOutlined} />}>
            <Link to="/shipping-fee/default">Phí ship</Link>
          </Menu.Item>
          <SubMenu key="usersub" icon={<UserOutlined />} title="Nhân viên">
            <Menu.Item key="staff">
              <Link to="/staff">Danh sách nhân viên</Link>
            </Menu.Item>
            <Menu.Item key="role-management">
              <Link to="/role-management">Phân quyền</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <HeaderApp />
        <Content className="layout-content">{props.children}</Content>
      </Layout>
    </Layout>
  ) : null;
};

export default LayoutApp;
