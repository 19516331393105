import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Divider,
  Input,
  notification,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import {
  ITemplate,
  IThemeConfigImageItem,
  IThemeConfigTextImageList,
  IThemeConfigTextItem,
  ThemeType,
} from "states/theme-setting/theme-setting.models";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { ThemeImageItem } from "./theme-image-item";
import { ThemeTextItem } from "./theme-text-item";

interface ThemeConfigProps {
  model: IThemeConfigTextImageList;
  onChange: (model: IThemeConfigTextImageList) => void;
  onRemove?: (index: number) => void;
  index?: number;
}
export const ThemeTextImageList = (props: ThemeConfigProps) => {
  const { model, onChange, onRemove, index } = props;

  const _handleRemoveItem = () => {
    model.data = model.data.filter((item: ITemplate) => {
      return !item.isChecked;
    });
    onChange({ ...model });
  };

  const removeItem = () => {
    onRemove && onRemove(index || 0);
  };
  const _handleReorder = () => {
    model.data.sort((a: any, b: any) => {
      return a.Sort > b.Sort ? 1 : b.Sort > a.Sort ? -1 : 0;
    });
    onChange({ ...model });
  };
  return (
    <div>
      <div className="theme-text-image-list">
        <Button icon={<CopyOutlined />} type="default" className="mr-2">
          {model.name}
        </Button>
        <Button
          style={{ marginRight: "10px" }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={(ev) => {
            let newModel = {
              sort:
                model.data.length > 0
                  ? model.data[model.data.length - 1].sort + 1
                  : 0,
              isChecked: false,
              imageList: [] as any[],
              textList: [] as any[],
            };
            model.template.imageList.forEach((img) => {
              let newImg = {} as IThemeConfigImageItem;
              newImg.name = img;
              newModel.imageList.push(newImg);
            });
            model.template.textList.forEach((txt) => {
              let newTxt = {} as IThemeConfigTextItem;
              newTxt.name = txt.name;
              newTxt.textType = txt.textType;
              newTxt.themeType = ThemeType.ThemeConfigTextItem;
              newTxt.value = "";
              newModel.textList.push(newTxt);
            });
            model.data.push(newModel);
            onChange({ ...model });
          }}
        >
          Thêm phần tử
        </Button>
        <Popconfirm
          placement="topLeft"
          onConfirm={_handleRemoveItem}
          title={`Bạn có chắc chắn muốn xóa không?`}
          okText="Đồng ý"
          cancelText="Hủy bỏ"
        >
          <Button className="mr-2" icon={<DeleteOutlined />} danger>
            Xóa
          </Button>
        </Popconfirm>

        <Popconfirm
          placement="topLeft"
          onConfirm={removeItem}
          title={`Bạn có chắc chắn muốn xóa không?`}
          okText="Đồng ý"
          cancelText="Hủy bỏ"
        >
          <Button
            className="mr-2"
            type="primary"
            danger
            icon={<DeleteOutlined />}
          >
            Xóa danh sách
          </Button>
        </Popconfirm>
      </div>
      <table className="table-customize mt-4">
        <thead>
          <tr>
            <td className="checklist">
              <Checkbox
                onChange={(ev: any) => {
                  model.data = model.data.map((data) => ({
                    ...data,
                    isChecked: ev.target.checked,
                  }));
                  onChange({ ...model });
                }}
              />
            </td>
            <td className="stt">STT</td>
            {model?.template.imageList.map((m) => (
              <td className="t-image">{m}</td>
            ))}
            {model?.template.textList.map((m) => (
              <td>{m.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {model.data.map((item, index) => (
            <tr key={index}>
              <td className="checklist">
                <Checkbox
                  checked={item.isChecked}
                  onChange={(ev: any) => {
                    item.isChecked = !item.isChecked;
                    onChange({ ...model });
                  }}
                />
              </td>
              <td className="stt">
                <Input
                  type="number"
                  value={item.sort}
                  onChange={(e) => {
                    item.sort = Number(e.target.value);
                    onChange({ ...model });
                    _handleReorder();
                  }}
                />
              </td>
              {item.imageList.map((m, i) => (
                <td className="t-image text-center">
                  <ThemeImageItem
                    key={i}
                    model={m}
                    index={i}
                    isHideRemove={true}
                    isHideLabel={true}
                    onChange={(newModel) => {
                      item.imageList[i] = newModel;
                      onChange({ ...model });
                    }}
                  />
                </td>
              ))}
              {item.textList.map((m, i) => (
                <td>
                  <ThemeTextItem
                    key={i}
                    index={i}
                    model={m}
                    isHideRemove={true}
                    isHidelabel={true}
                    onChange={(newModel) => {
                      item.textList[i] = newModel;
                      onChange({ ...model });
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
