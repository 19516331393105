import React, { useState } from "react";

import { DefaultErrorMessage } from "shared/constants/message";
import Modal from "antd/lib/modal";
import {
  IAddUpdateItem,
  IThemeConfigImageItem,
  IThemeConfigImageList,
  IThemeConfigModel,
  IThemeConfigTextImageList,
  IThemeConfigTextItem,
  ThemeType,
} from "states/theme-setting/theme-setting.models";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Popconfirm,
  Row,
  Select,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ThemeImageItem } from "./theme-image-item";
import { ThemeTextItem } from "./theme-text-item";
import { ThemeTextImageList } from "./theme-text-image-list";
import "./style/theme-config-item.less";
import _ from "lodash";
import { ThemeImageList } from "./theme-image-list";
interface ThemeConfigProps {
  model: IThemeConfigModel;
  onChange: (model: IThemeConfigModel) => void;
  isShowKeyInput: boolean;
  isShowDelete?: boolean;
  onRemove?: (index: number) => void;
  index?: number;
}
const { Panel } = Collapse;
const { Option } = Select;
export const ThemeConfigItem = (props: ThemeConfigProps) => {
  const {
    model,
    isShowKeyInput,
    isShowDelete,
    index,
    onChange,
    onRemove,
  } = props;
  const [itemModel, setItemModel] = useState<IAddUpdateItem>(
    {} as IAddUpdateItem
  );
  const [toggleModal, setToggleModal] = React.useState<boolean>(false);
  const _handleAddItem = () => {
    if (
      !_.has(itemModel, "name") ||
      itemModel.name === "" ||
      itemModel.name === null
    ) {
      Modal.error({
        title: "Thông báo",
        content: "Vui lòng nhập tên" ?? DefaultErrorMessage,
      });
      return false;
    }
    if (!_.has(itemModel, "type")) {
      Modal.error({
        title: "Thông báo",
        content: "Vui lòng chọn nhóm" ?? DefaultErrorMessage,
      });
      return false;
    }
    if (
      _.has(itemModel, "type") &&
      itemModel.type === ThemeType.ThemeConfigTextItem &&
      !_.has(itemModel, "textType")
    ) {
      Modal.error({
        title: "Thông báo",
        content: "Vui lòng chọn loại text" ?? DefaultErrorMessage,
      });
      return false;
    }
    let new_model = { ...model };
    if (!new_model.data) {
      new_model.data = [];
    }
    let add_model;
    switch (itemModel.type) {
      case ThemeType.ThemeConfigModel:
        add_model = {} as IThemeConfigModel;
        add_model.name = itemModel.name;
        add_model.themeType = itemModel.type;
        break;
      case ThemeType.ThemeConfigImageItem:
        add_model = {} as IThemeConfigImageItem;
        add_model.name = itemModel.name;
        add_model.themeType = itemModel.type;
        break;
      case ThemeType.ThemeConfigTextItem:
        add_model = {} as IThemeConfigTextItem;
        add_model.name = itemModel.name;
        add_model.themeType = itemModel.type;
        add_model.textType = itemModel.textType;
        break;
      case ThemeType.ThemeConfigImageList:
        add_model = {} as IThemeConfigImageList;
        add_model.name = itemModel.name;
        add_model.themeType = itemModel.type;
        add_model.data = [];
        break;
      case ThemeType.ThemeConfigTextImageList:
        setToggleModal(true);
        return true;
    }
    new_model.data.push(add_model as any);
    onChange({ ...model, data: new_model.data });
  };
  const removeItem = () => {
    onRemove && onRemove(index || 0);
  };
  //#endregion

  return (
    <React.Fragment>
      <div className="p-2 mt-2">
        <Collapse defaultActiveKey={["1"]} expandIconPosition={`right`}>
          <Panel
            header={
              <div className="header-collapse d-flex justify-content-between align-items-center flex-auto ">
                <Input
                  value={model?.name ?? ""}
                  placeholder="Theme Name"
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                  onChange={(ev) => {
                    ev.stopPropagation();
                    onChange({ ...model, name: ev.target.value });
                  }}
                />
                {isShowKeyInput && (
                  <Input
                    placeholder="THEME_NAME KEY (*)"
                    value={model?.slug ?? ""}
                    onClick={(ev) => {
                      ev.stopPropagation();
                    }}
                    onChange={(ev) => {
                      ev.stopPropagation();
                      onChange({
                        ...model,
                        slug: ev.target.value.toUpperCase(),
                      });
                    }}
                  />
                )}
              </div>
            }
            key="1"
          >
            <div>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <Input
                    value={itemModel?.name ?? ""}
                    placeholder="Tên"
                    onChange={(e) => {
                      setItemModel({ ...itemModel, name: e.target.value });
                    }}
                  />
                </Col>
                <Col span={3}>
                  <Select
                    showSearch
                    className="w-100"
                    placeholder="Nhóm"
                    onChange={(value: number) => {
                      setItemModel({
                        ...itemModel,
                        type: value,
                      });
                    }}
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={ThemeType.ThemeConfigModel}>Group</Option>
                    <Option value={ThemeType.ThemeConfigImageItem}>
                      Image
                    </Option>
                    <Option value={ThemeType.ThemeConfigTextItem}>Text</Option>
                    <Option value={ThemeType.ThemeConfigImageList}>
                      Image List
                    </Option>
                    <Option value={ThemeType.ThemeConfigTextImageList}>
                      Text Image List
                    </Option>
                  </Select>
                </Col>
                {itemModel.type === ThemeType.ThemeConfigTextItem && (
                  <Col span={3}>
                    <Select
                      showSearch
                      className="w-100"
                      placeholder="Text Style"
                      optionFilterProp="children"
                      onChange={(value: number) => {
                        setItemModel({
                          ...itemModel,
                          textType: value,
                        });
                      }}
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={0}>Normal</Option>
                      <Option value={1}>Html</Option>
                      <Option value={2}>Link</Option>
                    </Select>
                  </Col>
                )}
                <Col span={3}>
                  <div className="btn-w d-flex justify-content-between align-items-center flex-auto ">
                    <Button
                      type="primary"
                      onClick={_handleAddItem}
                      icon={<PlusOutlined />}
                    >
                      Thêm
                    </Button>
                    {isShowDelete && (
                      <Popconfirm
                        placement="topLeft"
                        onConfirm={removeItem}
                        title={`Bạn có muốn xóa nhóm`}
                        okText="Đồng ý"
                        cancelText="Hủy bỏ"
                      >
                        <Button
                          type="primary"
                          danger={true}
                          icon={<DeleteOutlined />}
                        >
                          Xóa Nhóm
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                </Col>
              </Row>
              <div>
                {model.data &&
                  model.data.map((ele: any, index: number) => {
                    if (ele.themeType === ThemeType.ThemeConfigModel) {
                      return (
                        <React.Fragment>
                          <ThemeConfigItem
                            model={ele as IThemeConfigModel}
                            index={index}
                            isShowDelete={true}
                            isShowKeyInput={false}
                            onRemove={(index) => {
                              model.data.splice(index, 1);
                              onChange({ ...model });
                            }}
                            onChange={(newmodel) => {
                              model.data[index] = newmodel;
                              onChange({ ...model });
                            }}
                          />
                          <Divider style={{ borderTop: "#000",background: "#000",width: "100%",height: "1px" }} />
                        </React.Fragment>
                      );
                    } else if (
                      ele.themeType === ThemeType.ThemeConfigImageItem
                    ) {
                      return (
                        <React.Fragment>
                          <ThemeImageItem
                            model={ele as IThemeConfigImageItem}
                            index={index}
                            onRemove={(ix) => {
                              model.data.splice(ix, 1);
                              onChange({ ...model });
                            }}
                            onChange={(newmodel) => {
                              model.data[index] = newmodel;
                              onChange({ ...model });
                            }}
                          />
                          <Divider style={{ borderTop: "#000",background: "#000",width: "100%",height: "1px" }} />
                        </React.Fragment>
                      );
                    } else if (
                      ele.themeType === ThemeType.ThemeConfigTextItem
                    ) {
                      return (
                        <React.Fragment>
                          <ThemeTextItem
                            model={ele as IThemeConfigTextItem}
                            index={index}
                            onRemove={(ix) => {
                              model.data.splice(ix, 1);
                              onChange({ ...model });
                            }}
                            onChange={(newmodel: any) => {
                              model.data[index] = newmodel;
                              onChange({ ...model });
                            }}
                          />
                          <Divider style={{ borderTop: "#000",background: "#000",width: "100%",height: "1px" }} />
                        </React.Fragment>
                      );
                    } else if (
                      ele.themeType === ThemeType.ThemeConfigImageList
                    ) {
                      return (
                        <React.Fragment>
                          <ThemeImageList
                            model={ele as IThemeConfigImageList}
                            index={index}
                            onRemove={(ix) => {
                              model.data.splice(ix, 1);
                              onChange({ ...model });
                            }}
                            onChange={(newmodel) => {
                              model.data[index] = newmodel;
                              onChange({ ...model });
                            }}
                          />
                          <Divider style={{ borderTop: "#000",background: "#000",width: "100%",height: "1px" }} />
                        </React.Fragment>
                      );
                    } else if (
                      ele.themeType === ThemeType.ThemeConfigTextImageList
                    ) {
                      return (
                        <React.Fragment>
                          <ThemeTextImageList
                            model={ele as IThemeConfigTextImageList}
                            index={index}
                            onRemove={(ix) => {
                              model.data.splice(ix, 1);
                              onChange({ ...props.model });
                            }}
                            onChange={(newmodel) => {
                              model.data[index] = newmodel;
                              onChange({ ...model });
                            }}
                          />
                          <Divider style={{ borderTop: "#000",background: "#000",width: "100%",height: "1px" }} />
                        </React.Fragment>
                      );
                    }
                  })}
              </div>
              <Modal
                title="Text Image "
                visible={toggleModal}
                onOk={() => {
                  if (
                    itemModel.imageTemplate === "" &&
                    itemModel.textTemplate === ""
                  ) {
                    Modal.info({
                      title: "Thông báo",
                      content: "Vui lòng nhập tên",
                    });
                    return false;
                  }
                  let imgList = itemModel.imageTemplate
                    ? itemModel.imageTemplate.split(";")
                    : [];
                  let textList = itemModel.textTemplate
                    ? itemModel.textTemplate.split(";")
                    : [];
                  let textTemplate: any[] = [];
                  if (textList.length > 0) {
                    textList.forEach((element) => {
                      let splits: string[] = element.split(":");
                      if (splits.length !== 2 || isNaN(parseInt(splits[1])))
                        return;
                      textTemplate.push({
                        name: splits[0],
                        textType: parseInt(splits[1]),
                      });
                    });
                  }
                  let add_model = {
                    name: "",
                    slug: "",
                    themeType: ThemeType.ThemeConfigTextImageList,
                    sort: 0,
                    data: [],
                    template: {
                      imageList: [],
                      sort: 0,
                      isChecked: false,
                      textList: [],
                    },
                  } as IThemeConfigTextImageList;
                  add_model.name = itemModel.name;
                  add_model.template.imageList = imgList;
                  add_model.template.textList = textTemplate;
                  model.data.push(add_model);
                  onChange({ ...model });
                  setToggleModal(false);
                }}
                onCancel={() => {
                  setToggleModal(false);
                }}
              >
                <Input
                  className="mb-2"
                  placeholder="Name;Name.."
                  value={itemModel?.imageTemplate ?? ""}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    ev.preventDefault();
                    itemModel.imageTemplate = ev.target.value;
                    setItemModel({ ...itemModel });
                  }}
                />
                <Input
                  placeholder="Name:0-1-2;Name:0-1-2.."
                  value={itemModel?.textTemplate ?? ""}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    ev.preventDefault();
                    itemModel.textTemplate = ev.target.value;
                    setItemModel({ ...itemModel });
                  }}
                />
              </Modal>
            </div>
          </Panel>
        </Collapse>
      </div>
    </React.Fragment>
  );
};
