import React, { FC, useState, useEffect } from "react";
import { Input, Select, Modal, Form, DatePicker } from "antd";
import { TreatmentStatus } from "states/treatment/treatment.models";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  CreatedTreatmentNotificationModel,
  TreatmentNotificationModel,
  UpdatedTreatmentNotificationModel,
} from "states/treatment/treatment-notification.models";
import {
  addTreatmentNotification,
  updateTreatmentNotification,
} from "states/treatment/treatment-notification.api";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { NotiType } from "states/notification-manager/notification-manager.models";

interface ITreatmentNotificationModalProps {
  model: TreatmentNotificationModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  treatmentId: string;
  onSuccess: () => void;
}
const { Option } = Select;
export const TreatmentNotificationModalComponent: FC<ITreatmentNotificationModalProps> = (
  props
) => {
  const {
    model,
    onModal,
    treatmentId,
    closeModal,
    onSuccess,
    isUpdate,
  } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedTreatmentNotificationModel = {
          ...values,
          id: values.id,
          message: values.message,
          startDate: values.startDate,
          endDate: values.endDate,
          repeatDay: values.repeatDay,
          notiType: values.notiType,
          treatmentId: Number(treatmentId),
          title: values.title,
        };
        await updateTreatmentNotification(payload);
      } else {
        let payload: CreatedTreatmentNotificationModel = {
          ...values,
          message: values.message,
          startDate: values.startDate,
          endDate: values.endDate,
          repeatDay: values.repeatDay,
          notiType: values.notiType,
          treatmentId: Number(treatmentId),
          title: values.title,
        };
        await addTreatmentNotification(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onStatusChange = (value: TreatmentStatus) => {
    form.setFieldsValue({ status: value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      startDate: moment(model?.startDate),
      endDate: moment(model?.endDate),
      repeatDay: model?.repeatDay ?? 0,
      notiType: model?.notiType,
      treatmentId: treatmentId,
      title: model?.title,
      message: model?.message,
    });
  };
  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate
            ? `Cập nhật nhắc lịch điều trị`
            : `Thêm nhắc lịch điều trị mới`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="treatmentNotificationModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày bắt đầu" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày bắt đầu"
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày kết thúc" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày kết thúc"
          />
        </Form.Item>
        <Form.Item label="Số ngày lặp lại" name="repeatDay">
          <Input
            type="number"
            className="w-100"
            placeholder="Số ngày lặp lại"
          />
        </Form.Item>
        <Form.Item label="Tiêu đề" name="title">
          <Input className="w-100" placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item label="Nội dung" name="message">
          <TextArea className="w-100" placeholder="Nội dung" />
        </Form.Item>
        <Form.Item
          label="Loại tin nhắn"
          name="notiType"
          rules={[{ required: true, message: "Vui lòng chọn loại tin nhắn" }]}
        >
          <Select
            className="w-100"
            placeholder="Chọn tin nhắn"
            onChange={onStatusChange}
            allowClear
          >
            <Option value={NotiType.Schedule}>Nhắc đến hẹn điều trị</Option>
            <Option value={NotiType.SendImageSchedule}>
              Nhắc gửi kết quả điều trị
            </Option>
          </Select>
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
