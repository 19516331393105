import { ProductPriceFilterModel } from "states/product-price-filter/product-price-filter.models";

export interface ProductCategoryModel {
  key: string;
  id: number;
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyword: string;
  description: string;
  avatar: string;
  themeId?: number;
  priceFilters: ProductPriceFilterModel[];
  attributeFilters: ProductCategoryProductAttributeModel[];
}

export interface CreateProductCategoryModel {
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyword: string;
  description: string;
  avatar: string;
  themeId?: number;
  priceFilters: number[];
  attributeFilters: ProductCategoryProductAttributeModel[];
}

export interface UpdatedProductCategoryModel {
  id: number;
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyword: string;
  description: string;
  avatar: string;
  themeId?: number;
  priceFilters: number[];
  attributeFilters: ProductCategoryProductAttributeModel[];
}

export interface SearchProductCategoryRequestModel {
  name?: string;
  page: number;
  pageSize: number;
}

export interface RemovedProductCategoryModel {
  idList: number[];
}

export interface ProductCategoryProductAttributeModel {
  attributeId: number;
  attributeName: string;
  sort: number;
  logicalTerm: LogicalTermEnum;
  values: ProductCategoryProductAttributeValueModel[];
}
export interface ProductCategoryProductAttributeValueModel {
  valueId: number;
  value: string;
  sort: number;
}

export enum LogicalTermEnum {
  Or,
  And,
}
