import React, { FC, useState, useEffect } from "react";
import { Input, Select, Modal, Form,  Switch, DatePicker } from "antd";

import {
  CreateCustomerModel,
  CustomerModel,
  UpdatedCustomerModel,
} from "states/customer/customer.models";
import { addCustomer, updateCustomer } from "states/customer/customer.api";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";

interface ICustomerModalProps {
  model: CustomerModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const CustomerModalComponent: FC<ICustomerModalProps> = (props) => {
  const { model, onModal, closeModal,onSuccess, isUpdate } = props;
  const [form] = useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedCustomerModel = {
          ...values,
          id: values.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          birthDay: values.birthDay ? moment(values.birthDay).format("DD/MM/YYYY"): ""
        };
        await updateCustomer(payload);
      } else {
        let payload: CreateCustomerModel = {
          ...values,
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          birthDay: values.birthDay ? moment(values.birthDay).format("DD/MM/YYYY"): ""
        };
        await addCustomer(payload);
      }
      handleCloseModal();
      setLoading(false);
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
      onSuccess();
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      email: model?.email,
      phoneNumber: model?.phoneNumber,
      name: model?.name,
      birthDay: moment( model?.birthDay),
    });
  };

  useEffect(() => {
    if(!isUpdate){
      onReset();
    }else{
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate ? `Cập nhật khách hàng` : `Thêm khách hàng`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="customerModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        initialValues={{isActive:true}}
        wrapperCol={{ span: 16 }}
      >
       
        <Form.Item
          label="Tên khách hàng"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập tên khách hàng!" }]}
        >
            <Input className="w-100" placeholder="Tên khách hàng" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Vui lòng nhập địa chỉ email!" }]}
        >
          <Input  className="w-100" placeholder="Địa chỉ email" />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
        >
           <Input  className="w-100" placeholder="Số điện thoại" />
        </Form.Item>
        <Form.Item
          label="Ngày sinh"
          name="birthDay"
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày sinh"
          />
        </Form.Item>
        <Form.Item
          hidden={true}
          name="id"
        >
           <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
