import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Modal,
} from "antd";
import _ from "lodash";
import {  DeleteOutlined, OrderedListOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  TreatmentModel,
} from "states/treatment/treatment.models";
import {
  getTreatmentById,
} from "states/treatment/treatment.api";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { useParams } from "react-router-dom";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { TreatmentDetailBreadcrumb } from "./components/treatment-detail-breadcrumb";
import { RemovedTreatmentProductModel, TreatmentProductModel,  } from "states/treatment/treatment-product.models";
import { TreatmentUpdateProductModalComponent } from "./components/treatment-update-product-modal";
import { TreatmentDetailMenu } from "./components/treatment-menu";
import { removeTreatmentProduct, searchTreatmentProduct} from "states/treatment/treatment-product.api";
import { TreatmentAddProductModalComponent } from "./components/treatment-add-product-modal";
import { TreatmentSortProductModalComponent } from "./components/treatment-sort-product-modal";
interface ParamsRoute{
  customerId: string;
  treatmentId: string;
}
interface ITreatmentProductProps {

};
const TreatmentProduct: FC<ITreatmentProductProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedTreatmentProductModel>({
    idList: [],
  });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [treatmentModel, setTreatmentModel] = useState<TreatmentModel>(
    {} as TreatmentModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    [] as TreatmentProductModel[]
  );
  const [isModal,setModal] = useState(false);
  const [isAddModal,setAddModal] = useState(false);
  const [isSortModal,setSortModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] =  useState<TreatmentProductModel>({} as TreatmentProductModel);
  const params = useParams<ParamsRoute>();

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any[]) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys.map((m) => {return { treatmentId: Number(params.treatmentId), productId: Number(m)} }) });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
 const _handleGetSetupData = () =>{
  getCustomerById(Number(params?.customerId)).then(
    (customerResponse) => {
      setCustomerModel(customerResponse.data);
    }
  );
  getTreatmentById(Number(params?.treatmentId)).then(
    (response) => {
      setTreatmentModel(response.data);
    }
  );
  handleSearch.callback();
  }
  useEffect(() => {
    document.title = "Danh sách liệu trình";
    _handleGetSetupData();
  }, []);
 
  const handleAddTreatmentProduct = () => {
    setAddModal(true);
  }
  const handleSortTreatmentProduct = () => {
    setSortModal(true);
  }
  const gotoPage = async () => {
    setIsLoadingList(true);
    const result = await searchTreatmentProduct(Number(params?.treatmentId));
    result.data.forEach((m,index) => {
      m.key = m.productId.toString();
      m.sort = index
    });
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage();
  }, 500);
  const handleCloseModal = () =>{
    setModal(false);
  }
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeTreatmentProduct(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<TreatmentProductModel> = [
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
      render: (productName: string, item) => (
        <Button type="text" onClick={(ev) =>{
          setModel(item);
          setModal(true);
          setUpdate(true);
        }}>
          {productName}
        </Button>
      ),  
    }
  ];
  return (
    <div className="treatment-product-page">
      <TreatmentDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
        customerId={customerModel.id}
        treatmentName={treatmentModel.name}
      ></TreatmentDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="3"
        customerId={params.customerId}
      ></CustomerDetailMenu>

      <Row gutter={[16, 16]} justify="space-between">
        <Col span={20} className="d-flex justify-content-start">
            <TreatmentDetailMenu activeKey="1" customerId={params.customerId} treatmentId={params.treatmentId} />
        </Col>
        <Col span={4} className="d-flex justify-content-end">
            <Button type="primary" danger className="mr-2" onClick={handleSortTreatmentProduct} icon={<OrderedListOutlined />}>
              Sắp xếp thứ tự
            </Button>
            <Button type="primary" onClick={handleAddTreatmentProduct} icon={<PlusCircleOutlined />}>
              Thêm sản phẩm điều trị
            </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults}
        size="small"
        rowKey={record => record.key}
        pagination={{
          total: pagedResults.length,
          pageSize: pagedResults.length,
          current: 1,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <TreatmentUpdateProductModalComponent treatmentId={params.treatmentId} model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} />
      <TreatmentAddProductModalComponent treatmentId={params.treatmentId} treatmentProducts={pagedResults} onModal={isAddModal} closeModal={()=>{ setAddModal(false)}} onSuccess={handleSearch.callback} />
      <TreatmentSortProductModalComponent treatmentProducts={pagedResults} onModal={isSortModal} closeModal={()=>{ setSortModal(false)}} onSuccess={handleSearch.callback} />
    </div>
  );
};

export default TreatmentProduct;

