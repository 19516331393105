import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Typography,
} from "antd";

import {  DeleteOutlined,  PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedStaffModel,
  SearchStaffRequestModel,
  StaffModel,
} from "states/staff/staff.models";
import {
  removeStaff,
  searchStaff,
} from "states/staff/staff.api";
import { StaffModalComponent } from "./staff-modal";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";

interface IStaffProps {}
const Staff: FC<IStaffProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedStaffModel>({
    idList: [],
  });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<StaffModel>
  );
  const [isModal,setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] =  useState<StaffModel>({} as StaffModel);
  
  const [filterModel, setFilterModel] = useState<SearchStaffRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Nhân viên";
  }, []);
  const handleCloseModal = () =>{
    setModal(false);
  }
  const handleAddStaff = () => {
    setModel({} as StaffModel);
    setModal(true);
    setUpdate(false);
  }
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchStaff({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeStaff(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<StaffModel> = [
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <React.Fragment>
          <Button type="text" onClick={() =>{
            setModel(item);
            setModal(true);
            setUpdate(true);
          }}>
            {name}
          </Button>
        </React.Fragment>
      ),  
    },
    { 
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
      <Typography.Text>{email}</Typography.Text>
      ),
    },
    { 
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (phone: string) => (
      <Typography.Text>{phone}</Typography.Text>
      ),
    }
  ];
  return (
    <div className="staff">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên nhân viên cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.email}
            placeholder="Nhập email nhân viên cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, email: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.phone}
            placeholder="Nhập số điện thoại nhân viên cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, phone: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6} className="d-flex justify-content-end">
            <Button type="primary" onClick={handleAddStaff} icon={<PlusCircleOutlined />}>
              Thêm nhân viên
            </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <StaffModalComponent model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} />
    </div>
  );
};

export default Staff;

