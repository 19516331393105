import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { UserOutlined } from "@ant-design/icons";
import { DefaultErrorMessage } from "shared/constants/message";
import { ForgotPasswordModel } from "states/account/account.models";
import { forgotPasswordApi } from "states/account/account.api";

type SendMailPasswordProps = any;

export const SendEmailPasswordForm: FC<SendMailPasswordProps> = (props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const onSendEmail = async (values: any) => {
    setLoading(true);
    let payload: ForgotPasswordModel = { ...values };
    try {
      if (loading) {
        return;
      }
      await forgotPasswordApi(payload);
      message.success(
        "Reset password request has been sent successfully to your email."
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {}, []);
  return (
    <Form
      form={form}
      name="login-form"
      onFinish={onSendEmail}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Email is required" }]}
      >
        <Input size={"large"} prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block={true}
          size="large"
          className="mb-2"
          loading={loading}
        >
          Reset password
        </Button>
        <Link className="login-form-forgot" to="/login">
          Back
        </Link>
      </Form.Item>
    </Form>
  );
};
