import {
  THEME_SETTING_API,
  THEME_SETTING_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import { CreateThemeSettingModel, RemovedThemeSettingModel, SearchThemeSettingRequestModel, ThemeSettingModel, UpdatedThemeSettingModel } from "./theme-setting.models";

export const searchThemeSetting = async (
  searchThemeSettingPageRequestModel: SearchThemeSettingRequestModel
) => {
  const createResponse = await postHttp<PagedResult<ThemeSettingModel>>(
    `${process.env.REACT_APP_API_URI}${THEME_SETTING_API_SEARCH}`,
    searchThemeSettingPageRequestModel
  );
  return createResponse;
};

export const removeThemeSetting = async (
  removedModel: RemovedThemeSettingModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${THEME_SETTING_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getThemeSettingById = async (id: number) => {
  return await getHttp<ThemeSettingModel>(
    `${process.env.REACT_APP_API_URI}${THEME_SETTING_API}/${id}`
  );
};

export const addThemeSetting = async (model: CreateThemeSettingModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${THEME_SETTING_API}`,
    model
  );
  return response;
};

export const updateThemeSetting = async (model: UpdatedThemeSettingModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${THEME_SETTING_API}`,
    model
  );
  return response;
};
