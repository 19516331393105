import {
  CLIENT_STAFF_API_LIST,
  STAFF_API,
  STAFF_API_LIST,
  STAFF_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateStaffModel,
  RemovedStaffModel,
  SearchStaffRequestModel,
  StaffModel,
  UpdatedStaffModel,
} from "./staff.models";

export const searchStaff = async (
  searchStaffRequestModel: SearchStaffRequestModel
) => {
  const createResponse = await postHttp<PagedResult<StaffModel>>(
    `${process.env.REACT_APP_API_URI}${STAFF_API_SEARCH}`,
    searchStaffRequestModel
  );
  return createResponse;
};

export const getListStaff = async () => {
  const createResponse = await getHttp<StaffModel[]>(
    `${process.env.REACT_APP_API_URI}${STAFF_API_LIST}`
  );
  return createResponse;
};
export const getClientListStaff = async () => {
  const createResponse = await getHttp<StaffModel[]>(
    `${process.env.REACT_APP_API_URI}${CLIENT_STAFF_API_LIST}`
  );
  return createResponse;
};

export const removeStaff = async (
  removedModel: RemovedStaffModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${STAFF_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getStaffById = async (id: number) => {
  return await getHttp<StaffModel>(
    `${process.env.REACT_APP_API_URI}${STAFF_API}/${id}`
  );
};

export const addStaff = async (model: CreateStaffModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${STAFF_API}`,
    model
  );
  return response;
};

export const updateStaff = async (model: UpdatedStaffModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${STAFF_API}`,
    model
  );
  return response;
};
