import React, { FC, useState, useEffect } from "react";

import {
  Row,
  Col,
  Input,
  message,
  Card,
  Form,
  Modal,
  Button,
} from "antd";

import { WebsiteConfigModel } from "states/website-config/website-config.models";
import { getWebsiteConfig, updateWebsiteConfig } from "states/website-config/website-config.api";
import { Store } from "antd/lib/form/interface";
import { SaveOutlined } from "@ant-design/icons";
import { getWebsiteInformation, updateWebsiteInformation } from "states/website-information/website-information.api";
import { WebsiteInformationModel } from "states/website-information/website-information.models";
import { DefaultErrorMessage, UpdateDataSuccessfully } from "shared/constants/message";
interface IWebsiteInformationProps {}
const { TextArea } = Input;
const WebsiteInformation: FC<IWebsiteInformationProps> = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const getInfo = async () => {
    try {
      const res = await getWebsiteInformation();
      form.setFieldsValue(res.data);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ??DefaultErrorMessage
      );
    }
  };
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      let payload = {
        id: values?.id,
        name: values?.name,
        url: values?.url,
        email:values?.email,
        phone: values?.phone,
        address: values?.address,
        map: values?.map,
        facebook: values?.facebook,
        instagram: values?.instagram,
        twitter: values?.twitter,
        pinterest:values?.pinterest,
        youtube: values?.youtube,
        license: values?.license,
      } as WebsiteInformationModel;
      await updateWebsiteInformation(payload);
      setLoading(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content:
          error?.response?.data?.error?.message ??DefaultErrorMessage
      });
      setLoading(false);
    }
  };
  const onSaved = () =>{
    form.submit();
  }
  useEffect(() => {
    getInfo();
  }, []);

  
  return (
    <div className="website-information">
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between">
      <Col span={24}>
        <Card title={`Thông tin website`} bordered={false}>
          <Form form={form} name="website-information" onFinish={onFinish} layout="vertical">
            <Form.Item
              label={`Tên website`}
              name="name"
              rules={[{ required: true, message:`Vui lòng nhập tên website` }]}
            >
              <Input className="w-100" placeholder={`Tên website`} />
            </Form.Item>
            <Form.Item
              label={`Đường dẫn website`}
              name="url"
              rules={[{ required: true, message:`Vui lòng nhập đường dẫn website`}]}
            >
              <Input className="w-100" placeholder={`Đường dẫn website`} />
            </Form.Item>
            <Form.Item
              label={`Email`}
              name="email"
              rules={[{ required: true, message:`Vui lòng nhập địa chỉ email` }]}
            >
              <Input className="w-100" placeholder={`Email`} />
            </Form.Item>
            <Form.Item
              label={`Số điện thoại`}
              name="phone"
            >
              <Input className="w-100" placeholder={`Số điện thoại`} />
            </Form.Item>
            <Form.Item
              label={`Địa chỉ`}
              name="address"
            >
              <Input className="w-100" placeholder={`Địa chỉ`} />
            </Form.Item>
            <Form.Item
              label={`Bản đồ`}
              name="map"
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }}   className="w-100" placeholder={`Bản đồ`} />
            </Form.Item>
            <Form.Item
              label={`Facebook`}
              name="facebook"
            >
              <Input className="w-100" placeholder={`Facebook`} />
            </Form.Item>
            <Form.Item
              label={`Instagram`}
              name="instagram"
            >
              <Input className="w-100" placeholder={`Instagram`} />
            </Form.Item>
            <Form.Item
              label={`Twitter`}
              name="twitter"
            >
              <Input className="w-100" placeholder={`Twitter`} />
            </Form.Item>
            <Form.Item
              label={`Pinterest`}
              name="pinterest"
            >
              <Input className="w-100" placeholder={`Pinterest`} />
            </Form.Item>
            <Form.Item
              label={`Youtube`}
              name="youtube"
            >
              <Input className="w-100" placeholder={`Youtube`} />
            </Form.Item>
            <Form.Item
              label={`License`}
              name="license"
            >
              <Input className="w-100" placeholder={`License`} />
            </Form.Item>
            <Form.Item hidden={true} name="id">
              <Input type="hidden" />
            </Form.Item>
        </Form>
        </Card>
      </Col>

      </Row>
    </div>
  );
};

export default WebsiteInformation;
