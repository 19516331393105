export interface CouponModel {
  key: string;
  id: number;
  couponCode: string;
  couponValue: number;
  couponType: CouponType;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
}

export interface CreateCouponModel {
  couponCode: string;
  couponValue: number;
  couponType: CouponType;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
}

export interface UpdatedCouponModel {
  id: number;
  couponCode: string;
  couponValue: number;
  couponType: CouponType;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
}

export interface SearchCouponRequestModel {
  couponCode?: string;
  page: number;
  pageSize: number;
}

export interface RemovedCouponModel {
  idList: number[];
}

export enum CouponType {
  Percent,
  Amount
}
