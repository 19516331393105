import {
  DeleteOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { cleanUnicode, formatMoney } from "shared/plugins/string-helper";
import { globalValueSetLoading } from "states/global-value/global-value.action";
import {
  getProductAttributeList,
  getProductAttributeValueList,
} from "states/product-attribute/product-attribute.api";
import {
  ProductAttributeModel,
  ProductAttributeValueModel,
} from "states/product-attribute/product-attribute.models";
import {
  addProductCategory,
  getProductCategoryById,
  updateProductCategory,
} from "states/product-category/product-category.api";
import {
  CreateProductCategoryModel,
  LogicalTermEnum,
  ProductCategoryModel,
  ProductCategoryProductAttributeModel,
  ProductCategoryProductAttributeValueModel,
  UpdatedProductCategoryModel,
} from "states/product-category/product-category.models";
import { getProductPriceFilterList } from "states/product-price-filter/product-price-filter.api";
import { ProductPriceFilterModel } from "states/product-price-filter/product-price-filter.models";
import { searchThemeSetting } from "states/theme-setting/theme-setting.api";
import { ThemeSettingModel } from "states/theme-setting/theme-setting.models";

interface RouteParams {
  categoryId: string;
}
interface IAddUpdateProductCategoryProps {}

export function AddUpdateProductCategory(
  props: IAddUpdateProductCategoryProps
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { categoryId } = useParams<RouteParams>();
  const [model, setModel] = useState<ProductCategoryModel>({
    priceFilters: [] as ProductPriceFilterModel[],
    attributeFilters: [] as ProductCategoryProductAttributeModel[],
  } as ProductCategoryModel);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [productPriceFilterDropdown, setProductPriceFilterDropdown] = useState<
    ProductPriceFilterModel[]
  >([]);
  const [attributeDropdown, setAttributeDropdown] = useState<
    ProductAttributeModel[]
  >([]);

  const [attributeValueDropdown, setAttributeValueDropdown] = useState<
    ProductAttributeValueModel[]
  >([]);
  const [themeDropdown, setThemeDropdown] = useState<ThemeSettingModel[]>([]);

  const onSaved = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      if (isUpdateMode) {
        const payload: UpdatedProductCategoryModel = {
          id: model.id,
          name: model.name,
          slug: model.slug,
          avatar: model.avatar,
          description: model.description,
          metaTitle: model.metaTitle,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          priceFilters: model.priceFilters.map((m) => m.id),
          attributeFilters: model.attributeFilters,
        };
        await updateProductCategory(payload);
        message.success(UpdateDataSuccessfully);
      } else {
        const payload: CreateProductCategoryModel = {
          name: model.name,
          slug: model.slug,
          avatar: model.avatar,
          description: model.description,
          metaTitle: model.metaTitle,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          priceFilters: model.priceFilters.map((m) => m.id),
          attributeFilters: model.attributeFilters,
        };
        await addProductCategory(payload);
      }
      history.go(0);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };

  const handleAddAttribute = () => {
    model.attributeFilters = [
      ...model.attributeFilters,
      {
        sort: model.attributeFilters.length + 1,
        logicalTerm: LogicalTermEnum.Or,
        values: [] as ProductCategoryProductAttributeValueModel[],
      } as ProductCategoryProductAttributeModel,
    ];
    setModel({ ...model });
  };

  const attributeColumns: ColumnsType<ProductCategoryProductAttributeModel> = [
    {
      title: "Tên thuộc tính",
      render: (attributeName: any, item) => (
        <React.Fragment>
          <Select
            className="w-100"
            placeholder="Chọn thuộc tính"
            allowClear
            value={item.attributeId}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              item.attributeId = value;
              setModel({ ...model });
            }}
          >
            {attributeDropdown &&
              attributeDropdown.map((attribute) => (
                <Select.Option key={attribute.id} value={attribute.id}>
                  {attribute.name}
                </Select.Option>
              ))}
          </Select>
        </React.Fragment>
      ),
    },
    {
      title: "Thứ tự",
      render: (attributeSort: any, item) => (
        <React.Fragment>
          <Input
            type="number"
            value={item.sort}
            onChange={(value) => {
              item.sort = value.target.valueAsNumber;
              setModel({ ...model });
            }}
          />
        </React.Fragment>
      ),
    },
    {
      title: "Điều kiện lọc",
      render: (logicalTerm: any, item) => (
        <React.Fragment>
          <Select
            className="w-100"
            placeholder="Chọn điều kiện lọc"
            allowClear
            value={item.logicalTerm}
            onChange={(value) => {
              item.logicalTerm = value;
              setModel({ ...model });
            }}
          >
            <Select.Option key={LogicalTermEnum.Or} value={LogicalTermEnum.Or}>
              Hoặc
            </Select.Option>
            <Select.Option
              key={LogicalTermEnum.And}
              value={LogicalTermEnum.And}
            >
              Và
            </Select.Option>
          </Select>
        </React.Fragment>
      ),
    },
    {
      title: "",
      render: (attributeGroupAction: any, item, index) => (
        <Space>
          <Button
            onClick={() => {
              model.attributeFilters[index].values = [
                ...model.attributeFilters[index].values,
                {
                  sort: model.attributeFilters[index].values.length + 1,
                } as ProductCategoryProductAttributeValueModel,
              ];
              setModel({ ...model });
            }}
            type="primary"
            icon={<PlusCircleOutlined />}
          />
          <Popconfirm
            title={QuestionRemove}
            onConfirm={() => {
              model.attributeFilters.splice(index, 1);
              model.attributeFilters = [...model.attributeFilters];
              setModel({ ...model });
            }}
            okText="Có"
            cancelText="Không"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const expandedRowAttributeValue = (
    attribute: ProductCategoryProductAttributeModel,
    attributeIndex: number
  ) => {
    const attributeValueColumns: ColumnsType<ProductCategoryProductAttributeValueModel> = [
      {
        title: "Giá trị thuộc tính",
        render: (name: string, item) => (
          <React.Fragment>
            <Select
              className="w-100"
              placeholder="Chọn giá trị"
              allowClear
              value={item.valueId}
              showSearch
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                item.valueId = value;
                setModel({ ...model });
              }}
            >
              {attributeValueDropdown &&
                attributeValueDropdown.map((attributeValue) => (
                  <Select.Option
                    key={attributeValue.id}
                    value={attributeValue.id}
                  >
                    {attributeValue.value}
                  </Select.Option>
                ))}
            </Select>
          </React.Fragment>
        ),
      },
      {
        title: "Thứ tự",
        render: (sort: number, item) => (
          <React.Fragment>
            <Input
              type="number"
              value={item.sort}
              onChange={(value) => {
                item.sort = value.target.valueAsNumber;
                setModel({ ...model });
              }}
            />
          </React.Fragment>
        ),
      },

      {
        title: "",
        render: (action: any, item, index) => (
          <Space>
            <Popconfirm
              title={QuestionRemove}
              onConfirm={() => {
                model.attributeFilters[attributeIndex].values.splice(index, 1);
                model.attributeFilters[attributeIndex].values = [
                  ...model.attributeFilters[attributeIndex].values,
                ];
                setModel({ ...model });
              }}
              okText="Có"
              cancelText="Không"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <Table
        columns={attributeValueColumns}
        dataSource={attribute.values}
        rowKey={(record, index) => index?.toString() ?? ""}
      ></Table>
    );
  };

  useEffect(() => {
    if (document.location.href.indexOf("/add") > -1) {
      setIsUpdateMode(false);
    } else {
      setIsUpdateMode(true);
      getProductCategoryById(Number(categoryId)).then((res) => {
        setModel(res.data);
      });
    }
    getProductPriceFilterList().then((res) => {
      setProductPriceFilterDropdown(res.data);
    });
    getProductAttributeList().then((res) => {
      setAttributeDropdown(res.data);
    });
    getProductAttributeValueList().then((res) => {
      setAttributeValueDropdown(res.data);
    });
    searchThemeSetting({ page: 1, pageSize: 10000 }).then((res) => {
      setThemeDropdown(res.data.results);
    });
  }, []);

  return (
    <div className="add-update-product-category">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/product-category">Danh mục sản phẩm</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {isUpdateMode ? "Cập nhật" : "Thêm"} danh mục sản phẩm
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Link to="/product-category" className="mr-2">
            <Button type="default" icon={<RollbackOutlined />}>
              Trở lại
            </Button>
          </Link>
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Tabs type="card">
        <Tabs.TabPane tab={<span>Thông tin cơ bản</span>} key="1">
          <Row gutter={[16, 16]}>
            <Col span="16">
              <Card title="Thông tin">
                <Input.Group className="mb-3">
                  <Typography.Text>Tên: </Typography.Text>
                  <Input
                    value={model.name}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        name: e.target.value,
                        metaTitle: e.target.value,
                        slug: cleanUnicode(e.target.value),
                      });
                    }}
                    placeholder="Tên danh mục"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Mô tả: </Typography.Text>
                  <MceEditor
                    pageContent={model.description}
                    onChange={(e) => {
                      setModel({ ...model, description: e });
                    }}
                  ></MceEditor>
                </Input.Group>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Hình ảnh" className="mb-3">
                <UploadFileLocal
                  album={model.avatar ? [model.avatar] : []}
                  maxFileCount={1}
                  multiple={false}
                  onChange={(newFileList: string[]) => {
                    setModel({ ...model, avatar: newFileList[0] });
                  }}
                />
              </Card>
              <Card title="Theme" className="mb-3">
                <Select
                  className="w-100"
                  placeholder="Chọn theme"
                  onChange={(item) => {
                    setModel({ ...model, themeId: item });
                  }}
                  allowClear
                  value={model?.themeId}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {themeDropdown &&
                    themeDropdown.map((theme) => (
                      <Select.Option key={theme.id} value={theme.id}>
                        {theme.key}
                      </Select.Option>
                    ))}
                </Select>
              </Card>
              <Card title="SEO">
                <Input.Group className="mb-3">
                  <Typography.Text>Tiêu đề: </Typography.Text>
                  <Input
                    value={model.metaTitle}
                    onChange={(e) => {
                      setModel({ ...model, metaTitle: e.target.value });
                    }}
                    placeholder="Tiêu đề"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Mô tả: </Typography.Text>
                  <Input.TextArea
                    value={model.metaDescription}
                    onChange={(e) => {
                      setModel({ ...model, metaDescription: e.target.value });
                    }}
                    rows={5}
                    placeholder="Mô tả"
                  ></Input.TextArea>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Từ khóa: </Typography.Text>
                  <Input
                    value={model.metaKeyword}
                    onChange={(e) => {
                      setModel({ ...model, metaKeyword: e.target.value });
                    }}
                    placeholder="Từ khóa"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Slug: </Typography.Text>
                  <Input
                    readOnly={true}
                    value={
                      model.id
                        ? `/danh-muc-san-pham/${model.slug}-${model.id}.html`
                        : "Url chỉ hiển thị khi đã thêm danh mục"
                    }
                    placeholder="Slug"
                  ></Input>
                </Input.Group>
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Bộ lọc</span>} key="2">
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Card title="Lọc theo thuộc tính">
                <Button
                  type="primary"
                  className="mb-3"
                  onClick={handleAddAttribute}
                  icon={<PlusCircleOutlined />}
                >
                  Thêm thuộc tính
                </Button>
                <Table
                  expandable={{
                    expandedRowRender: expandedRowAttributeValue,
                  }}
                  dataSource={model.attributeFilters}
                  columns={attributeColumns}
                  rowKey={(record, index) => index?.toString() ?? ""}
                ></Table>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Lọc theo khoảng giá">
                <Input.Group>
                  <Typography.Text>Danh sách khoảng giá</Typography.Text>
                  <Select
                    className="w-100"
                    placeholder="Chọn khoảng giá"
                    onChange={(item) => {
                      setModel({
                        ...model,
                        priceFilters: item.map((m) => ({
                          id: m,
                          key: m.toString(),
                          displayedText: "",
                        })),
                      });
                    }}
                    allowClear
                    value={model?.priceFilters?.map((m) => m.id) ?? []}
                    showSearch
                    mode="multiple"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productPriceFilterDropdown &&
                      productPriceFilterDropdown.map((price) => (
                        <Select.Option key={price.id} value={price.id}>
                          {price.displayedText}
                        </Select.Option>
                      ))}
                  </Select>
                </Input.Group>
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
