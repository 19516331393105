import React, { FC, useState, useEffect } from "react";
import { Input, Modal, Form } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { ProductPriceFilterModel } from "states/product-price-filter/product-price-filter.models";
import {
  addProductPriceFilter,
  updateProductPriceFilter,
} from "states/product-price-filter/product-price-filter.api";

interface IProductPriceFilterModalProps {
  model: ProductPriceFilterModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const ProductPriceFilterModalComponent: FC<IProductPriceFilterModalProps> = (
  props
) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: ProductPriceFilterModel = {
          ...values,
          key: model.id.toString(),
          id: values.id,
          priceFrom: values.priceFrom,
          priceTo: values.priceTo,
          displayedText: values.displayedText,
        };
        await updateProductPriceFilter(payload);
      } else {
        let payload: ProductPriceFilterModel = {
          ...values,
          key: "0",
          id: 0,
          priceFrom: values.priceFrom,
          priceTo: values.priceTo,
          displayedText: values.displayedText,
        };
        await addProductPriceFilter(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      priceFrom: model?.priceFrom,
      priceTo: model?.priceTo,
      displayedText: model?.displayedText,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật khoảng giá` : `Thêm khoảng giá`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="productPriceFilterModal"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Giá từ"
          name="priceFrom"
          rules={[{ required: true, message: "Vui lòng nhập giá sản phẩm từ" }]}
        >
          <Input
            type="number"
            className="w-100"
            placeholder="Giá sản phẩm từ"
          />
        </Form.Item>
        <Form.Item
          label="Giá đến"
          name="priceTo"
          rules={[
            { required: true, message: "Vui lòng nhập giá sản phẩm đến" },
          ]}
        >
          <Input
            type="number"
            className="w-100"
            placeholder="Giá sản phẩm đến"
          />
        </Form.Item>
        <Form.Item
          label="Nội dung hiển thị"
          name="displayedText"
          rules={[
            { required: true, message: "Vui lòng nhập nội dung hiển thị" },
          ]}
        >
          <Input className="w-100" placeholder="Nội dung hiển thị" />
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
