import React, { FC, useEffect, useState } from "react";
import { Input, Modal, Form } from "antd";

import { IMenuSettingModel } from "states/menu-setting/menu-setting.models";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";

interface IMenuSettingChildrenModalProps {
  model: IMenuSettingModel;
  onUpdate: boolean;
  onModal: boolean;
  index?: number;
  onSave: (model: IMenuSettingModel) => void;
  closeModal: () => void;
}
export const MenuSettingChildrenModal: FC<IMenuSettingChildrenModalProps> = (
  props
) => {
  const { model, onUpdate, onModal, index, closeModal, onSave } = props;
  const [isLoading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<string>();
  const [form] = useForm();
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    let payload: IMenuSettingModel = {
      ...values,
      id: values.id,
      name: values.name,
      slug: values.slug,
      avatar: avatar,
      sort: values.sort,
      menuType: values.menuType,
      data: [],
    };
    onSave(payload);
    handleCloseModal();
    setLoading(false);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
    setAvatar(undefined);
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      name: model?.name,
      slug: model?.slug,
      menuType: model?.menuType,
      data: [],
    });
    setAvatar(model?.avatar);
  };
  useEffect(() => {
    if (!onUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <div className="menu-setting-modal">
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onBlur={() => {}}
            // end
          >
            {onUpdate ? `Cập nhật menu` : `Thêm menu`}
          </div>
        }
        confirmLoading={isLoading}
        centered={true}
        visible={onModal}
        onOk={onSubmit}
        onCancel={handleCloseModal}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        <Form
          form={form}
          name="StaffModal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 8 }}
          initialValues={{ isActive: true }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Tên menu"
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập tên menu" }]}
          >
            <Input className="w-100" placeholder="Tên menu" />
          </Form.Item>
          <Form.Item label="Slug" name="slug">
            <Input className="w-100" placeholder="Slug" />
          </Form.Item>
          <Form.Item label="Hình đại diện" name="avatar">
            <UploadFileLocal
              isUploadImage={true}
              multiple={false}
              maxFileCount={1}
              album={avatar ? [avatar] : []}
              onChange={(newFileList) => {
                setAvatar(newFileList[0]);
              }}
            />
          </Form.Item>
          <Form.Item label="Thứ tự" name="sort">
            <Input className="w-100" placeholder="Thứ tự" />
          </Form.Item>
          <Form.Item label="Phân loại" name="menuType">
            <Input className="w-100" placeholder="Phân loại" />
          </Form.Item>
          <Form.Item hidden={true} name="id">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
