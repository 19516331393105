export interface NotificationManagerModel {
  key: string;
  id: number;
  title:string;
  message: string;
  notiType: NotiType;
  data: string;
}
export interface NotificationData{
   actionLink: string;
   actionId: string;
}

export interface CreateNotificationManagerModel {
  message: string;
  notiType: NotiType;
  data:string;
  title:string;
}

export interface SendNotificationManagerModel {
  id: number;
}

export interface SendNotificationCustomerGroupModel {
  id: number | string;
  customerGroupId: number | string;
}

export interface UpdatedNotificationManagerModel {
  id: number;
  message: string;
  notiType: NotiType;
  data: string;
  title:string;
}

export interface SearchNotificationManagerRequestModel {
  notiType?: number;
  page: number;
  pageSize: number;
}

export interface RemovedNotificationManagerModel {
  idList: number[];
}

export enum NotiType{
  Normal,
  HappyBirthdate,
  Promotion,
  Schedule,
  SendImageSchedule
}