import React, { FC, useState, useEffect } from "react";
import { Button, Collapse, Popconfirm } from "antd";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  IMenuSettingChildren,
  IMenuSettingModel,
} from "states/menu-setting/menu-setting.models";
import { QuestionRemove } from "shared/constants/message";
import { MenuSettingChildrenModal } from "./menu-modal-chilren";
import { Sortable, SortItemModel } from "shared/components/sortable/sortable";

interface IMenuSettingItemProps {
  menuItems: IMenuSettingChildren[];
  onRemove: (id: number) => void;
  onAdd: (model: IMenuSettingModel) => void;
  onUpdate: (id: number, model: IMenuSettingModel) => void;
  onChange?: () => void;
}
const { Panel } = Collapse;
export const MenuSettingItemList: FC<IMenuSettingItemProps> = (props) => {
  const { menuItems, onRemove, onAdd, onUpdate, onChange } = props;
  const [model, setModel] = useState<IMenuSettingModel>(
    {} as IMenuSettingModel
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [indexChildren, setIndexChildren] = useState<number>(0);
  const handleToggleModal = () => {
    setModel({} as IMenuSettingModel);
    setModal(!isModal);
  };

  const [isSortModal, setIsSortModal] = useState(false);
  const [sortMenuList, setSortMenuList] = useState<SortItemModel[]>([]);

  const handleOnClickEditSortBtn = (sortList: SortItemModel[]) => {
    setSortMenuList(sortList);
    setIsSortModal(true);
  };

  useEffect(() => {}, []);
  const genExtra = (id: number) => (
    <React.Fragment>
      <Button
        type="primary"
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          handleToggleModal();
          setIndexChildren(id);
          setUpdate(false);
        }}
        icon={<PlusCircleOutlined />}
      ></Button>
      <Popconfirm
        placement="topLeft"
        title={QuestionRemove}
        onCancel={(event: any) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
        onConfirm={() => removeItemChildren(id)}
        okText="Đồng ý"
        cancelText="Hủy bỏ"
      >
        <Button
          type="primary"
          danger
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
            setIndexChildren(id);
          }}
          icon={<DeleteOutlined />}
        ></Button>
      </Popconfirm>
      <Button
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          handleToggleModal();
          setIndexChildren(id);
          setUpdate(true);
          setModel({ ...menuItems[id] });
        }}
        icon={<SettingOutlined />}
      ></Button>
      {menuItems[id] && menuItems[id].data.length > 0 && (
        <Button
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
            setIndexChildren(id);
            handleOnClickEditSortBtn(
              menuItems[id].data.map((m, index) => ({
                key: m.name,
                sort: index,
                title: m.name,
              }))
            );
          }}
          icon={<MenuOutlined />}
        ></Button>
      )}
    </React.Fragment>
  );
  const onAddMenuChildren = (menuModel: IMenuSettingModel) => {
    let modelTemp = { ...menuItems[indexChildren] };
    modelTemp.data.push(menuModel);
    onChange && onChange();
  };
  const removeItemChildren = (i: number) => {
    menuItems.splice(i, 1);
    onChange && onChange();
  };
  const onUpdateItemChildren = (i: number, menuObj: IMenuSettingModel) => {
    menuItems[i].name = menuObj.name;
    menuItems[i].menuType = menuObj.menuType;
    menuItems[i].slug = menuObj.slug;
    menuItems[i].avatar = menuObj.avatar;
    menuItems[i].sort = menuObj.sort;
    onChange && onChange();
  };
  return (
    <div className="menu-setting-item-list ">
      {menuItems?.map((menuItem, index) => (
        <div className="menu-setting-item-inner mb-2">
          <Collapse
            defaultActiveKey={[menuItem?.name]}
            expandIconPosition={`right`}
          >
            <Panel
              header={menuItem?.name}
              key={menuItem?.name}
              extra={genExtra(index)}
            >
              <MenuSettingItemList
                menuItems={menuItem?.data}
                onAdd={onAddMenuChildren}
                onRemove={removeItemChildren}
                onUpdate={onUpdateItemChildren}
                onChange={onChange && onChange}
              />
            </Panel>
          </Collapse>
        </div>
      ))}
      <MenuSettingChildrenModal
        model={model}
        onModal={isModal}
        onSave={(model: IMenuSettingModel) => {
          isUpdate
            ? onUpdateItemChildren(indexChildren, model)
            : onAddMenuChildren(model);
        }}
        closeModal={handleToggleModal}
        onUpdate={isUpdate}
      />
      <Sortable
        title={`Sắp xếp menu ${
          menuItems[indexChildren] ? menuItems[indexChildren].name : ""
        }`}
        visible={isSortModal}
        model={sortMenuList}
        onCancel={async () => {
          setIsSortModal(false);
        }}
        onOk={async (newlist) => {
          const newChildren: IMenuSettingChildren[] = [];
          newlist.forEach((item) => {
            const findItem = menuItems[indexChildren].data.find(
              (m) => m.name === item.title
            );
            if (!findItem) {
              return;
            }
            newChildren.push({ ...findItem });
          });
          menuItems[indexChildren].data = [...newChildren];
          onChange && onChange();
          setIsSortModal(false);
        }}
      ></Sortable>
    </div>
  );
};
