import {
  GlobalValueState,
  GlobalValueActionType,
  GlobalValue_SetLoading,
  ToastModel,
  GlobalValue_SetToast,
  GlobalValue_ShowWarning,
  GlobalValue_ShowSuccess,
  GlobalValue_ShowError,
  GlobalValue_ShowInfo,
  ConfirmModel,
  GlobalValue_ShowConfirmModal,
  GlobalValue_CloseConfirmModal,
  GlobalValue_SetPageTitle,
} from "./global-value.models";

const initialState: GlobalValueState = {
  isLoading: false,
  toast: {
    duration: 3000,
  } as ToastModel,
  confirm: {
    noBtnText: "Cancel",
    yesBtnText: "Ok",
    onYes: () => {},
    onClose: () => {},
    onNo: () => {},
  } as ConfirmModel,
};

export function globalValueReducer(
  state = initialState,
  action: GlobalValueActionType
): GlobalValueState {
  switch (action.type) {
    case GlobalValue_SetLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GlobalValue_SetToast:
      return {
        ...state,
        toast: action.payload,
      };
    case GlobalValue_ShowSuccess:
      return {
        ...state,
        toast: {
          ...state.toast,
          message: action.payload,
          status: "success",
          isShowToast: true,
        },
      };
    case GlobalValue_ShowError:
      return {
        ...state,
        toast: {
          ...state.toast,
          message: action.payload,
          status: "error",
          isShowToast: true,
        },
      };
    case GlobalValue_ShowWarning:
      return {
        ...state,
        toast: {
          ...state.toast,
          message: action.payload,
          status: "warning",
          isShowToast: true,
        },
      };
    case GlobalValue_ShowInfo:
      return {
        ...state,
        toast: {
          ...state.toast,
          message: action.payload,
          status: "info",
          isShowToast: true,
        },
      };
    case GlobalValue_ShowConfirmModal:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          isActive: true,
          message: action.payload.message,
          noBtnText: action.payload.noBtnText || state.confirm.noBtnText,
          yesBtnText: action.payload.yesBtnText || state.confirm.yesBtnText,
          onYes: action.payload.onYes || state.confirm.onYes,
          onNo: action.payload.onNo || state.confirm.onNo,
          onClose: action.payload.onClose || state.confirm.onClose,
        } as ConfirmModel,
      };
    case GlobalValue_CloseConfirmModal: {
      return {
        ...state,
        confirm: {
          ...state.confirm,
          isActive: false,
          onYes: () => {},
          onClose: () => {},
          onNo: () => {},
          yesBtnText: "Ok",
          noBtnText: "Cancel",
        },
      };
    }
    default:
      return state;
  }
}
