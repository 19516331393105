import {
  CONTACT_API,
  CONTACT_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedContactModel,
  SearchContactRequestModel,
  ContactModel,
  UpdatedContactModel,
} from "./contact.models";

export const searchContact = async (
  searchContactRequestModel: SearchContactRequestModel
) => {
  const createResponse = await postHttp<PagedResult<ContactModel>>(
    `${process.env.REACT_APP_API_URI}${CONTACT_API_SEARCH}`,
    searchContactRequestModel
  );
  return createResponse;
};

export const removeContact = async (
  removedModel: RemovedContactModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${CONTACT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getContactById = async (id: number) => {
  return await getHttp<ContactModel>(
    `${process.env.REACT_APP_API_URI}${CONTACT_API}/${id}`
  );
};

export const updateContact = async (model: UpdatedContactModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${CONTACT_API}`,
    model
  );
  return response;
};
