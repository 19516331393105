import { RootState } from "app.store";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getRefreshToken } from "shared/plugins/http-service";
import { setLoginUser, setLogoutUser } from "states/account/account.action";
import { getProfile } from "states/account/account.api";

const unAuthPath = ["/login", "/forgot-password"];

const AuthManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isLogin = useSelector(
    (state: RootState) => state.account.isAuthentication,
    shallowEqual
  );

  useEffect(() => {
    const isUnAuthPath =
      unAuthPath.filter((m) => location.pathname.startsWith(m)).length > 0;
    if (!isLogin) {
      if (getRefreshToken()) {
        getProfile()
          .then((userProfile) => {
            dispatch(setLoginUser(userProfile));
          })
          .catch(() => {
            dispatch(setLogoutUser());
          });
      } else if (!isUnAuthPath) {
        history.push(`/login?callback=${location.pathname}`);
      }
    }
  }, [isLogin]);

  return null;
};

export default AuthManagement;
