import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  message,
  notification,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { formatMoney } from "shared/plugins/string-helper";
import {
  getShippingFeeOrderAmountList,
  removeShippingFeeOrderAmount,
} from "states/shipping-fee/shipping-fee.api";
import {
  RemovedShippingFeeOrderAmountModel,
  ShippingFeeOrderAmountModel,
} from "states/shipping-fee/shipping-fee.models";
import { ShippingFeeMenu } from "./components/shipping-fee-menu";
import { ShippingFeeOrderAmountModal } from "./components/shipping-fee-order-amount-modal";

interface IShippingFeeOrderAmountProps {}

export function ShippingFeeOrderAmount(props: IShippingFeeOrderAmountProps) {
  const [shippingFeeOrderAmountList, setShippingFeeOrderAmountList] = useState<
    ShippingFeeOrderAmountModel[]
  >([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [
    shippingFeeModel,
    setShippingFeeModel,
  ] = useState<ShippingFeeOrderAmountModel>({} as ShippingFeeOrderAmountModel);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isActiveModel, setIsActiveModel] = useState(false);
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedShippingFeeOrderAmountModel>({
    idList: [],
  });

  const getShippingOrderAmounts = async () => {
    setIsLoadingList(true);
    try {
      const res = await getShippingFeeOrderAmountList();
      setShippingFeeOrderAmountList(
        res.data.map((m) => ({ ...m, key: m.id.toString() }))
      );
    } catch (error) {
      //ignore
    }
    setIsLoadingList(false);
  };

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };

  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeShippingFeeOrderAmount(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            getShippingOrderAmounts();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const handleClickAddBtn = () => {
    setShippingFeeModel({} as ShippingFeeOrderAmountModel);
    setIsUpdate(false);
    setIsActiveModel(true);
  };

  const handleClickUpdateBtn = (item: ShippingFeeOrderAmountModel) => {
    setShippingFeeModel(item);
    setIsUpdate(true);
    setIsActiveModel(true);
  };

  const onCloseModal = () => {
    setIsActiveModel(false);
  };
  const onSuccessModal = () => {
    getShippingOrderAmounts();
  };

  const shippingFeeOrderAmountColumns: ColumnsType<ShippingFeeOrderAmountModel> = [
    {
      title: "Giá trị đơn hàng từ",
      dataIndex: "amountFrom",
      key: "amountFrom",
      render: (amountFrom: number, item) => (
        <Button
          onClick={() => {
            handleClickUpdateBtn(item);
          }}
          type="link"
        >
          {formatMoney(amountFrom)}
        </Button>
      ),
    },
    {
      title: "Giá trị đơn hàng đến",
      dataIndex: "amountTo",
      key: "amountTo",
      render: (amountTo: number, item) => (
        <Button
          onClick={() => {
            handleClickUpdateBtn(item);
          }}
          type="link"
        >
          {formatMoney(amountTo)}
        </Button>
      ),
    },
    {
      title: "Phí ship",
      dataIndex: "shippingFee",
      key: "shippingFee",
      render: (shippingFee: number, item) => (
        <Button
          onClick={() => {
            handleClickUpdateBtn(item);
          }}
          type="link"
        >
          {formatMoney(shippingFee)}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getShippingOrderAmounts();
  }, []);

  return (
    <div className="shipping-fee-page">
      <ShippingFeeMenu activeKey="3"></ShippingFeeMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleClickAddBtn}
            icon={<PlusCircleOutlined />}
          >
            Thêm khoảng giá
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        dataSource={shippingFeeOrderAmountList}
        columns={shippingFeeOrderAmountColumns}
        pagination={false}
        loading={isLoadingList}
        rowSelection={rowSelection}
      ></Table>
      <ShippingFeeOrderAmountModal
        model={shippingFeeModel}
        closeModal={onCloseModal}
        isUpdate={isUpdate}
        onModal={isActiveModel}
        onSuccess={onSuccessModal}
      ></ShippingFeeOrderAmountModal>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
}
