import React, { FC, useState, useEffect } from "react";
import { globalValueSetPageTitle } from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import { Table, Row, Col, Button, message, Drawer, notification } from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

import {
  RemovedProductAttributeValueModel,
  ProductAttributeValueModel,
} from "states/product-attribute/product-attribute.models";
import {
  removeProductAttributeValue,
  getProductAttributeValueList,
} from "states/product-attribute/product-attribute.api";
//import { ProductAttributeValueModalComponent } from "./product-attribute-modal";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { ProductAttributeValueModalComponent } from "./product-attribute-value-modal";

interface IProductAttributeValueProps {}
const ProductAttributeValue: FC<IProductAttributeValueProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedProductAttributeValueModel>({
    idList: [],
  });
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    [] as ProductAttributeValueModel[]
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<ProductAttributeValueModel>(
    {} as ProductAttributeValueModel
  );

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleGetProductAttributeValueList();
  }, []);
  useEffect(() => {
    dispatch(globalValueSetPageTitle("Giá trị thuộc tính sản phẩm"));
  }, []);
  const handleCloseModal = () => {
    setModal(false);
    // if(!isUpdate){
    //   handleSearch.callback();
    // }
  };
  const handleAddProductAttributeValue = () => {
    setModel({} as ProductAttributeValueModel);
    setModal(true);
    setUpdate(false);
  };
  const handleGetProductAttributeValueList = async () => {
    setIsLoadingList(true);
    const result = await getProductAttributeValueList();
    result.data.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeProductAttributeValue(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleGetProductAttributeValueList();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<ProductAttributeValueModel> = [
    {
      title: "Giá trị thuộc tính",
      dataIndex: "value",
      key: "value",
      render: (value: string, item) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              setModel(item);
              setModal(true);
              setUpdate(true);
            }}
          >
            {value}
          </Button>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="ProductAttributeValue">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}></Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddProductAttributeValue}
            icon={<PlusCircleOutlined />}
          >
            Thêm giá trị thuộc tính
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults}
        size="small"
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <ProductAttributeValueModalComponent
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleGetProductAttributeValueList}
        isUpdate={isUpdate}
      />
    </div>
  );
};

export default ProductAttributeValue;
