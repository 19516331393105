import React, { FC, useState, useEffect } from "react";
import { Input, Modal, Form } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { TagModel } from "states/tag/tag.models";
import { addTag, updateTag } from "states/tag/tag.api";

interface ITagModalProps {
  model: TagModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const TagModalComponent: FC<ITagModalProps> = (props) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: TagModel = {
          ...values,
          key: model.id.toString(),
          id: values.id,
          tagName: values.tagName,
        };
        await updateTag(payload);
      } else {
        let payload: TagModel = {
          ...values,
          key: "0",
          id: 0,
          tagName: values.tagName,
        };
        await addTag(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      tagName: model?.tagName,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật tag` : `Thêm tag`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form form={form} name="tagModal" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Tên tag"
          name="tagName"
          rules={[{ required: true, message: "Vui lòng nhập tên tag!" }]}
        >
          <Input className="w-100" placeholder="Tên tag" />
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
