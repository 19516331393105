import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { groupBy, GroupByModel } from "shared/plugins/list-helper";
import { getProvinceList } from "states/address/address.api";
import { ProvinceModel } from "states/address/address.models";
import {
  getShippingFeeAddresses,
  removeShippingFeeAddresses,
} from "states/shipping-fee/shipping-fee.api";
import { ShippingFeeAddressModel } from "states/shipping-fee/shipping-fee.models";
import { ShippingFeeAddressModal } from "./components/shipping-fee-address-modal";
import { ShippingFeeMenu } from "./components/shipping-fee-menu";

interface IShippingFeeAddressProps {}

export function ShippingFeeAddress(props: IShippingFeeAddressProps) {
  const [shippingFeeAddress, setShippingFeeAddress] = useState<
    ShippingFeeAddressModel[]
  >([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [provinces, setProvinces] = useState<ProvinceModel[]>([]);
  const [isActiveModel, setIsActiveModel] = useState(false);
  const [shippingFeeAddressList, setShippingFeeAddressList] = useState<
    GroupByModel<ShippingFeeAddressModel>[]
  >([]);

  const shippingFeeAddressColumns: ColumnsType<
    GroupByModel<ShippingFeeAddressModel>
  > = [
    {
      title: "Tỉnh / Thành phố",
      dataIndex: "provinceName",
      key: "provinceName",
      render: (provinceName: string, item) => (
        <Button
          onClick={() => {
            handleClickUpdateAreaBtn(item);
          }}
          type="link"
        >
          {item.value[0].provinceName}
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (action: any, item) => (
        <Popconfirm
          title={QuestionRemove}
          onConfirm={async () => {
            try {
              await removeShippingFeeAddresses({
                idList: item.value.map((m) => m.id),
              });
              message.success(UpdateDataSuccessfully);
              getShippingAddress();
            } catch (error) {
              message.error("Xóa thất bại");
            }
          }}
          okText="Có"
          cancelText="Không"
        >
          <Button danger>Xóa</Button>
        </Popconfirm>
      ),
    },
  ];

  const getShippingAddress = async () => {
    const res = await getShippingFeeAddresses();
    setShippingFeeAddressList(
      groupBy(res.data, (m) => m.provinceId.toString())
    );
  };

  const onCloseModal = () => {
    setIsActiveModel(false);
  };
  const onSuccessModal = () => {
    getShippingAddress();
  };
  const handleClickAddAreaBtn = () => {
    setShippingFeeAddress([]);
    setIsUpdate(false);
    setIsActiveModel(true);
  };

  const handleClickUpdateAreaBtn = (
    item: GroupByModel<ShippingFeeAddressModel>
  ) => {
    setShippingFeeAddress([...item.value]);
    setIsUpdate(true);
    setIsActiveModel(true);
  };

  useEffect(() => {
    getProvinceList().then((res) => {
      setProvinces(res.data);
    });
    getShippingAddress();
  }, []);

  return (
    <div className="shipping-fee-page">
      <ShippingFeeMenu activeKey="2"></ShippingFeeMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleClickAddAreaBtn}
            icon={<PlusCircleOutlined />}
          >
            Thêm khu vực
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        dataSource={shippingFeeAddressList}
        columns={shippingFeeAddressColumns}
        pagination={false}
      ></Table>
      <ShippingFeeAddressModal
        shippingFeeAddresses={shippingFeeAddress}
        closeModal={onCloseModal}
        isUpdate={isUpdate}
        provinces={provinces}
        onModal={isActiveModel}
        onSuccess={onSuccessModal}
      ></ShippingFeeAddressModal>
    </div>
  );
}
