import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface ITreatmentDetailMenuProps {
  activeKey: string;
  customerId: string;
  treatmentId: string;
}

export function TreatmentDetailMenu(props: ITreatmentDetailMenuProps) {
  const { activeKey, customerId,treatmentId } = props;
  return (
    <Menu selectedKeys={[activeKey]} mode="horizontal" className="mb-4">
      <Menu.Item key="1" icon={<MailOutlined />}>
        <Link to={`/customers/${customerId}/treatments/${treatmentId}/products`}>Sản phẩm điều trị</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        <Link to={`/customers/${customerId}/treatments/${treatmentId}/schedules`}>Lịch điều trị</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<AppstoreOutlined />}>
        <Link to={`/customers/${customerId}/treatments/${treatmentId}/notifications`}>Lịch nhắc khách hàng</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<AppstoreOutlined />}>
        <Link to={`/customers/${customerId}/treatments/${treatmentId}/images`}>Theo dõi kết quả điều trị</Link>
      </Menu.Item>
    </Menu>
  );
}
