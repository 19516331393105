import {
  TREATMENT_PRODUCT_API,
  TREATMENT_PRODUCT_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  RemovedTreatmentProductModel,
  TreatmentProductModel,
  UpdatedTreatmentProductModel,
  CreatedTreatmentProductModel,
  CreatedMultipleTreatmentProductModel,
  UpdatedSortListTreatmentProductModel,
} from "./treatment-product.models";

export const searchTreatmentProduct = async (treatmentId: number) => {
  const createResponse = await getHttp<TreatmentProductModel[]>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API_SEARCH}?treatmentId=${treatmentId}`
  );
  return createResponse;
};
export const removeTreatmentProduct = async (
  removedModel: RemovedTreatmentProductModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addTreatmentProduct = async (model: CreatedTreatmentProductModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}`,
    model
  );
  return response;
};

export const addMultipleTreatmentProduct = async (model: CreatedMultipleTreatmentProductModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}/multiple`,
    model
  );
  return response;
};

export const getTreatmentProductById = async (treatmentId: number, productId:number) => {
  return await getHttp<TreatmentProductModel>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}/${treatmentId}/${productId}`
  );
};

export const updateTreatmentProduct = async (model: UpdatedTreatmentProductModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}`,
    model
  );
  return response;
};
export const updateSortTreatmentProduct = async (model: UpdatedSortListTreatmentProductModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_PRODUCT_API}/update_sort`,
    model
  );
  return response;
};
