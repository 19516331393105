import { MenuOutlined } from "@ant-design/icons";
import { List, Modal } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

export interface ISortableProps {
  title?: string;
  onOk: (result: SortItemModel[]) => Promise<void>;
  onCancel: () => Promise<void>;
  visible: boolean;
  model: SortItemModel[];
}

export interface SortItemModel {
  key: string;
  title: string;
  sort: number;
}

export function Sortable(props: ISortableProps) {
  const { title, onOk, visible, onCancel, model } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [modelLocalState, setModelLocalState] = useState<SortItemModel[]>([]);

  const handleCloseModal = () => {
    onCancel();
  };

  const handleSubmitSort = async () => {
    setIsLoading(true);
    await onOk(modelLocalState);
    setIsLoading(false);
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const columns: ColumnsType<SortItemModel> = [
    {
      title: "Sắp xếp",
      dataIndex: "sort",
      width: "100px",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Tên",
      dataIndex: "title",
      className: "drag-visible",
      render: (title: string) => title,
    },
  ];
  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableContainerElement = SortableContainer((props: any) => (
    <tbody {...props} />
  ));
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...modelLocalState], oldIndex, newIndex);
      newData.forEach((treatmentGroup, index) => {
        treatmentGroup.sort = index;
        treatmentGroup.key = index.toString();
      });
      setModelLocalState(newData);
    }
  };

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      modelLocalState &&
      [...modelLocalState].findIndex(
        (s) => s.sort === restProps["data-row-key"]
      );
    return <SortableItem index={index} {...restProps} />;
  };
  const DraggableContainer = (props: any) => (
    <SortableContainerElement
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  useEffect(() => {
    setModelLocalState([...model]);
  }, [model]);

  return (
    <Modal
      width="800px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {title}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={visible}
      onOk={handleSubmitSort}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Table
        size="small"
        pagination={false}
        dataSource={modelLocalState}
        columns={columns}
        rowKey={(record) => record.sort}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Modal>
  );
}
