import {
  RollbackOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Select,
  Typography,
  Upload,
  DatePicker,
} from "antd";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { globalValueSetLoading } from "states/global-value/global-value.action";
import { addBlog, getBlogById, updateBlog } from "states/blog/blog.api";
import {
  CreateBlogModel,
  BlogModel,
  UpdatedBlogModel,
} from "states/blog/blog.models";
import { BlogCategoryModel } from "states/blog-category/blog-category.models";
import { searchBlogCategory } from "states/blog-category/blog-category.api";
import { cleanUnicode } from "shared/plugins/string-helper";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";

interface RouteParams {
  blogId: string;
}
interface IAddUpdateBlogProps {}
const { Option } = Select;
export function AddUpdateBlog(props: IAddUpdateBlogProps) {
  const history = useHistory();
  const { blogId } = useParams<RouteParams>();
  const [model, setModel] = useState<BlogModel>({} as BlogModel);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [blogCategories, setBlogCategories] = useState<BlogCategoryModel[]>([]);
  const dispatch = useDispatch();

  const getBlog = async () => {
    try {
      const res = await getBlogById(Number(blogId));
      setModel(res.data);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const getBlogCategoryies = async () => {
    try {
      const res = await searchBlogCategory({
        name: "",
        page: 1,
        pageSize: 1000,
      });
      setBlogCategories(res.data.results);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const onSaved = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      if (isUpdateMode) {
        const payload = {
          id: model.id,
          title: model.title,
          description: model.description,
          blogContent: model.blogContent,
          slug: model.slug,
          metaTitle: model.metaTitle,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          customizeField: model.customizeField,
          avatar: model.avatar,
          mainCategoryId: model.mainCategoryId,
          relatedCategoryIds: model.relatedCategoryIds,
          eventStartDate: model.eventStartDate,
          eventEndDate: model.eventEndDate,
        } as UpdatedBlogModel;
        await updateBlog(payload);
        message.success(UpdateDataSuccessfully);
      } else {
        const payload = {
          title: model.title,
          description: model.description,
          blogContent: model.blogContent,
          slug: model.slug,
          metaTitle: model.metaTitle,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          avatar: model.avatar,
          customizeField: model.customizeField,
          mainCategoryId: model.mainCategoryId,
          relatedCategoryIds: model.relatedCategoryIds,
          eventStartDate: model.eventStartDate,
          eventEndDate: model.eventEndDate,
        } as CreateBlogModel;
        await addBlog(payload);
        message.success(UpdateDataSuccessfully);
      }
      history.go(0);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };
  useEffect(() => {
    if (document.location.href.indexOf("/add") > -1) {
      setIsUpdateMode(false);
      document.title = "Thêm bài viết";
    } else {
      setIsUpdateMode(true);
      getBlog();
      document.title = "Cập nhật bài viết";
    }
    getBlogCategoryies();
  }, []);

  return (
    <div className="add-update-blog">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/blog">Bài viết</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Bài viết</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Link to="/blog" className="mr-2">
            <Button type="default" icon={<RollbackOutlined />}>
              Trở lại
            </Button>
          </Link>
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span="16">
          <Card title="Thông tin">
            <Input.Group className="mb-3">
              <Typography.Text>Tiêu đề: </Typography.Text>
              <Input
                value={model.title}
                onChange={(e) => {
                  setModel({
                    ...model,
                    title: e.target.value,
                    metaTitle: e.target.value,
                    slug: cleanUnicode(e.target.value),
                  });
                }}
                placeholder="Tiêu đề"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Mô tả: </Typography.Text>
              <MceEditor
                pageContent={model.description}
                onChange={(e) => {
                  setModel({ ...model, description: e });
                  console.log(model);
                }}
              ></MceEditor>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Nội dung: </Typography.Text>
              <MceEditor
                pageContent={model.blogContent}
                cssCustom = "/minfile/tynickblog.min.css"
                onChange={(e) => {
                  setModel({ ...model, blogContent: e });
                }}
              ></MceEditor>
            </Input.Group>
          </Card>
        </Col>
        <Col span="8">
          <Card title="Hình ảnh" className="mb-3">
            <UploadFileLocal
              album={model.avatar !== undefined ? [model.avatar] : []}
              maxFileCount={1}
              multiple={false}
              onChange={(newFileList: string[]) => {
                setModel({ ...model, avatar: newFileList[0] });
              }}
            />
          </Card>
          <Card title="Danh mục" className="mb-3">
            <Input.Group className="mb-3">
              <Typography.Text>Danh mục chính</Typography.Text>
              <Select
                className="w-100"
                placeholder="Chọn danh mục chính"
                onChange={(item) => {
                  setModel({ ...model, mainCategoryId: item });
                }}
                allowClear
                value={model?.mainCategoryId}
                showSearch
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {blogCategories &&
                  blogCategories.map((cate) => (
                    <Option key={cate.id} value={cate.id}>
                      {cate.name}
                    </Option>
                  ))}
              </Select>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Danh mục phụ</Typography.Text>
              <Select
                className="w-100"
                placeholder="Chọn danh mục phụ"
                onChange={(item) => {
                  setModel({ ...model, relatedCategoryIds: item });
                }}
                allowClear
                value={model?.relatedCategoryIds ?? []}
                showSearch
                mode="multiple"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {blogCategories &&
                  blogCategories.map((cate) => (
                    <Option key={cate.id} value={cate.id}>
                      {cate.name}
                    </Option>
                  ))}
              </Select>
            </Input.Group>
          </Card>
          <Card title="Thông tin thêm" className="mb-3">
            <Input.Group className="mb-3">
              <Typography.Text>Youtube Url / Địa điểm: </Typography.Text>
              <Input
                value={model.customizeField?.youtubeUrl ?? ""}
                onChange={(e) => {
                  setModel({
                    ...model,
                    customizeField: {
                      youtubeUrl: e.target.value,
                    },
                  });
                }}
                placeholder="Youtube Url"
              ></Input>
            </Input.Group>

            <Input.Group className="mb-3">
              <Typography.Text>Ngày bắt đầu: </Typography.Text>
              <DatePicker
                value={
                  model.eventStartDate ? moment(model.eventStartDate) : null
                }
                onChange={(e) => {
                  setModel({
                    ...model,
                    eventStartDate: e?.toDate(),
                  });
                  console.log(model);
                }}
                className="ant-input"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Chọn ngày bắt đầu"
              />
            </Input.Group>

            <Input.Group className="mb-3">
              <Typography.Text>Ngày kết thúc: </Typography.Text>
              <DatePicker
                value={model.eventEndDate ? moment(model.eventEndDate) : null}
                onChange={(e) => {
                  setModel({
                    ...model,
                    eventEndDate: e?.toDate(),
                  });
                }}
                className="ant-input"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Chọn ngày kết thúc"
              />
            </Input.Group>
          </Card>
          <Card title="SEO">
            <Input.Group className="mb-3">
              <Typography.Text>Tiêu đề: </Typography.Text>
              <Input
                value={model.metaTitle}
                onChange={(e) => {
                  setModel({ ...model, metaTitle: e.target.value });
                }}
                placeholder="Tiêu đề"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Mô tả: </Typography.Text>
              <Input.TextArea
                value={model.metaDescription}
                onChange={(e) => {
                  setModel({ ...model, metaDescription: e.target.value });
                }}
                rows={5}
                placeholder="Mô tả"
              ></Input.TextArea>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Từ khóa: </Typography.Text>
              <Input
                value={model.metaKeyword}
                onChange={(e) => {
                  setModel({ ...model, metaKeyword: e.target.value });
                }}
                placeholder="Từ khóa"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Slug: </Typography.Text>
              <Input
                readOnly={true}
                value={
                  model.id
                    ? `/tin-chi-tiet/${model.slug}-${model.id}.html`
                    : "Url chỉ hiện thị khi đã thêm blog"
                }
                placeholder="Slug"
              ></Input>
            </Input.Group>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
