import {
  GlobalValueActionType,
  GlobalValue_SetLoading,
  GlobalValue_SetToast,
  ToastModel,
  GlobalValue_ShowSuccess,
  GlobalValue_ShowError,
  GlobalValue_ShowWarning,
  GlobalValue_ShowInfo,
  GlobalValue_ShowConfirmModal,
  ConfirmModel,
  GlobalValue_CloseConfirmModal,
  GlobalValue_SetPageTitle,
} from "./global-value.models";

export const globalValueSetLoading = (
  isLoading: boolean
): GlobalValueActionType => {
  return {
    type: GlobalValue_SetLoading,
    payload: isLoading,
  };
};

export const globalValueSetToast = (
  toast: ToastModel
): GlobalValueActionType => {
  return {
    type: GlobalValue_SetToast,
    payload: toast,
  };
};

export const globalValueShowSuccessToast = (
  message: string
): GlobalValueActionType => {
  return {
    type: GlobalValue_ShowSuccess,
    payload: message,
  };
};

export const globalValueShowErrorToast = (
  message: string
): GlobalValueActionType => {
  return {
    type: GlobalValue_ShowError,
    payload: message,
  };
};

export const globalValueShowWarningToast = (
  message: string
): GlobalValueActionType => {
  return {
    type: GlobalValue_ShowWarning,
    payload: message,
  };
};

export const globalValueShowInfoToast = (
  message: string
): GlobalValueActionType => {
  return {
    type: GlobalValue_ShowInfo,
    payload: message,
  };
};

export const globalValueShowConfirmModal = (
  confirmModal: ConfirmModel
): GlobalValueActionType => {
  return {
    type: GlobalValue_ShowConfirmModal,
    payload: confirmModal,
  };
};

export const globalValueCloseConfirmModal = (): GlobalValueActionType => {
  return {
    type: GlobalValue_CloseConfirmModal,
  };
};

export const globalValueSetPageTitle = (
  title: string
): GlobalValueActionType => {
  return {
    type: GlobalValue_SetPageTitle,
    payload: title,
  };
};
