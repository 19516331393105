//TODO: Show only the staff whose were added into the Customer
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { getCustomerById, updateCustomer } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { getClientListStaff, getListStaff } from "states/staff/staff.api";
import { StaffModel } from "states/staff/staff.models";
import { CustomerDetailBreadcrumb } from "./components/customer-detail-breadcrumb";
import { CustomerDetailMenu } from "./components/customer-menu";

export interface ICustomerDetailProps {}
interface RouteParams {
  customerId: string;
}
type CustomerDetailProps = ICustomerDetailProps &
  RouteComponentProps<RouteParams>;

const CustomerDetail: FC<CustomerDetailProps> = (
  props: CustomerDetailProps
) => {
  const [loading, setLoading] = useState(false);
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [staffs, setStaffs] = useState<StaffModel[]>([]);
  const onSave = async () => {
    try {
      setLoading(true);
      await updateCustomer({
        ...customerModel,
        id: customerModel.id,
        name: customerModel.name,
        phoneNumber: customerModel.phoneNumber,
        email: customerModel.email,
        birthDay: customerModel.birthDay
          ? moment(customerModel.birthDay).format("DD/MM/YYYY")
          : "",
      });
      setLoading(false);
      message.success(UpdateDataSuccessfully);
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  useEffect(() => {
    getCustomerById(Number(props.match.params.customerId)).then(
      (customerResponse) => {
        setCustomerModel(customerResponse.data);
      }
    );
    getClientListStaff().then((staffResponse) => {
      setStaffs(staffResponse.data);
    });
  }, []);

  return (
    <div className="Customer-detail Customer-overview">
      <CustomerDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
      ></CustomerDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="1"
        customerId={props.match.params.customerId}
      ></CustomerDetailMenu>
      <Row gutter={[16, 16]}>
        <Col span={12} xxl={8}>
          <Card
            title="Thông tin khách hàng"
            extra={
              <Button
                loading={loading}
                disabled={loading}
                onClick={onSave}
                type="primary"
              >
                Save
              </Button>
            }
          >
            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Tên khách hàng (*):</Typography.Text>
              </Col>
              <Col flex="auto">
                <Input
                  placeholder="Tên khách hàng"
                  bordered={false}
                  value={customerModel.name}
                  onChange={(e) =>
                    setCustomerModel({ ...customerModel, name: e.target.value })
                  }
                ></Input>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Email (*):</Typography.Text>
              </Col>
              <Col flex="auto">
                <Input
                  disabled
                  placeholder="Địa chỉ Email"
                  bordered={false}
                  value={customerModel.email}
                  onChange={(e) =>
                    setCustomerModel({
                      ...customerModel,
                      email: e.target.value,
                    })
                  }
                ></Input>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Số điện thoại:</Typography.Text>
              </Col>
              <Col flex="auto">
                <Input
                  disabled
                  placeholder="Số điện thoại"
                  bordered={false}
                  value={customerModel.phoneNumber}
                  onChange={(e) =>
                    setCustomerModel({
                      ...customerModel,
                      phoneNumber: e.target.value,
                    })
                  }
                ></Input>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Ngày sinh:</Typography.Text>
              </Col>
              <Col flex="auto">
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-100"
                  placeholder="Ngày sinh"
                  bordered={false}
                  value={
                    customerModel.birthDay
                      ? moment(customerModel.birthDay)
                      : null
                  }
                  onChange={(value) => {
                    setCustomerModel({
                      ...customerModel,
                      birthDay: value?.toDate() ?? "",
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Nhân viên phụ trách:</Typography.Text>
              </Col>
              <Col flex="auto">
                <Select
                  placeholder="Chọn nhân viên phụ trách"
                  optionFilterProp="children"
                  allowClear={true}
                  value={customerModel?.staffId}
                  className="w-100"
                  onChange={(e) => {
                    setCustomerModel({
                      ...customerModel,
                      staffId: e,
                    });
                  }}
                  filterOption={(input, option) =>
                    (option!.title as string)
                      .toLowerCase()
                      .search(input?.toLocaleLowerCase() ?? "") >= 0
                  }
                  showSearch
                >
                  {staffs?.map((staff) => (
                    <Select.Option
                      key={staff.id}
                      value={staff.id}
                      title={staff.name}
                    >
                      {staff.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default CustomerDetail;
