import {
  SHIPPING_FEE_API_BY_ADDRESS,
  SHIPPING_FEE_API_BY_ORDER_AMOUNT,
  SHIPPING_FEE_API_DEFAULT,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  CreatedShippingFeeAddressModel,
  CreatedShippingFeeOrderAmountModel,
  RemovedShippingFeeAddressModel,
  RemovedShippingFeeOrderAmountModel,
  ShippingFeeAddressModel,
  ShippingFeeOrderAmountModel,
  UpdatedShippingFeeAddressModel,
  UpdatedShippingFeeOrderAmountModel,
} from "./shipping-fee.models";

export const getDefaultShippingFee = async () => {
  const createResponse = await getHttp<number>(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_DEFAULT}`
  );
  return createResponse;
};

export const updateDefaultShippingFee = async (shippingFee: number) => {
  debugger;
  const createResponse = await putHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_DEFAULT}`,
    shippingFee,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return createResponse;
};

export const getShippingFeeAddresses = async () => {
  const createResponse = await getHttp<ShippingFeeAddressModel[]>(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ADDRESS}`
  );
  return createResponse;
};

export const updateShippingFeeAddresses = async (
  model: UpdatedShippingFeeAddressModel[]
) => {
  const createResponse = await putHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ADDRESS}`,
    model
  );
  return createResponse;
};

export const createShippingFeeAddresses = async (
  model: CreatedShippingFeeAddressModel[]
) => {
  const createResponse = await postHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ADDRESS}`,
    model
  );
  return createResponse;
};

export const removeShippingFeeAddresses = async (
  removedModel: RemovedShippingFeeAddressModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ADDRESS}/remove_list`,
    removedModel
  );
  return response;
};

export const createShippingFeeOrderAmount = async (
  model: CreatedShippingFeeOrderAmountModel
) => {
  return await postHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ORDER_AMOUNT}`,
    model
  );
};

export const getShippingFeeOrderAmountList = async () => {
  return await getHttp<ShippingFeeOrderAmountModel[]>(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ORDER_AMOUNT}`
  );
};

export const updateShippingFeeOrderAmount = async (
  model: UpdatedShippingFeeOrderAmountModel
) => {
  return await putHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ORDER_AMOUNT}`,
    model
  );
};

export const removeShippingFeeOrderAmount = async (
  removedModel: RemovedShippingFeeOrderAmountModel
) => {
  return await postHttp(
    `${process.env.REACT_APP_API_URI}${SHIPPING_FEE_API_BY_ORDER_AMOUNT}/remove_list`,
    removedModel
  );
};
