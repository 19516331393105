import {
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { getDistrictList } from "states/address/address.api";
import { ProvinceModel } from "states/address/address.models";
import {
  createShippingFeeAddresses,
  updateShippingFeeAddresses,
} from "states/shipping-fee/shipping-fee.api";
import {
  CreatedShippingFeeAddressModel,
  ShippingFeeAddressModel,
  UpdatedShippingFeeAddressModel,
} from "states/shipping-fee/shipping-fee.models";

interface IShippingFeeAddressModalProps {
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  onSuccess: () => void;
  provinces: ProvinceModel[];
  shippingFeeAddresses: ShippingFeeAddressModel[];
}

export function ShippingFeeAddressModal(props: IShippingFeeAddressModalProps) {
  const {
    onModal,
    closeModal,
    onSuccess,
    isUpdate,
    provinces,
    shippingFeeAddresses,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [shippingFeeAddressesModel, setShippingFeeAddressesModel] = useState<
    ShippingFeeAddressModel[]
  >([]);
  const [currentProvinceId, setCurrentProvinceId] = useState<number>();

  const handleCloseModal = () => closeModal();

  const handleChangeProvince = async (provinceId?: number) => {
    try {
      if (provinceId) {
        const res = await getDistrictList(provinceId);
        setShippingFeeAddressesModel(
          res.data.map((district) => ({
            key: "",
            id: 0,
            shippingFee: undefined,
            districtId: district.id,
            districtName: district.name,
            provinceId: district.provinceId,
            provinceName: district.provinceName,
          }))
        );
      } else {
        setShippingFeeAddressesModel([]);
      }
      setCurrentProvinceId(provinceId);
    } catch {
      message.error("Không thể tải danh sách quận / huyện");
    }
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      if (isUpdate) {
        const payload = shippingFeeAddressesModel.map(
          (s) =>
            ({
              id: s.id,
              shippingFee: s.shippingFee,
              districtId: s.districtId,
            } as UpdatedShippingFeeAddressModel)
        );
        await updateShippingFeeAddresses(payload);
      } else {
        const payload = shippingFeeAddressesModel.map(
          (s) =>
            ({
              shippingFee: s.shippingFee,
              districtId: s.districtId,
            } as CreatedShippingFeeAddressModel)
        );
        await createShippingFeeAddresses(payload);
      }
      handleCloseModal();
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
    }
    setLoading(false);
  };

  const districtColumn: ColumnsType<ShippingFeeAddressModel> = [
    {
      title: "Quận / Huyện",
      dataIndex: "districtName",
      key: "districtName",
      render: (districtName: string) => <>{districtName}</>,
    },
    {
      title: "Phí ship",
      dataIndex: "shippingFee",
      key: "shippingFee",
      render: (shippingFee: number, item, index) => (
        <Input
          type="number"
          value={shippingFee}
          onChange={(e) => {
            item.shippingFee = e.target.valueAsNumber;
            setShippingFeeAddressesModel([...shippingFeeAddressesModel]);
          }}
        ></Input>
      ),
    },
  ];

  useEffect(() => {
    setShippingFeeAddressesModel(shippingFeeAddresses);
    if (isUpdate) {
      setCurrentProvinceId(shippingFeeAddresses[0].provinceId);
    } else {
      setCurrentProvinceId(undefined);
    }
  }, [shippingFeeAddresses]);
  return (
    <Modal
      title={
        <div>
          {isUpdate
            ? `Cập nhật phí ship theo khu vực`
            : `Thêm phí ship theo khu vực`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onFinish}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
      width="1000px"
    >
      <Input.Group className="mb-3">
        <Row gutter={[16, 16]}>
          <Col>
            <Typography.Text>Chọn tỉnh / thành phố</Typography.Text>
            <Select
              className="w-100"
              placeholder="Chọn tỉnh / thành phố"
              value={currentProvinceId}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              allowClear
              onChange={handleChangeProvince}
              disabled={isUpdate}
            >
              {provinces.map((p) => (
                <Select.Option value={p.id}>{p.name}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Typography.Text>Phí ship</Typography.Text>
            <Input
              type="number"
              onChange={(e) => {
                setShippingFeeAddressesModel(
                  shippingFeeAddressesModel.map((s) => ({
                    ...s,
                    shippingFee: e.target.valueAsNumber,
                  }))
                );
              }}
              disabled={isUpdate}
            ></Input>
          </Col>
        </Row>
      </Input.Group>
      <Table
        size="small"
        columns={districtColumn}
        dataSource={shippingFeeAddressesModel}
        pagination={false}
      ></Table>
    </Modal>
  );
}
