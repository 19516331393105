import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { ACCOUNT_API_REFRESH_TOKEN } from "shared/constants/api-endpoint";

const accessTokenName = "AccessToken";
const refreshTokenName = "RefreshToken";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest.url.endsWith(ACCOUNT_API_REFRESH_TOKEN)
    ) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        try {
          const res = await axios.post(
            process.env.REACT_APP_API_URI + ACCOUNT_API_REFRESH_TOKEN,
            {
              token: refreshToken,
            }
          );
          setRefreshToken(res.data.refreshToken);
          setToken(res.data.accessToken);
          return axios(originalRequest);
        } catch (e) {
          removeToken();
          removeRefreshToken();
          document.location.href = "/login";
          return Promise.reject(error);
        }
      }
      document.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export function getHttp<T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
): Promise<R> {
  return axios.get<T, R>(url, config);
}

export function postHttp<T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<R> {
  return axios.post<T, R>(url, data, config);
}

export function deleteHttp<T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
): Promise<R> {
  return axios.delete<T, R>(url, config);
}

export function putHttp<T = any, R = AxiosResponse<T>>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<R> {
  return axios.put<T, R>(url, data, config);
}

export const getToken = () => localStorage.getItem(accessTokenName);
export const removeToken = () => localStorage.removeItem(accessTokenName);
export const setToken = (token?: string) =>
  localStorage.setItem(accessTokenName, token ?? "");
export const getRefreshToken = () => localStorage.getItem(refreshTokenName);
export const removeRefreshToken = () =>
  localStorage.removeItem(refreshTokenName);
export const setRefreshToken = (token?: string) =>
  localStorage.setItem(refreshTokenName, token ?? "");
