export interface ContractModel {
  key: string;
  id: number;
  name: string;
  blankFileUrl: string;
  fileUrl: string;
  status: ContractStatus;
  contractNumber: string;
  startDate: Date;
  customerId: number;
  customerName: string;
}
export interface CreatedContractModel {
  name: string;
  blankFileUrl: string;
  fileUrl: string;
  status: ContractStatus;
  contractNumber: string;
  startDate: Date;
  customerId: number;
}
export interface UpdatedContractModel {
  id: number;
  name: string;
  blankFileUrl: string;
  fileUrl: string;
  status: ContractStatus;
  contractNumber: string;
  startDate: Date;
  customerId: number;
}
export interface UpdatedContractStatusModel{
  id: number;
  status: ContractStatus;
}
export interface SearchContractRequestModel {
  name?: string;
  contractNumber?: string;
  customerId?: string;
  status?: ContractStatus;
  page: number;
  pageSize: number;
}

export interface RemovedContractModel {
  idList: number[];
}

export enum ContractStatus{
  New,
  Done,
  Cancel,
  Processing
}
