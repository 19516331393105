import {
  MENU_SETTING_API,
  MENU_SETTING_API_SEARCH,
} from "shared/constants/api-endpoint";
import { deleteHttp, getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import { ICreateMenuSettingModel, IRemovedMenuSettingModel, ISearchMenuSettingRequestModel, IMenuSettingModel, IUpdatedMenuSettingModel } from "./menu-setting.models";

export const searchMenuSetting = async (
  searchMenuSettingPageRequestModel: ISearchMenuSettingRequestModel
) => {
  const createResponse = await postHttp<PagedResult<IMenuSettingModel>>(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API_SEARCH}`,
    searchMenuSettingPageRequestModel
  );
  return createResponse;
};

export const removeMenuSettings = async (
  removedModel: IRemovedMenuSettingModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API}/remove_list`,
    removedModel
  );
  return response;
};

export const removeMenuSetting = async (id: number) => {
  const response = await deleteHttp(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API}/${id}`);
  return response;
};

export const getMenuSettingById = async (id: number) => {
  return await getHttp<IMenuSettingModel>(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API}/${id}`
  );
};

export const addMenuSetting = async (model: ICreateMenuSettingModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API}`,
    model
  );
  return response;
};

export const updateMenuSetting = async (model: IUpdatedMenuSettingModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${MENU_SETTING_API}`,
    model
  );
  return response;
};
