export const API_V1_URI = "/api/v1";
export const CLIENT_API_V1_URI = "/api/v1/client";
export const ACCOUNT_API_LOGIN = API_V1_URI + "/accounts/login";
export const ACCOUNT_API_PROFILE = API_V1_URI + "/accounts/profile";
export const ACCOUNT_API_PASSWORD = API_V1_URI + "/accounts/update-password";
export const ACCOUNT_API_FORGOT_PASSWORD =
  API_V1_URI + "/accounts/forgot-password";
export const ACCOUNT_API_RESET_PASSWORD =
  API_V1_URI + "/accounts/reset-password";
export const ACCOUNT_API_REFRESH_TOKEN = API_V1_URI + "/accounts/refresh-token";
export const PROJECT_API = API_V1_URI + "/projects";
export const PROJECT_API_REMOVE_LIST = PROJECT_API + "/remove_list";
export const PROJECT_TASK_API = API_V1_URI + "/project_tasks";
export const PROJECT_TASK_API_SEARCH = API_V1_URI + "/project_tasks/search";
export const PROJECT_TASK_API_REMOVE_LIST = PROJECT_TASK_API + "/remove_list";
export const APP_USER_API_DROPDOWN_LIST =
  API_V1_URI + "/app-users/dropdown-list";
export const USER_MANAGEMENT_API = API_V1_URI + "/user_managements";
export const USER_MANAGEMENT_API_ROLE = API_V1_URI + "/user_managements/roles";
export const ROLE_MANAGEMENT_API = API_V1_URI + "/role_managements";
export const ROLE_MANAGEMENT_API_PERMISSION =
  API_V1_URI + "/role_managements/permissions";
export const FILE_API = API_V1_URI + "/files";
export const UPLOAD_FILE_API = API_V1_URI + "/uploads";
export const UPLOAD_FILE_PDF_API = API_V1_URI + "/uploads/pdf";
export const SEARCH_USER_API = API_V1_URI + "/search_engine/identity/users";
export const SEARCH_PROJECT_TASK_STATUS_API =
  API_V1_URI + "/search_engine/core/task_status";
export const STATIC_PAGE_API = API_V1_URI + "/static_pages";
export const STATIC_PAGE_API_SEARCH = STATIC_PAGE_API + "/search";

export const CONTACT_API = API_V1_URI + "/contacts";
export const CONTACT_API_SEARCH = CONTACT_API + "/search";
export const CONTACT_API_REMOVE = CONTACT_API + "/remove_list";

export const CONTRACT_API = API_V1_URI + "/contracts";
export const CONTRACT_API_SEARCH = CONTRACT_API + "/search";
export const CONTRACT_API_REMOVE = CONTRACT_API + "/remove_list";

export const TREATMENT_API = API_V1_URI + "/treatments";
export const TREATMENT_API_SEARCH = TREATMENT_API + "/search";
export const TREATMENT_API_REMOVE = TREATMENT_API + "/remove_list";

export const COUPON_API = API_V1_URI + "/coupons";
export const COUPON_API_SEARCH = COUPON_API + "/search";
export const COUPON_API_REMOVE = COUPON_API + "/remove_list";

export const BLOG_API = API_V1_URI + "/blogs";
export const BLOG_API_SEARCH = BLOG_API + "/search";
export const BLOG_API_REMOVE = BLOG_API + "/remove_list";

export const BLOG_CATEGORY_API = API_V1_URI + "/blog_categories";
export const BLOG_CATEGORY_API_SEARCH = BLOG_CATEGORY_API + "/search";
export const BLOG_CATEGORY_API_REMOVE = BLOG_CATEGORY_API + "/remove_list";

export const STAFF_API = API_V1_URI + "/staffs";
export const STAFF_API_SEARCH = STAFF_API + "/search";
export const STAFF_API_LIST = STAFF_API + "/list";
export const STAFF_API_REMOVE = STAFF_API + "/remove_list";
export const CLIENT_STAFF_API =  CLIENT_API_V1_URI+ "/staffs";
export const CLIENT_STAFF_API_LIST =  CLIENT_STAFF_API+ "/list";

export const THEME_SETTING_API = API_V1_URI + "/theme_settings";
export const THEME_SETTING_API_SEARCH = THEME_SETTING_API + "/search";
export const THEME_SETTING_API_REMOVE = THEME_SETTING_API + "/remove_list";

export const MENU_SETTING_API = API_V1_URI + "/menu_settings";
export const MENU_SETTING_API_SEARCH = MENU_SETTING_API + "/search";
export const MENU_SETTING_API_REMOVE = MENU_SETTING_API + "/remove_list";

export const CUSTOMER_API = API_V1_URI + "/customers";
export const CUSTOMER_API_SEARCH = CUSTOMER_API + "/search";
export const CUSTOMER_API_REMOVE = CUSTOMER_API + "/remove_list";

export const TREATMENT_PRODUCT_API = API_V1_URI + "/treatment_products";
export const TREATMENT_PRODUCT_API_SEARCH = TREATMENT_PRODUCT_API + "/search";
export const TREATMENT_PRODUCT_API_REMOVE =
  TREATMENT_PRODUCT_API + "/remove_list";

export const TREATMENT_SCHEDULE_API = API_V1_URI + "/treatment_schedules";
export const TREATMENT_SCHEDULE_API_SEARCH = TREATMENT_SCHEDULE_API + "/search";
export const TREATMENT_SCHEDULE_API_REMOVE =
  TREATMENT_SCHEDULE_API + "/remove_list";

export const TREATMENT_IMAGE_API = API_V1_URI + "/treatment_images";
export const TREATMENT_IMAGE_API_SEARCH = TREATMENT_IMAGE_API + "/search";
export const TREATMENT_IMAGE_API_REMOVE = TREATMENT_IMAGE_API + "/remove_list";

export const NOTIFICATION_MANAGER_API = API_V1_URI + "/notification_managers";
export const NOTIFICATION_MANAGER_API_SEARCH =
  NOTIFICATION_MANAGER_API + "/search";
export const SEND_CG_NOTIFICATION_API =
  NOTIFICATION_MANAGER_API + "/customer_group/send_notification";
export const NOTIFICATION_MANAGER_API_REMOVE =
  NOTIFICATION_MANAGER_API + "/remove_list";

export const TREATMENT_NOTIFICATION_API =
  API_V1_URI + "/treatment_notifications";
export const TREATMENT_NOTIFICATION_API_SEARCH =
  TREATMENT_NOTIFICATION_API + "/search";
export const TREATMENT_NOTIFICATION_API_REMOVE =
  TREATMENT_NOTIFICATION_API + "/remove_list";

export const PRODUCT_CATEGORY_API = API_V1_URI + "/product_categories";
export const PRODUCT_CATEGORY_API_SEARCH = PRODUCT_CATEGORY_API + "/search";
export const PRODUCT_CATEGORY_API_REMOVE =
  PRODUCT_CATEGORY_API + "/remove_list";

export const PRODUCT_API = API_V1_URI + "/products";
export const PRODUCT_API_SEARCH = PRODUCT_API + "/search";
export const PRODUCT_API_REMOVE = PRODUCT_API + "/remove_list";
export const PRODUCT_API_KIOTVIET = PRODUCT_API + "/sync_kiot_viet";
export const SEARCH_PRODUCT_API = API_V1_URI + "/search_engine/core/products";

export const PRODUCT_LABEL_API = API_V1_URI + "/product_labels";
export const PRODUCT_LABEL_API_REMOVE = PRODUCT_LABEL_API + "/remove_list";

export const SHIPPING_FEE_API = API_V1_URI + "/shipping_fee";
export const SHIPPING_FEE_API_DEFAULT = SHIPPING_FEE_API + "/default";
export const SHIPPING_FEE_API_BY_ADDRESS = SHIPPING_FEE_API + "/by_address";
export const SHIPPING_FEE_API_BY_ORDER_AMOUNT =
  SHIPPING_FEE_API + "/by_order_amount";

export const ADDRESS_API = API_V1_URI + "/addresses";
export const ADDRESS_API_PROVINCE = ADDRESS_API + "/provinces";
export const ADDRESS_API_DISTRICT = ADDRESS_API + "/districts";
export const ADDRESS_API_WARD = ADDRESS_API + "/wards";

export const PRODUCT_PRICE_FILTER_API = API_V1_URI + "/product_price_filters";
export const PRODUCT_PRICE_FILTER_API_REMOVE =
  PRODUCT_PRICE_FILTER_API + "/remove_list";

export const PRODUCT_COMMENT_API = API_V1_URI + "/product_comments";

export const ORDER_API = API_V1_URI + "/orders";
export const ORDER_API_SEARCH = ORDER_API + "/search";
export const ORDER_API_ORDER_STATUS = ORDER_API + "/order-status";
export const ORDER_API_PAYMENT_STATUS = ORDER_API + "/payment-status";

export const PRODUCT_ATTRIBUTE_GROUP_API =
  API_V1_URI + "/product_attribute_groups";
export const PRODUCT_ATTRIBUTE_GROUP_API_REMOVE =
  PRODUCT_ATTRIBUTE_GROUP_API + "/remove_list";
export const PRODUCT_ATTRIBUTE_API = API_V1_URI + "/product_attributes";
export const PRODUCT_ATTRIBUTE_API_REMOVE =
  PRODUCT_ATTRIBUTE_API + "/remove_list";
export const PRODUCT_ATTRIBUTE_VALUE_API =
  API_V1_URI + "/product_attribute_values";
export const PRODUCT_ATTRIBUTE_VALUE_API_REMOVE =
  PRODUCT_ATTRIBUTE_VALUE_API + "/remove_list";

export const TAG_API = API_V1_URI + "/tags";
export const TAG_API_REMOVE = TAG_API + "/remove_list";

export const CUSTOMER_GROUP_API = API_V1_URI + "/customer_groups";
export const CUSTOMER_GROUP_API_SEARCH =
  CUSTOMER_GROUP_API + "/search";
export const CUSTOMER_GROUP_API_REMOVE =
  CUSTOMER_GROUP_API + "/remove_list";
export const WEBSITE_CONFIGURATION_API = API_V1_URI + "/website_config";
export const WEBSITE_INFORMATION_API = API_V1_URI + "/website_information";
export const SYSTEM_API = API_V1_URI + "/system_settings";
export const TIMEZONE_API = SYSTEM_API + "/time_zones";
