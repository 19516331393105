export interface IThemeConfigModel{
  name: string;
  slug: string;
  themeType: ThemeType;
  data: any[];
}
export interface IThemeConfigImageItem {
  name: string;
  url: string;
  src: string;
  alt: string;
  sort: number;
  themeType: ThemeType;
  isChecked: boolean;
}
export interface IAddUpdateItem{
  name: string;
  type: number;
  textType: number;
  textTemplate: string;
  imageTemplate: string;
}
export interface IThemeConfigTextItem {
  name: string;
  value: string;
  url: string;
  sort: number;
  textType: number;
  themeType: ThemeType;
}

export interface IThemeConfigImageList{
  name: string;
  slug: string;
  themeType: ThemeType;
  sort: number;
  data: IThemeConfigImageItem[];
}
export interface IThemeConfigTextImageList{
  name: string;
  slug: string;
  themeType: ThemeType;
  sort: number;
  data: ITemplate[];
  template: ITemplate;
}
export interface ITemplate{
  sort: number;
  isChecked:boolean;
  imageList:any[];
  textList:any[];
}

export enum ThemeType{
  ThemeConfigImageList,
  ThemeConfigTextItem,
  ThemeConfigTextImageList,
  ThemeConfigImageItem,
  ThemeConfigModel
}
export interface ThemeSettingModel {
  key: string;
  keyName: string;
  id: number;
  data: IThemeConfigModel;
}
export interface CreateThemeSettingModel {
  key: string;
  data: IThemeConfigModel;
}

export interface UpdatedThemeSettingModel {
  key: string;
  id: number;
  data: IThemeConfigModel;
}

export interface SearchThemeSettingRequestModel {
  key?: string;
  page: number;
  pageSize: number;
}

export interface RemovedThemeSettingModel {
  idList: number[];
}
