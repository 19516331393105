import React, { FC, useState, useEffect } from "react";
import { globalValueSetPageTitle, globalValueShowErrorToast, globalValueShowWarningToast } from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
} from "antd";

import {  DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedCouponModel,
  SearchCouponRequestModel,
  CouponModel,
  CouponType
} from "states/coupon/coupon.models";
import {
  removeCoupon,
  searchCoupon,
  updateCoupon,
} from "states/coupon/coupon.api";
import moment from "moment";
import { CouponModalComponent } from "./coupon-modal";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { formatMoney } from "shared/plugins/string-helper";

interface ICouponProps {}
const Coupon: FC<ICouponProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedCouponModel>({
    idList: [],
  });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<CouponModel>
  );
  const [isModal,setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] =  useState<CouponModel>({} as CouponModel);
  
  const [filterModel, setFilterModel] = useState<SearchCouponRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Chương trình khuyến mãi";
  }, []);
  const handleCloseModal = () =>{
    setModal(false);
  }
  const handleAddCoupon = () => {
    setModel({} as CouponModel);
    setModal(true);
    setUpdate(false);
  }
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchCoupon({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeCoupon(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const expandedRowRender = (data: CouponModel) => {
    return <div>
      <div>
        <Typography.Text>Ngày áp dụng: {moment(data.startDate).format("MM/DD/YYYY hh:mm:ss")}</Typography.Text>
      </div>
      <div>
      <Typography.Text>Ngày kết thúc: {moment(data.endDate).format("MM/DD/YYYY hh:mm:ss")}</Typography.Text>
      </div>
    </div>;
  };
  const columns: ColumnsType<CouponModel> = [
    {
      title: "Mã giảm giá",
      dataIndex: "couponCode",
      key: "couponCode",
      render: (couponCode: string, item) => (
        <React.Fragment>
          <Button type="text" onClick={() =>{
            setModel(item);
            setModal(true);
            setUpdate(true);
          }}>
            {couponCode}
          </Button>
        </React.Fragment>
      ),  
    },
    { 
      title: "Loại giảm giá",
      dataIndex: "couponType",
      key: "couponType",
      render: (couponType: CouponType,item) => (
        <React.Fragment>
          {couponType === CouponType.Amount ? <Tag color="#108ee9">- {formatMoney(item.couponValue)} VND</Tag> : <Tag color="#87d068">- {item.couponValue}%</Tag>}
        </React.Fragment>
      ),
    }
  ];
  return (
    <div className="Coupon">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.couponCode}
            placeholder="Nhập mã giảm giá cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, couponCode: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
            <Button type="primary" onClick={handleAddCoupon} icon={<PlusCircleOutlined />}>
              Thêm mã giảm giá
            </Button>
        </Col>
      </Row>
      <Table
        expandable={{expandedRowRender: record => expandedRowRender(record)}}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <CouponModalComponent model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} />
    </div>
  );
};

export default Coupon;

