import React, { FC, useState, useEffect, } from "react";
import {  Modal, Typography } from "antd";
import _ from "lodash";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { TreatmentProductModel, UpdatedSortListTreatmentProductModel, UpdatedSortTreatmentProductModel } from "states/treatment/treatment-product.models";
import Table, { ColumnsType } from "antd/lib/table";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import "../styles/treatment-page.less";
import { MenuOutlined } from "@ant-design/icons";
import { updateSortTreatmentProduct } from "states/treatment/treatment-product.api";
interface ITreatmentSortProductModalProps {
  treatmentProducts: TreatmentProductModel[];
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
}
export const TreatmentSortProductModalComponent: FC<ITreatmentSortProductModalProps> = (props) => {
  const { treatmentProducts, onModal, closeModal,onSuccess} = props;
  const [isLoading, setLoading] = useState(false);
  const [treatmentProductSorts, setTreatmentProductSorts] = useState<TreatmentProductModel[]>([]);
  const handleCloseModal = () => closeModal();
  const onSubmit = () => {
    onFinish();
  };
  const onFinish = async () => {
    setLoading(true);
    try {
      let payload : UpdatedSortListTreatmentProductModel ={
        products: [...treatmentProductSorts].map(m=> {return {
          productId:m.productId,
          treatmentId: m.treatmentId,
          sort: m.sort,
        } as UpdatedSortTreatmentProductModel} )
      }
      await updateSortTreatmentProduct(payload);
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
      setLoading(false);
      onSuccess();
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
   setTreatmentProductSorts(treatmentProducts);
  }, [treatmentProducts]);
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
  ));
  const columns: ColumnsType<TreatmentProductModel> = [
    {
      title: 'Sắp xếp',
      dataIndex: 'sort',
      width: 100,
      className: 'drag-visible',
      render: () => <DragHandle />, 
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
      className: 'drag-visible',
      render: (productName: string, item) => (
        <Typography.Text>{productName}</Typography.Text>
      ),  
    },
    {
      title: "Thứ tự",
      render: (item: TreatmentProductModel) => (
        <Typography.Text>
        {item?.sort}
      </Typography.Text>
      ),  
    }
  ];
  const SortableItem = SortableElement((props:any) => <tr {...props} />);
  const SortableContainerElement = SortableContainer((props:any) => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }:{ oldIndex:number, newIndex: number}) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...treatmentProductSorts], oldIndex, newIndex);
      newData.forEach((treatmentGroup,index) =>{
          treatmentGroup.sort = index;
          treatmentGroup.key = index.toString();
      });
      setTreatmentProductSorts(newData);
    }
  };
  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = treatmentProductSorts && [...treatmentProductSorts].findIndex(s => s.sort === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps}  />;
  };
  const DraggableContainer = (props: any) => (
    <SortableContainerElement
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {`Sắp xếp sản phẩm điều trị`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Table
        columns={columns}
        dataSource={treatmentProductSorts}
        pagination={false}
        rowKey={record => record.sort}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        loading={isLoading}
      ></Table>
    </Modal>
  );
};
