import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Modal,
} from "antd";
import _ from "lodash";
import {  DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedTreatmentModel,
  TreatmentModel,
  TreatmentStatus,
} from "states/treatment/treatment.models";
import {
  getTreatmentById,
} from "states/treatment/treatment.api";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { Link, useParams } from "react-router-dom";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { TreatmentDetailBreadcrumb } from "./components/treatment-detail-breadcrumb";
import { SearchTreatmentScheduleRequestModel, TreatmentScheduleModel, TreatmentScheduleStatus, UpdatedTreatmentScheduleStatusModel } from "states/treatment/treatment-schedule.models";
import { TreatmentScheduleModalComponent } from "./components/treatment-schedule-modal";
import { TreatmentDetailMenu } from "./components/treatment-menu";
import { removeTreatmentSchedule, searchTreatmentSchedule, updateTreatmentScheduleStatus } from "states/treatment/treatment-schedule.api";
import moment from "moment";
const { Option } = Select;
interface ParamsRoute{
  customerId: string;
  treatmentId: string;
}
interface ITreatmentScheduleProps {

};
const TreatmentSchedule: FC<ITreatmentScheduleProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedTreatmentModel>({
    idList: [],
  });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [treatmentModel, setTreatmentModel] = useState<TreatmentModel>(
    {} as TreatmentModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<TreatmentScheduleModel>
  );
  const [isModal,setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] =  useState<TreatmentScheduleModel>({} as TreatmentScheduleModel);
  const params = useParams<ParamsRoute>();
  const [filterModel, setFilterModel] = useState<SearchTreatmentScheduleRequestModel>({
    treatmentId: params?.treatmentId,
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
 const _handleGetSetupData = () =>{
  getCustomerById(Number(params?.customerId)).then(
    (customerResponse) => {
      setCustomerModel(customerResponse.data);
    }
  );
  getTreatmentById(Number(params?.treatmentId)).then(
    (response) => {
      setTreatmentModel(response.data);
    }
  );
 }
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách liệu trình";
    _handleGetSetupData();
  }, []);
 
  const handleAddTreatmentSchedule = () => {
    setModel({} as TreatmentScheduleModel);
    setModal(true);
    setUpdate(false);
  }
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchTreatmentSchedule({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const handleChangeStatus = async (model: UpdatedTreatmentScheduleStatusModel) => {
    setIsLoadingList(true);
    try {
      await updateTreatmentScheduleStatus(model);
      setIsLoadingList(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
        onOk: () => {
          handleSearch.callback();
        },
      });
    } catch (e) {
      setIsLoadingList(false);
      Modal.error({
        title: "Thông báo",
        content: e?.response?.data?.error?.message ?? DefaultErrorMessage,
        onOk: () => {
          handleSearch.callback();
        },
      });
    }
  };
  const handleCloseModal = () =>{
    setModal(false);
  }
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeTreatmentSchedule(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<TreatmentScheduleModel> = [
    {
      title: "Ngày điều trị",
      dataIndex: "treatmentDate",
      key: "treatmentDate",
      render: (treatmentDate: Date, item) => (
        <Button type="text" onClick={(ev) =>{
          setModel(item);
          setModal(true);
          setUpdate(true);
        }}>
         Từ ngày {moment(item.fromDate).format("DD-MM-YYYY")} đến ngày {moment(item.toDate).format("DD-MM-YYYY")}
        </Button>
      ),  
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string, item) => (
        <>{title}</>
      ),  
    },
    { 
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status: TreatmentScheduleStatus,item) => (
        <React.Fragment>
        <Select
          placeholder="Chọn trạng thái"
          optionFilterProp="children"
          value={status}
          onChange={(e) => {
            let modal = Modal.confirm({
              title: "Xác nhận",
              icon: <ExclamationCircleOutlined />,
              content: "Bạn có muốn thay đổi trạng thái không?",
              okText: "Đồng ý",
              visible: false,
              onOk: () => {
                handleChangeStatus({id: item.id, status:e.valueOf()});
                modal.destroy();
              },
              cancelText: "Huỷ bỏ",
            });
          }}
        >
          <Option value={TreatmentStatus.New}>
            <Tag color="#f50">Mới</Tag>
          </Option>
          <Option value={TreatmentStatus.Done}>
            <Tag color="#108ee9">Đã hoàn thành</Tag>
          </Option>
          <Option value={TreatmentStatus.Cancel}>
            <Tag color="#87d068">Hủy bỏ </Tag>
          </Option>
        </Select>
      </React.Fragment>
      ),
    }
  ];
  return (
    <div className="Treatment-page">
      <TreatmentDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
        customerId={customerModel.id}
        treatmentName={treatmentModel.name}
      ></TreatmentDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="3"
        customerId={params.customerId}
      ></CustomerDetailMenu>

      <Row gutter={[16, 16]} justify="space-between">
        <Col span={20} className="d-flex justify-content-start">
            <TreatmentDetailMenu activeKey="2" customerId={params.customerId} treatmentId={params.treatmentId} />
        </Col>
        <Col span={4} className="d-flex justify-content-end">
            <Button type="primary" onClick={handleAddTreatmentSchedule} icon={<PlusCircleOutlined />}>
              Thêm lịch điều trị mới
            </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <TreatmentScheduleModalComponent treatmentId={params.treatmentId} model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} />
    </div>
  );
};

export default TreatmentSchedule;

