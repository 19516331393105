import {
  CUSTOMER_API,
  CUSTOMER_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateCustomerModel,
  RemovedCustomerModel,
  SearchCustomerRequestModel,
  CustomerModel,
  UpdatedCustomerModel,
} from "./customer.models";

export const searchCustomer = async (
  searchCustomerRequestModel: SearchCustomerRequestModel
) => {
  const createResponse = await postHttp<PagedResult<CustomerModel>>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_API_SEARCH}`,
    searchCustomerRequestModel
  );
  return createResponse;
};

export const removeCustomer = async (
  removedModel: RemovedCustomerModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getCustomerById = async (id: number) => {
  return await getHttp<CustomerModel>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_API}/${id}`
  );
};

export const addCustomer = async (model: CreateCustomerModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_API}`,
    model
  );
  return response;
};

export const updateCustomer = async (model: UpdatedCustomerModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_API}`,
    model
  );
  return response;
};
