import { Modal, Input, message, Button, Row, Col, Menu, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DefaultErrorMessage } from "shared/constants/message";

import { groupBy } from "shared/plugins/list-helper";
import {
  AppPermissionModel,
  CreateRoleManagementModel,
  PermissionConst,
  RoleManagementModel,
  UpdateRoleManagementModel,
} from "states/role-management/role-management.models";
import {
  createRole,
  updateRole,
} from "states/role-management/role-management.api";

export interface IRoleFormModalProps {
  visible?: boolean;
  isUpdate?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onModifier?: () => void;
  model: RoleManagementModel;
  permissions: AppPermissionModel[];
}

export function RoleFormModal(props: IRoleFormModalProps) {
  const {
    visible,
    isUpdate,
    onCancel,
    onClose,
    onModifier,
    permissions,
  } = props;
  const inputForm = useRef<Input>(null);
  const [activePermission, setActivePermission] = useState(0);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState<
    {
      name: string;
      value: {
        id: string;
        name: string;
        groupName: string;
        actived: boolean;
      }[];
    }[]
  >([]);
  const [roleModel, setRoleModel] = useState<RoleManagementModel>(props.model);

  const onSave = async () => {
    try {
      setAddBtnLoading(true);
      if (isUpdate) {
        const payload = initUpdateRoleModel();
        await updateRole(payload);
        message.success("Cập nhật thành công");
      } else {
        const payload = initCreateRoleModel();
        await createRole(payload);
        message.success("Thêm thành công");
      }
      onModifier && onModifier();
      setAddBtnLoading(false);
      onClose && onClose();
    } catch (error) {
      setAddBtnLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const initCreateRoleModel = () => {
    const payload: CreateRoleManagementModel = {
      ...roleModel,
      appPermissions: [],
    };
    permissionGroups.forEach((group) => {
      payload.appPermissions = payload.appPermissions.concat(
        group.value.filter((m) => m.actived).map((m) => m.id)
      );
    });
    return payload;
  };

  const initUpdateRoleModel = () => {
    const payload: UpdateRoleManagementModel = {
      ...roleModel,
      appPermissions: [],
    };
    permissionGroups.forEach((group) => {
      payload.appPermissions = payload.appPermissions.concat(
        group.value.filter((m) => m.actived).map((m) => m.id)
      );
    });
    return payload;
  };

  const onSelectedPermissionGroup = ({ key }: any) => {
    setActivePermission(key);
  };

  const onChangeSelectAll = (event: any) => {
    permissionGroups[activePermission].value = permissionGroups[
      activePermission
    ].value.map((permission) => ({
      ...permission,
      actived: event.target.checked,
    }));

    setPermissionGroups([...permissionGroups]);
  };

  const onChangePermissionItem = (event: any) => {
    const index = event.target.value as number;
    permissionGroups[activePermission].value[index].actived =
      event.target.checked;

    setPermissionGroups([...permissionGroups]);
  };

  useEffect(() => {
    inputForm.current?.setValue("");
    setRoleModel({ ...props.model });
  }, [props.model]);

  useEffect(() => {
    const permissionGroupTemp = groupBy(permissions, (p) => p.groupName);
    const newPermissionGroup = permissionGroupTemp.map((group) => ({
      ...group,
      value: group.value.map((permission) => ({
        ...permission,
        actived:
          roleModel.appPermissions.filter((p) => p.id === permission.id)
            .length > 0,
      })),
    }));
    setPermissionGroups(newPermissionGroup);
  }, [permissions, roleModel]);
  return (
    <div className="modal">
      <Modal
        title={`${isUpdate ? `Cập nhật` : `Thêm`} phân quyền`}
        visible={visible}
        onOk={onSave}
        confirmLoading={addBtnLoading}
        onCancel={onCancel}
        width="1000px"
        footer={[
          <Button key="back" onClick={onCancel}>
            Hủy bỏ
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addBtnLoading}
            onClick={onSave}
          >
            Lưu
          </Button>,
        ]}
      >
        <div className="mb-4">
          <Input
            ref={inputForm}
            type="text"
            placeholder="Tên phân quyền"
            value={roleModel?.name}
            onChange={(event: any) => {
              roleModel.name = event.target.value;
              setRoleModel({ ...roleModel });
            }}
          ></Input>
        </div>
        <Row gutter={[16, 16]} justify="space-between">
          <Col span={6}>
            <Menu
              selectedKeys={[activePermission.toString()]}
              onSelect={onSelectedPermissionGroup}
            >
              {permissionGroups.map((group, index) => (
                <Menu.Item key={index}>{PermissionConst[group.name]}</Menu.Item>
              ))}
            </Menu>
          </Col>
          <Col span={18}>
            <Menu selectable={false}>
              <Menu.Item key="all">
                <Checkbox
                  onChange={onChangeSelectAll}
                  checked={
                    permissionGroups.length > 0 &&
                    permissionGroups[activePermission].value.filter(
                      (m) => m.actived
                    ).length === permissionGroups[activePermission].value.length
                  }
                >
                  <strong>Chọn tất cả</strong>
                </Checkbox>
              </Menu.Item>
              {permissionGroups.length > 0 &&
                permissionGroups[activePermission].value.map((item, index) => (
                  <Menu.Item key={item.name}>
                    <Checkbox
                      value={index}
                      onChange={onChangePermissionItem}
                      checked={item.actived}
                    >
                      {PermissionConst[item.name]}
                    </Checkbox>
                  </Menu.Item>
                ))}
            </Menu>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
