import { Button, Card, Col, Input, message, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  getDefaultShippingFee,
  updateDefaultShippingFee,
} from "states/shipping-fee/shipping-fee.api";
import { ShippingFeeMenu } from "./components/shipping-fee-menu";

interface IDefaultShippingFeeProps {}
export function DefaultShippingFee() {
  const [loading, setLoading] = useState(false);
  const [defaultFee, setDefaultFee] = useState(0);

  const onSave = async () => {
    setLoading(true);
    try {
      await updateDefaultShippingFee(defaultFee);
      message.success(UpdateDataSuccessfully);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getDefaultShippingFee().then((res) => {
      setDefaultFee(res.data);
    });
  }, []);

  return (
    <div className="shipping-fee-page">
      <ShippingFeeMenu activeKey="1"></ShippingFeeMenu>
      <Row gutter={[16, 16]}>
        <Col span={12} xxl={8}>
          <Card
            title="Phí ship mặc định"
            extra={
              <Button
                loading={loading}
                disabled={loading}
                onClick={onSave}
                type="primary"
              >
                Lưu
              </Button>
            }
          >
            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                <Typography.Text>Phí ship (*):</Typography.Text>
              </Col>
              <Col flex="auto">
                <Input
                  type="number"
                  placeholder="Tên khách hàng"
                  bordered={false}
                  value={defaultFee}
                  onChange={(e) => setDefaultFee(e.target.valueAsNumber)}
                ></Input>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
