import {
  CUSTOMER_GROUP_API,
  CUSTOMER_GROUP_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateCustomerGroupModel,
  RemovedCustomerGroupModel,
  SearchCustomerGroupRequestModel,
  CustomerGroupModel,
  UpdatedCustomerGroupModel,
} from "./customer-group.models";


export const searchCustomerGroup = async (
  searchCustomerGroupRequestModel: SearchCustomerGroupRequestModel
) => {
  const createResponse = await postHttp<PagedResult<CustomerGroupModel>>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_GROUP_API_SEARCH}`,
    searchCustomerGroupRequestModel
  );
  return createResponse;
};

export const removeCustomerGroup = async (
  removedModel: RemovedCustomerGroupModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_GROUP_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getCustomerGroupById = async (id: number) => {
  return await getHttp<CustomerGroupModel>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_GROUP_API}/${id}`
  );
};

export const addCustomerGroup = async (model: CreateCustomerGroupModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_GROUP_API}`,
    model
  );
  return response;
};

export const updateCustomerGroup = async (model: UpdatedCustomerGroupModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${CUSTOMER_GROUP_API}`,
    model
  );
  return response;
};
