import React, { FC, useState, useEffect, useCallback } from "react";

import { Input, Select, Modal, Form, DatePicker, Tag } from "antd";

import {
  ContractModel,
  ContractStatus,
  CreatedContractModel,
  UpdatedContractModel,
} from "states/contract/contract.models";
import { addContract, updateContract } from "states/contract/contract.api";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import moment from "moment";
import { UploadFileLocalPdf } from "shared/components/upload-file/upload-file-pdf";

interface IContractModalProps {
  model: ContractModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  customerId: string;
  onSuccess: () => void;
}
const { Option } = Select;
export const ContractModalComponent: FC<IContractModalProps> = (props) => {
  const { model, onModal, customerId, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [blankFileUrl, setBlankFileUrl] = useState("");
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedContractModel = {
          ...values,
          id: values.id,
          name: values.name,
          blankFileUrl: values.blankFileUrl,
          fileUrl: values.fileUrl,
          status: values.status,
          contractNumber: values.contractNumber,
          customerId: Number(customerId),
          startDate: values.startDate,
        };
        await updateContract(payload);
      } else {
        let payload: CreatedContractModel = {
          ...values,
          name: values.name,
          blankFileUrl: values.blankFileUrl,
          fileUrl: values.fileUrl,
          status: values.status,
          contractNumber: values.contractNumber,
          customerId: Number(customerId),
          startDate: values.startDate,
        };
        await addContract(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onStatusChange = (value: ContractStatus) => {
    form.setFieldsValue({ status: value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      name: model?.name,
      blankFileUrl: model?.blankFileUrl,
      fileUrl: model?.fileUrl,
      status: model?.status,
      contractNumber: model?.contractNumber,
      customerId: customerId,
      startDate: moment(model?.startDate),
    });
    setBlankFileUrl(model?.blankFileUrl);
  };
  useEffect(() => {
    // if (!isUpdate) {
    //   onReset();
    // } else {
    //   onFill();
    // }
    onReset();
    onFill();
  }, [onModal]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate ? `Cập nhật hợp đồng` : `Thêm hợp đồng mới`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="contractModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Tên hợp đồng"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập tên hợp đồng!" }]}
        >
          <Input className="w-100" placeholder="Tên hợp đồng" />
        </Form.Item>
        <Form.Item
          label="Số hợp đồng"
          name="contractNumber"
          rules={[{ required: true, message: "Vui lòng nhập số hợp đồng!" }]}
        >
          <Input className="w-100" placeholder="Số hợp đồng" />
        </Form.Item>
        <Form.Item label="File mẫu" name="blankFileUrl">
          <UploadFileLocalPdf
            defaultFileList={blankFileUrl ? [blankFileUrl] : []}
            maxFileCount={2}
            multiple={false}
            onChange={(newFileList: string[]) => {
              setBlankFileUrl(newFileList[0]);
              form.setFieldsValue({ blankFileUrl: newFileList[0] });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          rules={[{ required: true, message: "Vui lòng chọn trạng thái!" }]}
        >
          <Select
            disabled={!isUpdate}
            className="w-100"
            placeholder="Chọn trạng thái"
            onChange={onStatusChange}
            allowClear
          >
            <Option value={ContractStatus.New}>
              <Tag color="#f50">Chưa kí</Tag>
            </Option>
            <Option value={ContractStatus.Processing}>
              <Tag color="#87d068">Đang chờ duyệt</Tag>
            </Option>
            <Option value={ContractStatus.Done}>
              <Tag color="#108ee9">Đã kí</Tag>
            </Option>
            <Option value={ContractStatus.Cancel}>
              <Tag color="#87d068">Hủy </Tag>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày bắt đầu!" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày bắt đầu"
          />
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
