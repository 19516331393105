import React, { FC, useState, useEffect } from "react";
import { Button, Collapse, message, Popconfirm } from "antd";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import {
  IMenuSettingChildren,
  IMenuSettingModel,
  IUpdatedMenuSettingModel,
} from "states/menu-setting/menu-setting.models";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { MenuSettingChildrenModal } from "./menu-modal-chilren";
import { MenuSettingItemList } from "./menu-item-list";
import {
  removeMenuSetting,
  updateMenuSetting,
} from "states/menu-setting/menu-setting.api";
import { Sortable, SortItemModel } from "shared/components/sortable/sortable";

interface IMenuSettingItemProps {
  menuItem: IMenuSettingModel;
  onSuccess: () => void;
}
const { Panel } = Collapse;
export const MenuSettingItem: FC<IMenuSettingItemProps> = (props) => {
  const { menuItem, onSuccess } = props;
  const [model, setModel] = useState<IMenuSettingModel>(
    {} as IMenuSettingModel
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [isSortModal, setIsSortModal] = useState(false);
  const [sortMenuList, setSortMenuList] = useState<SortItemModel[]>([]);

  const handleOnClickEditSortBtn = (sortList: SortItemModel[]) => {
    setSortMenuList(sortList);
    setIsSortModal(true);
  };

  const handleToggleModal = () => {
    setModel({} as IMenuSettingModel);
    setModal(!isModal);
  };
  useEffect(() => {}, []);
  const onRemoveBtn = async (id: number) => {
    try {
      removeMenuSetting(id);
      onSuccess();
      message.success(UpdateDataSuccessfully);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const genExtra = (id: number) => (
    <React.Fragment>
      <Button
        type="primary"
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          handleToggleModal();
          setUpdate(false);
        }}
        icon={<PlusCircleOutlined />}
      ></Button>
      <Popconfirm
        placement="topLeft"
        title={QuestionRemove}
        onCancel={(event: any) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
        onConfirm={() => onRemoveBtn(id)}
        okText="Đồng ý"
        cancelText="Hủy bỏ"
      >
        <Button
          type="primary"
          danger
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
          icon={<DeleteOutlined />}
        ></Button>
      </Popconfirm>
      <Button
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          handleToggleModal();
          setIndex(id);
          setUpdate(true);
          setModel({ ...menuItem });
        }}
        icon={<SettingOutlined />}
      ></Button>
      {menuItem.data.length > 0 && (
        <Button
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
            handleOnClickEditSortBtn(
              menuItem.data.map((m, index) => ({
                key: m.name,
                sort: index,
                title: m.name,
              }))
            );
          }}
          icon={<MenuOutlined />}
        ></Button>
      )}
    </React.Fragment>
  );
  const onUpdate = async (model: IMenuSettingModel) => {
    try {
      let payload: IUpdatedMenuSettingModel = {
        ...model,
        id: model.id ?? 0,
        name: model.name,
        slug: model.slug,
        avatar: model.avatar,
        sort: model.sort,
        data: model.data,
        menuType: model.menuType,
      };
      await updateMenuSetting(payload);
      onSuccess();
      message.success(UpdateDataSuccessfully);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const onAddMenuChildren = (menuModel: IMenuSettingModel) => {
    let modelTemp = { ...menuItem };
    modelTemp.data.push(menuModel);
    onUpdate(modelTemp);
  };
  const removeItemChildren = (i: number) => {
    let modelTemp = { ...menuItem };
    modelTemp.data.splice(i, 1);
    onUpdate(modelTemp);
  };
  const onUpdateItemChildren = (i: number, menuObj: IMenuSettingModel) => {
    let modelTemp = { ...menuItem };
    modelTemp.data[i].name = menuObj.name;
    modelTemp.data[i].slug = menuObj.slug;
    modelTemp.data[i].sort = menuObj.sort;
    modelTemp.data[i].avatar = menuObj.avatar;
    modelTemp.data[i].menuType = menuObj.menuType;
    onUpdate(modelTemp);
  };
  const onUpdateItem = (menuObj: IMenuSettingModel) => {
    let modelTemp = { ...menuItem };
    modelTemp.name = menuObj?.name;
    modelTemp.slug = menuObj?.slug;
    modelTemp.avatar = menuObj?.avatar;
    modelTemp.sort = menuObj?.sort;
    modelTemp.menuType = menuObj?.menuType;
    onUpdate(modelTemp);
  };
  return (
    <div className="menu-setting-item m-bot-1">
      <Collapse defaultActiveKey={["1"]} expandIconPosition={`right`}>
        <Panel
          header={menuItem.name}
          key="1"
          extra={genExtra(menuItem?.id ?? 0)}
        >
          <MenuSettingItemList
            menuItems={menuItem.data}
            onAdd={onAddMenuChildren}
            onChange={() => onUpdate(menuItem)}
            onRemove={removeItemChildren}
            onUpdate={onUpdateItemChildren}
          />
          <MenuSettingChildrenModal
            model={model}
            onModal={isModal}
            onSave={(model: IMenuSettingModel) => {
              isUpdate ? onUpdateItem(model) : onAddMenuChildren(model);
            }}
            closeModal={handleToggleModal}
            onUpdate={isUpdate}
          />
          <Sortable
            title={`Sắp xếp menu ${menuItem ? menuItem.name : ""}`}
            visible={isSortModal}
            model={sortMenuList}
            onCancel={async () => {
              setIsSortModal(false);
            }}
            onOk={async (newlist) => {
              const newChildren: IMenuSettingChildren[] = [];
              newlist.forEach((item) => {
                const findItem = menuItem.data.find(
                  (m) => m.name === item.title
                );
                if (!findItem) {
                  return;
                }
                newChildren.push({ ...findItem });
              });
              onUpdate({ ...menuItem, data: newChildren });
              setIsSortModal(false);
            }}
          ></Sortable>
        </Panel>
      </Collapse>
    </div>
  );
};
