import React, { useEffect, useState } from "react";
import { Button, Modal, Typography,  } from "antd";
import { MceEditor } from "shared/components/tinymce/mce-editor";
interface ThemeTextItemEditorProps {
  value: string;
  onEditorChange:(value: string) => void;
}
export const ThemeTextItemEditor = (props: ThemeTextItemEditorProps) => {
  const { value, onEditorChange } = props;
  const [onModal,setOnModal] = useState<boolean>(false);
  const [content,setContent] = useState<string>("");
  const onSubmit = () => {
    onEditorChange(content);
    setOnModal(false);
  }
 
  useEffect(() => {
    setContent(value);
  },[value]);
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{__html: content}} />
      <Button type="primary" onClick={()=>{
        setOnModal(true);
      }} >Chỉnh sửa nội dung
      </Button>
      <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          Cập nhật nội dung
        </div>
      }
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={()=>{
        setOnModal(false);
      }}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <MceEditor
        height={500}
        pageContent={content}
        onChange={(val) => {
          setContent(val);
        }}
      />
     </Modal>
    </React.Fragment>
  );
};
