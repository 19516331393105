import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
interface ITreatmentDetailMenuProps {
  customerName: string;
  treatmentName: string;
  customerId: number;
  className?: string;
}
export function TreatmentDetailBreadcrumb(props: ITreatmentDetailMenuProps) {
  const { customerName,customerId,treatmentName, className } = props;
  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item>
        <Link to="/customers">Khách hàng</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{customerName}</Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={`/customers/${customerId}/treatments`}>Liệu trình</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{treatmentName}</Breadcrumb.Item>
    
    </Breadcrumb>
  );
}
