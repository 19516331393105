import {
  TREATMENT_IMAGE_API,
  TREATMENT_IMAGE_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedTreatmentImageModel,
  SearchTreatmentImageRequestModel,
  TreatmentImageModel,
  CreatedTreatmentImageModel,
} from "./treatment-image.models";

export const searchTreatmentImage = async (
  searchTreatmentRequestModel: SearchTreatmentImageRequestModel
) => {
  const createResponse = await postHttp<PagedResult<TreatmentImageModel>>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_IMAGE_API_SEARCH}`,
    searchTreatmentRequestModel
  );
  return createResponse;
};
export const removeTreatmentImage = async (
  removedModel: RemovedTreatmentImageModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_IMAGE_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addTreatmentImage = async (model: CreatedTreatmentImageModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_IMAGE_API}`,
    model
  );
  return response;
};

export const getTreatmentImageById = async (id: number) => {
  return await getHttp<TreatmentImageModel>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_IMAGE_API}/${id}`
  );
};

