import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Modal,
  Typography,
} from "antd";
import _ from "lodash";
import {
  AlertOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedTreatmentModel,
  TreatmentModel,
} from "states/treatment/treatment.models";
import { getTreatmentById } from "states/treatment/treatment.api";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { useParams } from "react-router-dom";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { TreatmentDetailBreadcrumb } from "./components/treatment-detail-breadcrumb";
import {
  SearchTreatmentNotificationRequestModel,
  TreatmentNotificationModel,
} from "states/treatment/treatment-Notification.models";
import { TreatmentDetailMenu } from "./components/treatment-menu";
import moment from "moment";
import {
  pushNotificationTreatmentNotification,
  removeTreatmentNotification,
  searchTreatmentNotification,
} from "states/treatment/treatment-notification.api";
import { TreatmentNotificationModalComponent } from "./components/treatment-notification-modal";
import modal from "antd/lib/modal";
const { Option } = Select;
interface ParamsRoute {
  customerId: string;
  treatmentId: string;
}
interface ITreatmentNotificationProps {}
const TreatmentNotification: FC<ITreatmentNotificationProps> = () => {
  const [selectedRowsKeys, setSelectedRowsKeys] =
    useState<RemovedTreatmentModel>({
      idList: [],
    });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [treatmentModel, setTreatmentModel] = useState<TreatmentModel>(
    {} as TreatmentModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<TreatmentNotificationModel>
  );
  const [loadNoti, setLoadNoti] = useState(false);
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<TreatmentNotificationModel>(
    {} as TreatmentNotificationModel
  );
  const params = useParams<ParamsRoute>();
  const [filterModel, setFilterModel] =
    useState<SearchTreatmentNotificationRequestModel>({
      treatmentId: params?.treatmentId,
      page: 1,
      pageSize: 20,
    });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
  const _handleGetSetupData = () => {
    getCustomerById(Number(params?.customerId)).then((customerResponse) => {
      setCustomerModel(customerResponse.data);
    });
    getTreatmentById(Number(params?.treatmentId)).then((response) => {
      setTreatmentModel(response.data);
    });
  };
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách liệu trình";
    _handleGetSetupData();
  }, []);

  const handleAddTreatmentNotification = () => {
    setModel({} as TreatmentNotificationModel);
    setModal(true);
    setUpdate(false);
  };
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchTreatmentNotification({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleSendNotification = async (id: number) => {
    try {
      await pushNotificationTreatmentNotification({ id: id });
      message.success("Gửi thông báo thành công");
    } catch (e) {
      message.error("Gửi thông báo thất bại");
    }
  };
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeTreatmentNotification(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<TreatmentNotificationModel> = [
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: Date, item) => (
        <Button
          type="text"
          onClick={(ev) => {
            setModel(item);
            setModal(true);
            setUpdate(true);
          }}
        >
          {moment(startDate).format("DD-MM-YYYY")}
        </Button>
      ),
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: Date) => (
        <Typography.Text>
          {moment(endDate).format("DD-MM-YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "Số ngày lặp lại",
      dataIndex: "repeatDay",
      key: "repeatDay",
      render: (repeatDay: Date) => (
        <Typography.Text>{repeatDay}</Typography.Text>
      ),
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string) => <Typography.Text>{title}</Typography.Text>,
    },
    {
      title: "Thông báo",
      dataIndex: "notification",
      key: "notification",
      render: (button: string, item) => (
        <Button
          type="primary"
          onClick={() => {
            modal.confirm({
              title: "Thông báo",
              content: (
                <>Bạn có muốn gửi thông báo đến khách hàng này không ?</>
              ),
              onOk: () => {
                handleSendNotification(item.id);
              },
            });
          }}
          icon={<AlertOutlined />}
        >
          Thông báo
        </Button>
      ),
    },
  ];
  return (
    <div className="treatment-page">
      <TreatmentDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
        customerId={customerModel.id}
        treatmentName={treatmentModel.name}
      ></TreatmentDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="3"
        customerId={params.customerId}
      ></CustomerDetailMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={20} className="d-flex justify-content-start">
          <TreatmentDetailMenu
            activeKey="3"
            customerId={params.customerId}
            treatmentId={params.treatmentId}
          />
        </Col>
        <Col span={4} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddTreatmentNotification}
            icon={<PlusCircleOutlined />}
          >
            Thêm lịch nhắc điều trị mới
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <TreatmentNotificationModalComponent
        treatmentId={params.treatmentId}
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleSearch.callback}
        isUpdate={isUpdate}
      />
    </div>
  );
};

export default TreatmentNotification;
