import {
  BLOG_API,
  BLOG_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateBlogModel,
  RemovedBlogModel,
  SearchBlogRequestModel,
  BlogModel,
  UpdatedBlogModel,
} from "./blog.models";

export const searchBlog = async (
  searchBLOGRequestModel: SearchBlogRequestModel
) => {
  const createResponse = await postHttp<PagedResult<BlogModel>>(
    `${process.env.REACT_APP_API_URI}${BLOG_API_SEARCH}`,
    searchBLOGRequestModel
  );
  return createResponse;
};

export const removeBlog = async (
  removedModel: RemovedBlogModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${BLOG_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getBlogById = async (id: number) => {
  return await getHttp<BlogModel>(
    `${process.env.REACT_APP_API_URI}${BLOG_API}/${id}`
  );
};

export const addBlog = async (model: CreateBlogModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${BLOG_API}`,
    model
  );
  return response;
};

export const updateBlog = async (model: UpdatedBlogModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${BLOG_API}`,
    model
  );
  return response;
};
