import * as React from "react";
import { Avatar, Button, Comment, message, Rate, Space, Tag } from "antd";
import {
  CheckOutlined,
  EnterOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  ProductCommentModel,
  ProductCommentStatusEnum,
} from "states/product-comment/product-comment.models";
import moment from "moment";
import { useDispatch } from "react-redux";
import { globalValueSetLoading } from "states/global-value/global-value.action";
import {
  changeProductCommentStatus,
  toggleProductCommentIsActive,
} from "states/product-comment/product-comment.api";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";

interface IProductCommentItemProps {
  model: ProductCommentModel;
  onReplyBtnClicked?: (commentId: number) => void;
  onUpdated?: () => void;
}

export function ProductCommentItem(props: IProductCommentItemProps) {
  const { model, onReplyBtnClicked, onUpdated } = props;
  const dispatch = useDispatch();

  const handleToggleIsActive = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      await toggleProductCommentIsActive(model.id);
      message.success(UpdateDataSuccessfully);
      onUpdated && onUpdated();
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };

  const handleToggleStatus = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      const newStatus =
        model.status === ProductCommentStatusEnum.New
          ? ProductCommentStatusEnum.Done
          : ProductCommentStatusEnum.New;
      await changeProductCommentStatus(model.id, newStatus);
      message.success(UpdateDataSuccessfully);
      onUpdated && onUpdated();
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };

  return (
    <Comment
      actions={[
        !model.parentId && (
          <Button
            onClick={() => {
              onReplyBtnClicked && onReplyBtnClicked(model.id);
            }}
          >
            <EnterOutlined /> Trả lời
          </Button>
        ),
        model.isActive ? (
          <Button onClick={handleToggleIsActive}>
            <EyeInvisibleOutlined /> Ẩn
          </Button>
        ) : (
          <Button onClick={handleToggleIsActive}>
            <EyeOutlined /> Hiện
          </Button>
        ),
        !model.staffId &&
          (model.status === ProductCommentStatusEnum.New ? (
            <Button onClick={handleToggleStatus}>
              <CheckOutlined /> Đánh dấu đã xử lý
            </Button>
          ) : (
            <Button onClick={handleToggleStatus}>
              <CheckOutlined /> Đánh dấu chưa xử lý
            </Button>
          )),
      ]}
      author={
        <Space style={{ lineHeight: "22px" }}>
          {model.name} - {model.phone} - {model.email}
        </Space>
      }
      avatar={
        <Avatar className="bg-primary">{model.name && model.name[0]}</Avatar>
      }
      content={
        model.staffId ? (
          <div dangerouslySetInnerHTML={{ __html: model.message }}></div>
        ) : (
          <>{model.message}</>
        )
      }
      datetime={
        <Space>
          <span>{moment(model.creationTime).format("DD/MM/YYYY HH:mm")}</span>
          {model.rating && <Rate allowHalf value={model.rating}></Rate>}
          {model.status === ProductCommentStatusEnum.New && (
            <Tag color="blue">Mới</Tag>
          )}
          {model.staffId && <Tag color="orange">Quản trị viên</Tag>}
        </Space>
      }
      key={model.id}
    >
      {model.childrenComments.map((child) => (
        <ProductCommentItem
          key={child.id}
          model={child}
          onUpdated={onUpdated}
        />
      ))}
    </Comment>
  );
}
