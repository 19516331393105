import React, { FC, useState, useEffect } from "react";
import { Input, Modal, Form } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { ProductAttributeValueModel } from "states/product-attribute/product-attribute.models";
import {
  addProductAttributeValue,
  updateProductAttributeValue,
} from "states/product-attribute/product-attribute.api";

interface IProductAttributeValueModalProps {
  model: ProductAttributeValueModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const ProductAttributeValueModalComponent: FC<IProductAttributeValueModalProps> = (
  props
) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: ProductAttributeValueModel = {
          ...values,
          key: model.id.toString(),
          id: values.id,
          value: values.value,
        };
        await updateProductAttributeValue(payload);
      } else {
        let payload: ProductAttributeValueModel = {
          ...values,
          key: "0",
          id: 0,
          value: values.value,
        };
        await addProductAttributeValue(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      value: model?.value,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật giá trị thuộc tính` : `Thêm giá trị thuộc tính`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="productAttributeValueModal"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Giá trị thuộc tính"
          name="value"
          rules={[
            { required: true, message: "Vui lòng nhập giá trị thuộc tính!" },
          ]}
        >
          <Input className="w-100" placeholder="Giá trị thuộc tính" />
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
