export interface RemovedRoleManagementModel {
  idList: string[];
}

export interface RoleManagementModel {
  key: string;
  id: string;
  name: string;
  normalizedName: string;
  appPermissions: AppPermissionModel[];
}

export interface CreateRoleManagementModel {
  name: string;
  appPermissions: string[];
}

export interface UpdateRoleManagementModel {
  id: string;
  name: string;
  appPermissions: string[];
}

export interface AppPermissionModel {
  id: string;
  name: string;
  groupName: string;
}

export const PermissionConst: { [key: string]: string } = {
  BLOG_CATEGORY: "Quản lý danh mục tin tức",
  "BLOG_CATEGORY:DELETE": "Xóa",
  "BLOG_CATEGORY:CREATE": "Thêm",
  "BLOG_CATEGORY:UPDATE": "Sửa",
  "BLOG_CATEGORY:READ": "Xem",

  BLOG: "Quản lý tin tức",
  "BLOG:DELETE": "Xóa",
  "BLOG:CREATE": "Thêm",
  "BLOG:UPDATE": "Sửa",
  "BLOG:READ": "Xem",

  CONTACT: "Quản lý liên hệ",
  "CONTACT:DELETE": "Xóa",
  "CONTACT:CREATE": "Thêm",
  "CONTACT:UPDATE": "Sửa",
  "CONTACT:READ": "Xem",

  CONTRACT: "Quản lý hợp đồng khách",
  "CONTRACT:DELETE": "Xóa",
  "CONTRACT:CREATE": "Thêm",
  "CONTRACT:UPDATE": "Sửa",
  "CONTRACT:READ": "Xem",

  COUPON: "Quản lý mã khuyến mãi",
  "COUPON:DELETE": "Xóa",
  "COUPON:CREATE": "Thêm",
  "COUPON:UPDATE": "Sửa",
  "COUPON:READ": "Xem",

  CUSTOMER: "Quản lý khách hàng",
  "CUSTOMER:DELETE": "Xóa",
  "CUSTOMER:CREATE": "Thêm",
  "CUSTOMER:MANAGE": "Quản lí",
  "CUSTOMER:UPDATE": "Sửa",
  "CUSTOMER:READ": "Xem",

  FILE: "Quản lý file",
  "FILE:CREATE": "Thêm",
  "FILE:READ": "Xem",

  MENU: "Quản lý menu",
  "MENU:DELETE": "Xóa",
  "MENU:CREATE": "Thêm",
  "MENU:UPDATE": "Sửa",
  "MENU:READ": "Xem",

  NOTIFICATION: "Quản lý thộng báo",
  "NOTIFICATION:DELETE": "Xóa",
  "NOTIFICATION:CREATE": "Thêm",
  "NOTIFICATION:UPDATE": "Sửa",
  "NOTIFICATION:READ": "Xem",
  "NOTIFICATION:SEND_NOTIFICATION": "Gửi thông báo",

  ORDER: "Quản lý đơn hàng",
  "ORDER:DELETE": "Xóa",
  "ORDER:CREATE": "Thêm",
  "ORDER:UPDATE": "Sửa",
  "ORDER:READ": "Xem",

  PRODUCT_ATTRIBUTE_GROUP: "Quản lý nhóm thuộc tính sản phẩm",
  "PRODUCT_ATTRIBUTE_GROUP:DELETE": "Xóa",
  "PRODUCT_ATTRIBUTE_GROUP:CREATE": "Thêm",
  "PRODUCT_ATTRIBUTE_GROUP:UPDATE": "Sửa",
  "PRODUCT_ATTRIBUTE_GROUP:READ": "Xem",

  PRODUCT_ATTRIBUTE: "Quản lý thuộc tính sản phẩm",
  "PRODUCT_ATTRIBUTE:DELETE": "Xóa",
  "PRODUCT_ATTRIBUTE:CREATE": "Thêm",
  "PRODUCT_ATTRIBUTE:UPDATE": "Sửa",
  "PRODUCT_ATTRIBUTE:READ": "Xem",

  PRODUCT_ATTRIBUTE_VALUE: "Quản lý giá trị thuộc tính sản phẩm",
  "PRODUCT_ATTRIBUTE_VALUE:DELETE": "Xóa",
  "PRODUCT_ATTRIBUTE_VALUE:CREATE": "Thêm",
  "PRODUCT_ATTRIBUTE_VALUE:UPDATE": "Sửa",
  "PRODUCT_ATTRIBUTE_VALUE:READ": "Xem",

  PRODUCT_CATEGORY: "Quản lý danh mục sản phẩm",
  "PRODUCT_CATEGORY:DELETE": "Xóa",
  "PRODUCT_CATEGORY:CREATE": "Thêm",
  "PRODUCT_CATEGORY:UPDATE": "Sửa",
  "PRODUCT_CATEGORY:READ": "Xem",

  PRODUCT_COMMENT: "Quản lý bình luận sản phẩm",
  "PRODUCT_COMMENT:DELETE": "Xóa",
  "PRODUCT_COMMENT:CREATE": "Thêm",
  "PRODUCT_COMMENT:UPDATE": "Sửa",
  "PRODUCT_COMMENT:READ": "Xem",

  PRODUCT_LABEL: "Quản lý nhãn sản phẩm",
  "PRODUCT_LABEL:DELETE": "Xóa",
  "PRODUCT_LABEL:CREATE": "Thêm",
  "PRODUCT_LABEL:UPDATE": "Sửa",
  "PRODUCT_LABEL:READ": "Xem",

  PRODUCT: "Quản lý sản phẩm",
  "PRODUCT:DELETE": "Xóa",
  "PRODUCT:CREATE": "Thêm",
  "PRODUCT:UPDATE": "Sửa",
  "PRODUCT:READ": "Xem",

  PRODUCT_PRICE_FILTER: "Quản lý khoảng giá sản phẩm",
  "PRODUCT_PRICE_FILTER:DELETE": "Xóa",
  "PRODUCT_PRICE_FILTER:CREATE": "Thêm",
  "PRODUCT_PRICE_FILTER:UPDATE": "Sửa",
  "PRODUCT_PRICE_FILTER:READ": "Xem",

  SHIPPING_FEE: "Cấu hình phí ship",
  "SHIPPING_FEE:UPDATE": "Sửa",
  "SHIPPING_FEE:READ": "Xem",

  STATIC_PAGE: "Quản lý trang tĩnh",
  "STATIC_PAGE:DELETE": "Xóa",
  "STATIC_PAGE:CREATE": "Thêm",
  "STATIC_PAGE:UPDATE": "Sửa",
  "STATIC_PAGE:READ": "Xem",

  TAG: "Quản lý tag sản phẩm",
  "TAG:DELETE": "Xóa",
  "TAG:CREATE": "Thêm",
  "TAG:UPDATE": "Sửa",
  "TAG:READ": "Xem",

  THEME: "Quản lý giao diện",
  "THEME:DELETE": "Xóa",
  "THEME:CREATE": "Thêm",
  "THEME:UPDATE": "Sửa",
  "THEME:READ": "Xem",

  TREATMENT_IMAGE: "Hình ảnh theo dõi liệu trình",
  "TREATMENT_IMAGE:MANAGE": "Quản lí",

  TREATMENT_NOTIFICATION: "Thông báo theo lịch trong liệu trình",
  "TREATMENT_NOTIFICATION:MANAGE": "Quản lí",

  TREATMENT: "Quản lý thông tin liệu trình",
  "TREATMENT:MANAGE": "Quản lí",

  TREATMENT_PRODUCT: "Quản lý thông tin sản phẩm trong liệu trình",
  "TREATMENT_PRODUCT:MANAGE": "Quản lí",

  TREATMENT_SCHEDULE: "Quản lý lịch liệu trình",
  "TREATMENT_SCHEDULE:MANAGE": "Quản lí",
};
