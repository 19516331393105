import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Popconfirm, Row, Select, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useEffect, useState } from "react";
import { QuestionRemove } from "shared/constants/message";
import {
  getProductAttributeGroupList,
  getProductAttributeList,
  getProductAttributeValueList,
} from "states/product-attribute/product-attribute.api";
import {
  ProductAttributeGroupModel,
  ProductAttributeModel,
  ProductAttributeValueModel,
} from "states/product-attribute/product-attribute.models";
import {
  ProductProductAttributeGroupModel,
  ProductProductAttributeModel,
  ProductProductAttributeValueModel,
} from "states/product/product.models";

interface IProductAttributeTabProps {
  attributeGroups: ProductProductAttributeGroupModel[];
  onUpdated: (attributeGroups: ProductProductAttributeGroupModel[]) => void;
}

export function ProductAttributeTab(props: IProductAttributeTabProps) {
  const { attributeGroups, onUpdated } = props;

  const [attributeGroupDropdown, setAttributeGroupDropdown] = useState<
    ProductAttributeGroupModel[]
  >([]);

  const [attributeDropdown, setAttributeDropdown] = useState<
    ProductAttributeModel[]
  >([]);

  const [attributeValueDropdown, setAttributeValueDropdown] = useState<
    ProductAttributeValueModel[]
  >([]);

  const handleAddAttributeGroup = () => {
    attributeGroups.push({
      sort: attributeGroups.length + 1,
      attributes: [] as ProductProductAttributeModel[],
    } as ProductProductAttributeGroupModel);
    onUpdated([...attributeGroups]);
  };

  const attributeGroupColumns: ColumnsType<ProductProductAttributeGroupModel> = [
    {
      title: "Tên nhóm thuộc tính",
      render: (attributeGroupName: any, item) => (
        <React.Fragment>
          <Select
            className="w-100"
            placeholder="Chọn nhóm thuộc tính"
            allowClear
            value={item.attributeGroupId}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              item.attributeGroupId = value;
              onUpdated(attributeGroups);
            }}
          >
            {attributeGroupDropdown &&
              attributeGroupDropdown.map((attributeGroup) => (
                <Select.Option
                  key={attributeGroup.id}
                  value={attributeGroup.id}
                >
                  {attributeGroup.name}
                </Select.Option>
              ))}
          </Select>
        </React.Fragment>
      ),
    },
    {
      title: "Thứ tự",
      render: (attributeGroupSort: any, item) => (
        <React.Fragment>
          <Input
            type="number"
            value={item.sort}
            onChange={(value) => {
              item.sort = value.target.valueAsNumber;
              onUpdated(attributeGroups);
            }}
          />
        </React.Fragment>
      ),
    },
    {
      title: "",
      render: (attributeGroupAction: any, item, index) => (
        <Space>
          <Button
            onClick={() => {
              attributeGroups[index].attributes = [
                ...attributeGroups[index].attributes,
                {
                  sort: attributeGroups[index].attributes.length + 1,
                  values: [] as ProductProductAttributeValueModel[],
                } as ProductProductAttributeModel,
              ];
              onUpdated(attributeGroups);
            }}
            type="primary"
            icon={<PlusCircleOutlined />}
          />
          <Popconfirm
            title={QuestionRemove}
            onConfirm={() => {
              attributeGroups.splice(index, 1);
              onUpdated([...attributeGroups]);
            }}
            okText="Có"
            cancelText="Không"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const expandedRowAttributeValue = (
    attribute: ProductProductAttributeModel,
    attributeIndex: number,
    groupIndex: number
  ) => {
    const attributeValueColumns: ColumnsType<ProductProductAttributeValueModel> = [
      {
        title: "Giá trị thuộc tính",

        render: (name: string, item) => (
          <React.Fragment>
            <Select
              className="w-100"
              placeholder="Chọn giá trị thuộc tính"
              allowClear
              value={item.valueId}
              showSearch
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                item.valueId = value;
                onUpdated(attributeGroups);
              }}
            >
              {attributeValueDropdown &&
                attributeValueDropdown.map((attributeValue) => (
                  <Select.Option
                    key={attributeValue.id}
                    value={attributeValue.id}
                  >
                    {attributeValue.value}
                  </Select.Option>
                ))}
            </Select>
          </React.Fragment>
        ),
      },
      {
        title: "Thứ tự",
        render: (sort: number, item) => (
          <React.Fragment>
            <Input
              type="number"
              value={item.sort}
              onChange={(value) => {
                item.sort = value.target.valueAsNumber;
                onUpdated(attributeGroups);
              }}
            />
          </React.Fragment>
        ),
      },
      {
        title: "",
        render: (action: any, item, index) => (
          <Space>
            <Popconfirm
              title={QuestionRemove}
              onConfirm={() => {
                attributeGroups[groupIndex].attributes[
                  attributeIndex
                ].values.splice(index, 1);
                attributeGroups[groupIndex].attributes[
                  attributeIndex
                ].values = [
                  ...attributeGroups[groupIndex].attributes[attributeIndex]
                    .values,
                ];
                onUpdated(attributeGroups);
              }}
              okText="Có"
              cancelText="Không"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <Table
        columns={attributeValueColumns}
        dataSource={attribute.values}
        rowKey={(record, index) => index?.toString() ?? ""}
      ></Table>
    );
  };

  const expandedRowAttribute = (
    attributeGroup: ProductProductAttributeGroupModel,
    groupIndex: number
  ) => {
    const attributeColumns: ColumnsType<ProductProductAttributeModel> = [
      {
        title: "Tên thuộc tính",
        render: (name: string, item) => (
          <React.Fragment>
            <Select
              className="w-100"
              placeholder="Chọn thuộc tính"
              allowClear
              value={item.attributeId}
              showSearch
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                item.attributeId = value;
                onUpdated(attributeGroups);
              }}
            >
              {attributeDropdown &&
                attributeDropdown.map((attribute) => (
                  <Select.Option key={attribute.id} value={attribute.id}>
                    {attribute.name}
                  </Select.Option>
                ))}
            </Select>
          </React.Fragment>
        ),
      },
      {
        title: "Thứ tự",
        render: (sort: number, item) => (
          <React.Fragment>
            <Input
              type="number"
              value={item.sort}
              onChange={(value) => {
                item.sort = value.target.valueAsNumber;
                onUpdated(attributeGroups);
              }}
            />
          </React.Fragment>
        ),
      },
      {
        title: "",
        render: (action: any, item, index) => (
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                attributeGroups[groupIndex].attributes[index].values = [
                  ...attributeGroups[groupIndex].attributes[index].values,
                  {
                    sort: item.values.length + 1,
                  } as ProductProductAttributeValueModel,
                ];
                onUpdated(attributeGroups);
              }}
            />
            <Popconfirm
              title={QuestionRemove}
              onConfirm={() => {
                attributeGroups[groupIndex].attributes.splice(index, 1);
                attributeGroups[groupIndex].attributes = [
                  ...attributeGroups[groupIndex].attributes,
                ];
                onUpdated(attributeGroups);
              }}
              okText="Có"
              cancelText="Không"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <Table
        expandable={{
          expandedRowRender: (item, attributeIndex) =>
            expandedRowAttributeValue(item, attributeIndex, groupIndex),
        }}
        columns={attributeColumns}
        dataSource={attributeGroup.attributes}
        rowKey={(record, index) => index?.toString() ?? ""}
      ></Table>
    );
  };

  useEffect(() => {
    getProductAttributeGroupList().then((res) => {
      setAttributeGroupDropdown(res.data);
    });
    getProductAttributeList().then((res) => {
      setAttributeDropdown(res.data);
    });
    getProductAttributeValueList().then((res) => {
      setAttributeValueDropdown(res.data);
    });
  }, []);

  return (
    <>
      <Button
        type="primary"
        className="mb-3"
        onClick={handleAddAttributeGroup}
        icon={<PlusCircleOutlined />}
      >
        Thêm nhóm thuộc tính
      </Button>
      <Table
        expandable={{
          expandedRowRender: expandedRowAttribute,
        }}
        dataSource={attributeGroups}
        columns={attributeGroupColumns}
        rowKey={(record, index) => index?.toString() ?? ""}
      ></Table>
    </>
  );
}
