export interface ProductCommentModel {
  key: string;
  id: number;
  name: string;
  email: string;
  phone: string;
  message: string;
  rating?: number;
  status: ProductCommentStatusEnum;
  staffId?: number;
  parentId?: number;
  customerId?: number;
  productId: number;
  productName: string;
  isActive: boolean;
  childrenComments: ProductCommentModel[];
  creationTime: Date;
}

export interface CreatedProductCommentModel {
  message: string;
  parentId?: number;
  productId: number;
}

export enum ProductCommentStatusEnum {
  New,
  Done,
}
