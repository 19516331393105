export interface TreatmentScheduleModel {
  key: string;
  id: number;
  fromDate?: Date;
  toDate?:Date;
  title:string;
  description: string;
  status: TreatmentScheduleStatus;
  treatmentId: number;
}
export interface CreatedTreatmentScheduleModel {
  fromDate?: Date;
  toDate?:Date;
  title:string;
  description: string;
  status: TreatmentScheduleStatus;
  treatmentId: number;
}
export interface UpdatedTreatmentScheduleModel {
  id: number;
  fromDate?: Date;
  toDate?:Date;
  title:string;
  description: string;
  status: TreatmentScheduleStatus;
  treatmentId: number;
}
export interface SearchTreatmentScheduleRequestModel {
  treatmentId?: string;
  page: number;
  pageSize: number;
}
export interface UpdatedTreatmentScheduleStatusModel{
  id: number;
  status: TreatmentScheduleStatus;
}
export interface RemovedTreatmentScheduleModel {
  idList: number[];
}
export enum TreatmentScheduleStatus{
  New,
  Done,
  Cancel
}

