import {
  BLOG_CATEGORY_API,
  BLOG_CATEGORY_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateBlogCategoryModel,
  RemovedBlogCategoryModel,
  SearchBlogCategoryRequestModel,
  BlogCategoryModel,
  UpdatedBlogCategoryModel,
} from "./blog-category.models";

export const searchBlogCategory = async (
  searchBlogCategoryRequestModel: SearchBlogCategoryRequestModel
) => {
  const createResponse = await postHttp<PagedResult<BlogCategoryModel>>(
    `${process.env.REACT_APP_API_URI}${BLOG_CATEGORY_API_SEARCH}`,
    searchBlogCategoryRequestModel
  );
  return createResponse;
};

export const removeBlogCategory = async (
  removedModel: RemovedBlogCategoryModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${BLOG_CATEGORY_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getBlogCategoryById = async (id: number) => {
  return await getHttp<BlogCategoryModel>(
    `${process.env.REACT_APP_API_URI}${BLOG_CATEGORY_API}/${id}`
  );
};

export const addBlogCategory = async (model: CreateBlogCategoryModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${BLOG_CATEGORY_API}`,
    model
  );
  return response;
};

export const updateBlogCategory = async (model: UpdatedBlogCategoryModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${BLOG_CATEGORY_API}`,
    model
  );
  return response;
};
