import React from "react";
import { Button, Divider, Input, notification, Popconfirm } from "antd";
import { IThemeConfigTextItem } from "states/theme-setting/theme-setting.models";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { ThemeTextItemEditor } from "./theme-text-item-modal";
const { TextArea } = Input;
interface ThemeConfigProps {
  model: IThemeConfigTextItem;
  onChange: (model: IThemeConfigTextItem) => void;
  onRemove?: (index: number) => void;
  index?: number;
  isHidelabel?: boolean;
  isHideRemove?: boolean;
}
export const ThemeTextItem = (props: ThemeConfigProps) => {
  const { model, index, isHidelabel, isHideRemove, onRemove, onChange } = props;
  const TextType = () => {
    switch (model.textType) {
      case 0:
        return (
          <TextArea
            rows={4}
            placeholder="Nội dung"
            value={model?.value ?? ""}
            onChange={(ev: any) => {
              ev.preventDefault();
              onChange({ ...model, value: ev.target.value });
            }}
          />
        );
      case 1:
        return (
          <ThemeTextItemEditor
            value={model?.value ?? ""}
            onEditorChange={(value) => {
              onChange({ ...model, value: value });
            }}
          />
        );
      case 2:
        return (
          <React.Fragment>
            <Input
              value={model?.value ?? ""}
              placeholder="Name"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                ev.preventDefault();
                onChange({ ...model, value: ev.target.value });
              }}
              className="mb-2"
            />
            <Input
              value={model?.url ?? ""}
              placeholder="Url (Optional)"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                ev.preventDefault();
                onChange({ ...model, url: ev.target.value });
              }}
            />
          </React.Fragment>
        );
    }
  };
  const onRemoveBtn = () => {
    onRemove && onRemove(index || 0);
  };

  return (
    <div className="mt-2 mb-2">
      {!isHidelabel && (
        <div className="theme-text-item mt-2">
          {!isHideRemove && (
            <React.Fragment>
              <Button icon={<CopyOutlined />} type="default" className="mr-2" >{model.name}</Button>
              <Popconfirm
                placement="topLeft"
                onConfirm={onRemoveBtn}
                title={`Bạn có chắc chắn muốn xóa không?`}
                okText="Đồng ý"
                cancelText="Hủy bỏ"
              >
                <Button
                  type="primary"
                  className="mb-1"
                  danger
                  icon={<DeleteOutlined />}
                >
                  Xóa phần tử
                </Button>
              </Popconfirm>
            </React.Fragment>
          )}
        </div>
      )}
      {TextType()}
    </div>
  );
};
