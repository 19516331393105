import React from "react";
import { createStore } from "redux";
import { rootReducer } from "./app.store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import { Route, Redirect, Router } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import "./app.less";
import EmptyPage from "./modules/core/empty";
import LoginPage from "./modules/account/login-page";

import LayoutApp from "./shared/layout/layout-app";
import AuthManagement from "shared/components/auth/auth-management";
import history from "shared/plugins/history";

import { Loading } from "shared/components/loading/loading";
import ForgotPasswordPage from "modules/account/forgot-password-page";
import UserManagementPage from "modules/user-management/user-management-page";
import RoleManagementPage from "modules/role-management/role-management-page";
import StaticPage from "modules/static-page/static-page";
import { AddUpdateStaticPage } from "modules/static-page/add-update-static-page";
import Contact from "modules/contact/contact";
import Coupon from "modules/coupon/coupon";
import BlogCategory from "modules/blog-category/blog-category";
import { AddUpdateBlog } from "modules/blog/add-update-blog";
import Blog from "modules/blog/blog";
import Staff from "modules/staff/staff";
import ThemeSetting from "modules/theme-config/theme-config-list";
import AddUpdateThemeConfig from "modules/theme-config/theme-config-update";
import MenuSetting from "modules/menu-setting/menu-page";
import ProductCategory from "modules/product-category/product-category";
import Product from "modules/product/product";
import Customer from "modules/customer/customer";
import CustomerDetail from "modules/customer/customer-detail";
import { AddUpdateProduct } from "modules/product/add-update-product";
import ProductLabel from "modules/product-label/product-label";
import Contract from "modules/contract/contract";
import Treatment from "modules/treatment/treatment";
import TreatmentSchedule from "modules/treatment/treatment-schedule";
import TreatmentImage from "modules/treatment/treatment-image";
import TreatmentNotification from "modules/treatment/treatment-notification";
import TreatmentProduct from "modules/treatment/treatment-product";
import { DefaultShippingFee } from "modules/shipping-fee/default-shipping-fee";
import { ShippingFeeAddress } from "modules/shipping-fee/shipping-fee-address";
import { ShippingFeeOrderAmount } from "modules/shipping-fee/shipping-fee-order-amount";
import ProductPriceFilter from "modules/product-price-filter/product-price-filter";
import NotificationManager from "modules/notification-manager/notification-manager";
import { ProductComment } from "modules/product-comment/product-comment";
import Order from "modules/order/order";
import ProductAttributeGroup from "modules/product-attribute/product-attribute-group";
import ProductAttribute from "modules/product-attribute/product-attribute";
import ProductAttributeValue from "modules/product-attribute/product-attribute-value";
import Tag from "modules/tag/tag";
import { AddUpdateProductCategory } from "modules/product-category/add-update-product-category";
import OrderDetail from "modules/order/order-detail";
import CustomerGroup from "modules/customer-group/customer-group";
import WebsiteConfig from "modules/website-config/website-config";
import WebsiteInformation from "modules/website-information/website-information";

const store = createStore(rootReducer, composeWithDevTools());

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <Router history={history}>
          <Loading>
            <Route path="/login" exact={true} component={LoginPage} />
            <Route
              path="/forgot-password"
              exact={true}
              component={ForgotPasswordPage}
            />
            <Route
              path="/forgot-password/:userId/:token"
              exact={true}
              component={ForgotPasswordPage}
            />
            <Route path="/empty" exact={true} component={EmptyPage} />
            <LayoutApp>
              <Route path="/" exact={true}>
                <Redirect to="/projects"></Redirect>
              </Route>
              <Route
                path="/user-management"
                exact={true}
                component={UserManagementPage}
              />
              <Route
                path="/role-management"
                exact={true}
                component={RoleManagementPage}
              />
              <Route path="/contact" exact={true} component={Contact} />
              <Route path="/staff" exact={true} component={Staff} />
              <Route path="/customers" exact={true} component={Customer} />
              <Route
                path="/notification-managers"
                exact={true}
                component={NotificationManager}
              />
              <Route
                path="/customers/:customerId"
                exact={true}
                component={CustomerDetail}
              />
              <Route
                path="/customers/:customerId/contracts"
                exact={true}
                component={Contract}
              />
              <Route
                path="/customers/:customerId/treatments"
                exact={true}
                component={Treatment}
              />
              <Route
                path={[
                  "/customers/:customerId/treatments/:treatmentId",
                  "/customers/:customerId/treatments/:treatmentId/products",
                ]}
                exact={true}
                component={TreatmentProduct}
              />
              <Route
                path="/customers/:customerId/treatments/:treatmentId/images"
                exact={true}
                component={TreatmentImage}
              />
              <Route
                path="/customers/:customerId/treatments/:treatmentId/notifications"
                exact={true}
                component={TreatmentNotification}
              />
              <Route
                path="/customers/:customerId/treatments/:treatmentId/schedules"
                exact={true}
                component={TreatmentSchedule}
              />
              <Route path="/contracts" exact={true} component={Contract} />
              <Route path="/treatments" exact={true} component={Treatment} />
              <Route path="/coupon" exact={true} component={Coupon} />
              <Route
                path="/blog-category"
                exact={true}
                component={BlogCategory}
              />
              <Route
                path="/product-category"
                exact={true}
                component={ProductCategory}
              />
              <Route
                path="/product-category/add"
                exact={true}
                component={AddUpdateProductCategory}
              />
              <Route
                path="/product-category/update/:categoryId"
                exact={true}
                component={AddUpdateProductCategory}
              />
              <Route path="/static-page" exact={true} component={StaticPage} />
              <Route
                path="/static-page/add"
                exact={true}
                component={AddUpdateStaticPage}
              />
              <Route
                path="/static-page/update/:staticPageId"
                exact={true}
                component={AddUpdateStaticPage}
              />
              <Route
                path="/menu-setting"
                exact={true}
                component={MenuSetting}
              />
              <Route
                path="/theme-setting"
                exact={true}
                component={ThemeSetting}
              />
              <Route
                path="/theme-setting/add"
                exact={true}
                component={AddUpdateThemeConfig}
              />
              <Route
                path="/theme-setting/update/:themeSettingId"
                exact={true}
                component={AddUpdateThemeConfig}
              />
              <Route path="/blog" exact={true} component={Blog} />
              <Route path="/blog/add" exact={true} component={AddUpdateBlog} />
              <Route
                path="/blog/update/:blogId"
                exact={true}
                component={AddUpdateBlog}
              />
              <Route path="/product" exact={true} component={Product} />
              <Route
                path="/product/update/:productId"
                exact={true}
                component={AddUpdateProduct}
              />
              <Route
                path="/product-label"
                exact={true}
                component={ProductLabel}
              />
              <Route
                path="/shipping-fee/default"
                exact={true}
                component={DefaultShippingFee}
              />
              <Route
                path="/shipping-fee/by-address"
                exact={true}
                component={ShippingFeeAddress}
              />
              <Route
                path="/shipping-fee/by-order-amount"
                exact={true}
                component={ShippingFeeOrderAmount}
              />
              <Route
                path="/product-price-filter"
                exact={true}
                component={ProductPriceFilter}
              />
                <Route
                path="/website-config"
                exact={true}
                component={WebsiteConfig}
              />
              <Route
                path="/website-information"
                exact={true}
                component={WebsiteInformation}
              />
              <Route
                path="/product-comment"
                exact={true}
                component={ProductComment}
              ></Route>
              <Route path="/orders" exact={true} component={Order} />
              <Route path="/order/update/:orderId" exact={true} component={OrderDetail} />
              <Route
                path="/product-attribute-group"
                exact={true}
                component={ProductAttributeGroup}
              />
              <Route
                path="/product-attribute"
                exact={true}
                component={ProductAttribute}
              />
              <Route
                path="/product-attribute-value"
                exact={true}
                component={ProductAttributeValue}
              />
              <Route path="/tag" exact={true} component={Tag} />
              <Route
                path="/customer-group"
                exact={true}
                component={CustomerGroup}
              />
              <Route path="/tag" exact={true} component={Tag} />
            </LayoutApp>
            <AuthManagement></AuthManagement>
          </Loading>
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
