import {
  Account_SetLoginUser,
  CurrentUser,
  Account_LogoutUser,
} from "./account.models";

export const setLoginUser = (user: CurrentUser) => {
  return {
    type: Account_SetLoginUser,
    payload: user,
  };
};

export const setLogoutUser = () => {
  return {
    type: Account_LogoutUser,
    payload: undefined,
  };
};
