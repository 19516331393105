import React, { FC, useState, useEffect, useCallback } from "react";
import {
  globalValueSetPageTitle,
  globalValueShowErrorToast,
  globalValueShowSuccessToast,
  globalValueShowWarningToast,
} from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import { Input, Select, Modal, Form, DatePicker, Switch } from "antd";

import {
  CouponModel,
  CouponType,
  CreateCouponModel,
  UpdatedCouponModel,
} from "states/coupon/coupon.models";
import { addCoupon, updateCoupon } from "states/coupon/coupon.api";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import moment from "moment";

interface ICouponModalProps {
  model: CouponModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  onSuccess: () => void;
}
const { Option } = Select;
export const CouponModalComponent: FC<ICouponModalProps> = (props) => {
  const { model, onModal, closeModal,onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isActive,setIsActive] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedCouponModel = {
          ...values,
          id: values.id,
          couponCode: values.couponCode,
          couponValue: values.couponValue,
          couponType: values.couponType,
          startDate: values.startDate,
          endDate: values.endDate,
          isActive: isActive,
        };
        await updateCoupon(payload);
      } else {
        let payload: CreateCouponModel = {
          ...values,
          couponCode: values.couponCode,
          couponValue: values.couponValue,
          couponType: values.couponType,
          startDate: values.startDate,
          endDate: values.endDate,
          isActive: isActive,
        };
        await addCoupon(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onTypeChange = (value: CouponType) => {
    switch (value) {
      case CouponType.Amount:
        form.setFieldsValue({ couponType: CouponType.Amount });
        return;
      case CouponType.Percent:
        form.setFieldsValue({ couponType: CouponType.Percent });
        return;
    }
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      couponType: model?.couponType,
      couponCode: model?.couponCode,
      couponValue: model?.couponValue,
      startDate: moment(model?.startDate),
      endDate: moment(model?.endDate),
      isActive: model?.isActive
    });
    setIsActive(model.isActive);
  };

  useEffect(() => {
    if(!isUpdate){
      onReset();
    }else{
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate ? `Cập nhật mã giảm giá` : `Thêm mã giảm giá`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="couponModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
       
        <Form.Item
          label="Mã giá giá"
          name="couponCode"
          rules={[{ required: true, message: "Vui lòng nhập mã giảm giá!" }]}
        >
            <Input className="w-100" placeholder="Mã giảm giá" />
        </Form.Item>
        <Form.Item
          label="Giá trị"
          name="couponValue"
          rules={[{ required: true, message: "Vui lòng nhập giá trị!" }]}
        >
          <Input type="number" className="w-100" placeholder="Giá trị" />
        </Form.Item>
        <Form.Item
          label="Loại giá trị"
          name="couponType"
          rules={[{ required: true, message: "Vui lòng chọn loại giảm giá!" }]}
        >
          <Select
            className="w-100"
            placeholder="Chọn loại giảm giá"
            onChange={onTypeChange}
            allowClear
          >
            <Option value={CouponType.Amount}>Số tiền</Option>
            <Option value={CouponType.Percent}>Phần trăm</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày bắt đầu!" }]}
        >
          <DatePicker
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            className="w-100"
            placeholder="Chọn ngày bắt đầu"
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày kết thúc!" }]}
        >
          <DatePicker
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            className="w-100"
            placeholder="Chọn ngày kết thúc"
          />
        </Form.Item>
        <Form.Item label="Kích hoạt" name="isActive">
          <Switch checked={isActive} onChange={(checked) =>{
            setIsActive(checked);
          }} />
        </Form.Item>
        <Form.Item
          hidden={true}
          name="id"
        >
           <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
