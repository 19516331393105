import { Input, Modal, Typography } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  createShippingFeeOrderAmount,
  updateShippingFeeOrderAmount,
} from "states/shipping-fee/shipping-fee.api";
import {
  CreatedShippingFeeOrderAmountModel,
  ShippingFeeOrderAmountModel,
  UpdatedShippingFeeOrderAmountModel,
} from "states/shipping-fee/shipping-fee.models";

interface IShippingFeeOrderAmountModalProps {
  model: ShippingFeeOrderAmountModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  onSuccess: () => void;
}

export function ShippingFeeOrderAmountModal(
  props: IShippingFeeOrderAmountModalProps
) {
  const { onModal, closeModal, onSuccess, isUpdate, model } = props;
  const [isLoading, setLoading] = useState(false);
  const [
    shippingFeeOrderAmountModel,
    setShippingFeeOrderAmountModel,
  ] = useState<ShippingFeeOrderAmountModel>({} as ShippingFeeOrderAmountModel);

  const handleCloseModal = () => closeModal();

  const onFinish = async () => {
    setLoading(true);
    try {
      if (isUpdate) {
        const payload = {
          id: shippingFeeOrderAmountModel.id,
          shippingFee: shippingFeeOrderAmountModel.shippingFee,
          amountFrom: shippingFeeOrderAmountModel.amountFrom,
          amountTo: shippingFeeOrderAmountModel.amountTo,
        } as UpdatedShippingFeeOrderAmountModel;
        await updateShippingFeeOrderAmount(payload);
      } else {
        const payload = {
          shippingFee: shippingFeeOrderAmountModel.shippingFee,
          amountFrom: shippingFeeOrderAmountModel.amountFrom,
          amountTo: shippingFeeOrderAmountModel.amountTo,
        } as CreatedShippingFeeOrderAmountModel;
        await createShippingFeeOrderAmount(payload);
      }
      handleCloseModal();
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setShippingFeeOrderAmountModel(model);
  }, [model]);
  return (
    <Modal
      title={
        <div>
          {isUpdate
            ? `Cập nhật phí ship theo tiền hàng`
            : `Thêm phí ship theo tiền hàng`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onFinish}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Input.Group className="mb-3">
        <Typography.Text>Giá trị đơn hàng từ</Typography.Text>
        <Input
          type="number"
          value={shippingFeeOrderAmountModel.amountFrom}
          onChange={(e) => {
            setShippingFeeOrderAmountModel({
              ...shippingFeeOrderAmountModel,
              amountFrom: e.target.valueAsNumber,
            });
          }}
        ></Input>
      </Input.Group>
      <Input.Group className="mb-3">
        <Typography.Text>Giá trị đơn hàng đến</Typography.Text>
        <Input
          type="number"
          value={shippingFeeOrderAmountModel.amountTo}
          onChange={(e) => {
            setShippingFeeOrderAmountModel({
              ...shippingFeeOrderAmountModel,
              amountTo: e.target.valueAsNumber,
            });
          }}
        ></Input>
      </Input.Group>
      <Input.Group className="mb-3">
        <Typography.Text>Phí ship</Typography.Text>
        <Input
          type="number"
          value={shippingFeeOrderAmountModel.shippingFee}
          onChange={(e) => {
            setShippingFeeOrderAmountModel({
              ...shippingFeeOrderAmountModel,
              shippingFee: e.target.valueAsNumber,
            });
          }}
        ></Input>
      </Input.Group>
    </Modal>
  );
}
