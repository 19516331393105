import { Button, Divider, notification, Popconfirm, Typography } from "antd";
import React, { useEffect } from "react";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";
import "./style/theme-image-list.less";
import {
  IThemeConfigImageItem,
  IThemeConfigImageList,
} from "states/theme-setting/theme-setting.models";
import _ from "lodash";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
interface ThemeConfigProps {
  model: IThemeConfigImageList;
  onChange: (model: IThemeConfigImageList) => void;
  onRemove?: (index: number) => void;
  index?: number;
}

export const ThemeImageList = (props: ThemeConfigProps) => {
  const { model, index, onChange, onRemove } = props;
  const _handleRemoveItem = () => {
    onRemove && onRemove(index || 0)
  };
  return (
    <div className="theme-image-list">
      <div className="pb-2 btn-w-child d-flex justify-content-between align-items-center flex-auto ">
        <Button icon={<CopyOutlined />} type="default" className="mr-2" >{model.name}</Button>
        <Popconfirm
          placement="topLeft"
          onConfirm={_handleRemoveItem}
          title={`Bạn có chắc chắn muốn xóa không?`}
          okText="Đồng ý"
          cancelText="Hủy bỏ"
        >
          <Button type="primary" danger={true} icon={<DeleteOutlined />}>
            Xóa Danh sách
          </Button>
        </Popconfirm>
      </div>
      <div className="pt-2">
        <UploadFileLocal
          album={[...model?.data].map((m) => m.src)}
          multiple={true}
          onChange={(newFileList: string[]) => {
            let modelDataTemp = newFileList.map((file: string) => {
              return {
                sort:
                  model.data.length > 0
                    ? model.data[model.data.length - 1].sort + 1
                    : 0,
                src: file,
              } as IThemeConfigImageItem;
            });
            model.data = modelDataTemp;
            onChange({ ...model });
          }}
        />
      </div>
    </div>
  );
};
