import React, { FC, useEffect, useState } from "react";
import { Input, Modal, Form } from "antd";

import {
  IMenuSettingModel,
  IUpdatedMenuSettingModel,
  ICreateMenuSettingModel,
} from "states/menu-setting/menu-setting.models";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { addMenuSetting } from "states/menu-setting/menu-setting.api";
import { values } from "lodash";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";

interface IMenuSettingModalProps {
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
}
export const AddMenuSettingModal: FC<IMenuSettingModalProps> = (props) => {
  const { onModal, closeModal, onSuccess } = props;
  const [isLoading, setLoading] = useState(false);
  const [form] = useForm();
  const [avatar, setAvatar] = useState<string>();
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      let payload: ICreateMenuSettingModel = {
        ...values,
        name: values.name,
        slug: values.slug,
        avatar: avatar,
        sort: values.sort,
        menuType: values.menuType,
        data: [],
      };
      onSuccess();
      await addMenuSetting(payload);
      handleCloseModal();
      setLoading(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
    setAvatar(undefined);
  };
  useEffect(() => {
    onReset();
  }, [onModal]);

  return (
    <div className="menu-setting-modal">
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onBlur={() => {}}
            // end
          >
            Thêm menu mới
          </div>
        }
        confirmLoading={isLoading}
        centered={true}
        visible={onModal}
        onOk={onSubmit}
        onCancel={handleCloseModal}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        <Form
          form={form}
          name="StaffModal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 8 }}
          initialValues={{ isActive: true }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Tên menu"
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập tên menu" }]}
          >
            <Input className="w-100" placeholder="Tên menu" />
          </Form.Item>
          <Form.Item label="Slug" name="slug">
            <Input className="w-100" placeholder="Slug" />
          </Form.Item>
          <Form.Item label="Hình đại diện" name="avatar">
            <UploadFileLocal
              isUploadImage={true}
              multiple={false}
              maxFileCount={1}
              album={avatar ? [avatar] : []}
              onChange={(newFileList) => {
                setAvatar(newFileList[0]);
              }}
            />
          </Form.Item>
          <Form.Item label="Thứ tự" name="sort">
            <Input className="w-100" placeholder="Thứ tự" />
          </Form.Item>
          <Form.Item label="Phân loại" name="menuType">
            <Input className="w-100" placeholder="Phân loại" />
          </Form.Item>
          <Form.Item hidden={true} name="id">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
