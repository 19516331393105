import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultErrorMessage } from "shared/constants/message";
import { changePasswordApi } from "states/account/account.api";
import { ChangePasswordModel } from "states/account/account.models";

interface ChangePasswordModalProps {
  visible?: boolean;
  onCancel?: () => void;
}
export const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const { visible, onCancel } = props;
  const [form] = useForm();
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const onFinish = async (value: any) => {
    setAddBtnLoading(true);
    let payload: ChangePasswordModel = { ...value };
    try {
      await changePasswordApi(payload);
      setAddBtnLoading(false);
      onCancel && onCancel();
      message.success("Cập nhật mật khẩu thành công");
    } catch (error) {
      setAddBtnLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const onSave = async () => {
    form.submit();
  };
  useEffect(() => {
    form.setFieldsValue({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [visible]);
  return (
    <div className="modal">
      <Modal
        title={`Change password`}
        visible={visible}
        onOk={onSave}
        confirmLoading={addBtnLoading}
        onCancel={onCancel}
        width={500}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addBtnLoading}
            onClick={onSave}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          form={form}
          className="form-layout"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="currentPassword"
            rules={[
              { required: true, message: "Current password is required" },
            ]}
          >
            <Input type="password" placeholder="Current password"></Input>
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: "New password is required" }]}
          >
            <Input type="password" placeholder="New password"></Input>
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: "Confirm new password is required" },
            ]}
          >
            <Input type="password" placeholder="Confirm new password"></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
