import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
} from "antd";
import _ from "lodash";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedContractModel,
  SearchContractRequestModel,
  ContractStatus,
  UpdatedContractStatusModel,
  ContractModel,
} from "states/contract/contract.models";
import {
  removeContract,
  searchContract,
  updateContractStatus,
} from "states/contract/contract.api";
import moment from "moment";
import { ContractModalComponent } from "./contract-modal";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { useParams } from "react-router-dom";
import { CustomerDetailBreadcrumb } from "modules/customer/components/customer-detail-breadcrumb";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { redirectFileLocalUrl } from "shared/plugins/string-helper";
const { Option } = Select;
interface ParamsRoute {
  customerId: string;
}
interface IContractProps {}
const Contract: FC<IContractProps> = () => {
  const [selectedRowsKeys, setSelectedRowsKeys] =
    useState<RemovedContractModel>({
      idList: [],
    });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<ContractModel>
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<ContractModel>({} as ContractModel);
  const params = useParams<ParamsRoute>();
  const [filterModel, setFilterModel] = useState<SearchContractRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách hợp đồng";
    getCustomerById(Number(params.customerId)).then((customerResponse) => {
      setCustomerModel(customerResponse.data);
    });
  }, []);
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleAddContract = () => {
    setModel({ status: ContractStatus.New } as ContractModel);
    setModal(true);
    setUpdate(false);
  };
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    if (!_.isEmpty(params)) {
      filterModel.customerId = params.customerId;
    }
    const result = await searchContract({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const handleChangeStatus = async (model: UpdatedContractStatusModel) => {
    setIsLoadingList(true);
    try {
      await updateContractStatus(model);
      setIsLoadingList(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
        onOk: () => {
          handleSearch.callback();
        },
      });
    } catch (e) {
      setIsLoadingList(false);
      Modal.error({
        title: "Thông báo",
        content: e?.response?.data?.error?.message ?? DefaultErrorMessage,
        onOk: () => {
          handleSearch.callback();
        },
      });
    }
  };
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeContract(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const expandedRowRender = (data: ContractModel) => {
    return (
      <div>
        <div>
          <Typography.Text>
            File hợp đồng khách hàng:{" "}
            <a href={redirectFileLocalUrl(data.fileUrl)}>{data.fileUrl}</a>
          </Typography.Text>
        </div>
      </div>
    );
  };
  const columns: ColumnsType<ContractModel> = [
    {
      title: "Số hợp đồng",
      dataIndex: "contractNumber",
      key: "contractNumber",
      render: (contractNumber: string, item) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              setModel(item);
              setModal(true);
              setUpdate(true);
            }}
          >
            {contractNumber}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Tên hợp đồng",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <React.Fragment>{name}</React.Fragment>,
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => (
        <React.Fragment>
          {moment(startDate).format("DD-MM-yyyy")}
        </React.Fragment>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status: ContractStatus, item) => (
        <React.Fragment>
          <Select
            placeholder="Chọn trạng thái"
            optionFilterProp="children"
            value={status}
            onChange={(e) => {
              let modal = Modal.confirm({
                title: "Xác nhận",
                icon: <ExclamationCircleOutlined />,
                content: "Bạn có muốn thay đổi trạng thái không?",
                okText: "Đồng ý",
                visible: false,
                onOk: () => {
                  handleChangeStatus({ id: item.id, status: e.valueOf() });
                  modal.destroy();
                },
                cancelText: "Huỷ bỏ",
              });
            }}
          >
            <Option value={ContractStatus.New}>
              <Tag color="#108ee9">Chưa kí</Tag>
            </Option>
            <Option value={ContractStatus.Processing}>
              <Tag color="#9254de">Đang Chờ duyệt </Tag>
            </Option>
            <Option value={ContractStatus.Done}>
              <Tag color="#87d068">Đã kí</Tag>
            </Option>
            <Option value={ContractStatus.Cancel}>
              <Tag color="#f50">Hủy </Tag>
            </Option>
          </Select>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="contract-page">
      <CustomerDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
      ></CustomerDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="2"
        customerId={params.customerId}
      ></CustomerDetailMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên hợp đồng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.contractNumber}
            placeholder="Nhập số hợp đồng cần tìm..."
            onChange={(e) => {
              setFilterModel({
                ...filterModel,
                contractNumber: e.target.value,
              });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Chọn trạng thái"
            optionFilterProp="children"
            allowClear={true}
            value={filterModel.status}
            onChange={(e) => {
              setFilterModel({
                ...filterModel,
                status: e?.valueOf() ?? undefined,
              });
            }}
          >
            <Option value={ContractStatus.New}>
              <Tag color="#108ee9">Chưa kí</Tag>
            </Option>
            <Option value={ContractStatus.Processing}>
              <Tag color="#9254de">Đang Chờ duyệt </Tag>
            </Option>
            <Option value={ContractStatus.Done}>
              <Tag color="#87d068">Đã kí</Tag>
            </Option>
            <Option value={ContractStatus.Cancel}>
              <Tag color="#f50">Hủy </Tag>
            </Option>
          </Select>
        </Col>
        <Col span={6} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddContract}
            icon={<PlusCircleOutlined />}
          >
            Thêm hợp đồng mới
          </Button>
        </Col>
      </Row>
      <Table
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <ContractModalComponent
        customerId={params.customerId}
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleSearch.callback}
        isUpdate={isUpdate}
      />
    </div>
  );
};

export default Contract;
