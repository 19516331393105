import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
 
  Typography,
} from "antd";

import {  PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  SearchCustomerRequestModel,
  CustomerModel,
} from "states/customer/customer.models";
import {  searchCustomer } from "states/customer/customer.api";
import { CustomerModalComponent } from "./components/customer-modal";
import { Link } from "react-router-dom";

interface ICustomerProps {}
const Customer: FC<ICustomerProps> = () => {
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<CustomerModel>
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<CustomerModel>({} as CustomerModel);

  const [filterModel, setFilterModel] = useState<SearchCustomerRequestModel>({
    page: 1,
    pageSize: 20,
  } as SearchCustomerRequestModel);

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Khách hàng";
  }, []);
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleAddCustomer = () => {
    setModel({} as CustomerModel);
    setModal(true);
    setUpdate(false);
  };
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchCustomer({ ...filterModel, page });
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const columns: ColumnsType<CustomerModel> = [
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <React.Fragment>
          <Link to={`/customers/${item.id}`}>
            <Button type="text">{name}</Button>
          </Link>
        </React.Fragment>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => <Typography.Text>{email}</Typography.Text>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber: string) => (
        <Typography.Text>{phoneNumber}</Typography.Text>
      ),
    },
  ];
  return (
    <div className="Customer">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên khách hàng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.email}
            placeholder="Nhập email khách hàng  cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, email: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.phoneNumber}
            placeholder="Nhập số điện thoại khách hàng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, phoneNumber: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddCustomer}
            icon={<PlusCircleOutlined />}
          >
            Thêm khách hàng
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      
      <CustomerModalComponent
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleSearch.callback}
        isUpdate={isUpdate}
      />
    </div>
  );
};

export default Customer;
