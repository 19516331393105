import {
  PRODUCT_API,
  PRODUCT_API_KIOTVIET,
  PRODUCT_API_SEARCH,
  SEARCH_PRODUCT_API,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedProductModel,
  SearchProductRequestModel,
  ProductModel,
  UpdatedProductModel,
  SearchProductModel,
  ProductAndCommentModel,
} from "./product.models";

export const searchProduct = async (
  searchProductRequestModel: SearchProductRequestModel
) => {
  const createResponse = await postHttp<PagedResult<ProductModel>>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API_SEARCH}`,
    searchProductRequestModel
  );
  return createResponse;
};

export const removeProduct = async (removedModel: RemovedProductModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductById = async (id: number) => {
  return await getHttp<ProductModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API}/${id}`
  );
};

export const updateProduct = async (model: UpdatedProductModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API}`,
    model
  );
  return response;
};

export const syncKiotVietProduct = async () => {
  return await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API_KIOTVIET}`
  );
};

export const getAllProductDropdown = async () => {
  return await getHttp<SearchProductModel[]>(
    `${process.env.REACT_APP_API_URI}${SEARCH_PRODUCT_API}`
  );
};

export const getProductHasNewComment = async (
  page: number = 1,
  pageSize: number = 50
) => {
  return await getHttp<PagedResult<ProductAndCommentModel>>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_API}/has_new_comments?page=${page}&pageSize=${pageSize}`
  );
};
