import {
  TREATMENT_NOTIFICATION_API,
  TREATMENT_NOTIFICATION_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedTreatmentNotificationModel,
  SearchTreatmentNotificationRequestModel,
  TreatmentNotificationModel,
  UpdatedTreatmentNotificationModel,
  CreatedTreatmentNotificationModel,
  PushNotificationTreatmentNotificationModel,
} from "./treatment-notification.models";

export const searchTreatmentNotification = async (
  searchTreatmentRequestModel: SearchTreatmentNotificationRequestModel
) => {
  const createResponse = await postHttp<PagedResult<TreatmentNotificationModel>>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API_SEARCH}`,
    searchTreatmentRequestModel
  );
  return createResponse;
};
export const removeTreatmentNotification = async (
  removedModel: RemovedTreatmentNotificationModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addTreatmentNotification = async (model: CreatedTreatmentNotificationModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API}`,
    model
  );
  return response;
};
export const pushNotificationTreatmentNotification = async (model: PushNotificationTreatmentNotificationModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API}/send_notification`,
    model
  );
  return response;
};
export const getTreatmentNotificationById = async (id: number) => {
  return await getHttp<TreatmentNotificationModel>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API}/${id}`
  );
};

export const updateTreatmentNotification = async (model: UpdatedTreatmentNotificationModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_NOTIFICATION_API}`,
    model
  );
  return response;
};
