import React, { FC, useState, useEffect } from "react";
import { Input, Modal, Form, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  CreateNotificationManagerModel,
  NotificationManagerModel,
  NotiType,
  UpdatedNotificationManagerModel,
} from "states/notification-manager/notification-manager.models";
import {
  addNotificationManager,
  updateNotificationManager,
} from "states/notification-manager/notification-manager.api";

interface INotificationManagerModalProps {
  model: NotificationManagerModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
const { Option } = Select;
export const NotificationManagerModalComponent: FC<INotificationManagerModalProps> = (
  props
) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [notiType, setNotiType] = useState<NotiType>();
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedNotificationManagerModel = {
          ...values,
          id: values.id,
          message: values.message,
          data: values.data,
          notiType: values.notiType,
          title: values.title,
        };
        await updateNotificationManager(payload);
      } else {
        let payload: CreateNotificationManagerModel = {
          ...values,
          message: values.message,
          data: values.data,
          notiType: values.notiType,
          title: values.title,
        };
        await addNotificationManager(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      message: model?.message,
      data: model?.data,
      notiType: model?.notiType,
      title: model?.title,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật nội dung thông báo` : `Thêm nội dung thông báo`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="notificationManagerModal"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
        >
          <Input className="w-100" placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item
          label="Nội dung"
          name="message"
          rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
        >
          <Input.TextArea className="w-100" placeholder="Nội dung" />
        </Form.Item>
        <Form.Item
          label="Loại thông báo"
          name="notiType"
          rules={[{ required: true, message: "Vui lòng chọn loại thông báo!" }]}
        >
          <Select
            className="w-100"
            placeholder="Chọn loại thông báo"
            onChange={(value: NotiType) => {
              setNotiType(value);
              form.setFieldsValue({ notiType: value });
            }}
            allowClear
          >
            <Option value={NotiType.Normal}>Mặc định</Option>
            <Option value={NotiType.Promotion}>Chương trình khuyến mãi</Option>
          </Select>
        </Form.Item>
        {notiType === NotiType.Promotion && (
          <React.Fragment>
            <Form.Item label="Đường dẫn khuyến mãi" name="data">
              <Input className="w-100" placeholder="Đường dẫn khuyến mãi" />
            </Form.Item>
          </React.Fragment>
        )}

        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
