import {
  WEBSITE_CONFIGURATION_API,
} from "shared/constants/api-endpoint";
import { getHttp, putHttp } from "shared/plugins/http-service";
import {
  WebsiteConfigModel,
} from "./website-config.models";
export const getWebsiteConfig = async () => {
  return await getHttp<WebsiteConfigModel>(`${process.env.REACT_APP_API_URI}${WEBSITE_CONFIGURATION_API}`);
};
export const updateWebsiteConfig = async (model: WebsiteConfigModel) => {
  const response = await putHttp(`${process.env.REACT_APP_API_URI}${WEBSITE_CONFIGURATION_API}`, model);
  return response;
};
