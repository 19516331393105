import { postHttp, getHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  ROLE_MANAGEMENT_API,
  ROLE_MANAGEMENT_API_PERMISSION,
} from "shared/constants/api-endpoint";

import {
  AppPermissionModel,
  CreateRoleManagementModel,
  RemovedRoleManagementModel,
  RoleManagementModel,
  UpdateRoleManagementModel,
} from "./role-management.models";

export const createRole = async (
  createUserManagementModel: CreateRoleManagementModel
) => {
  const createResponse = await postHttp<string>(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API}`,
    createUserManagementModel
  );
  return createResponse;
};

export const getRoles = async () => {
  const createResponse = await getHttp<RoleManagementModel[]>(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API}`
  );
  return createResponse;
};

export const getRoleById = async (id: string) => {
  return await getHttp<RoleManagementModel>(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API}/${id}`
  );
};

export const updateRole = async (updateModel: UpdateRoleManagementModel) => {
  const response = await putHttp(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API}`,
    updateModel
  );
  return response;
};
export const removeRole = async (removedModel: RemovedRoleManagementModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getAppPermissions = async () => {
  return await getHttp<AppPermissionModel[]>(
    `${process.env.REACT_APP_API_URI}${ROLE_MANAGEMENT_API_PERMISSION}`
  );
};
