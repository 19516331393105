import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  message,
  Drawer,
  notification,
  Select,
  Modal,
} from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

import {
  RemovedNotificationManagerModel,
  NotificationManagerModel,
  SearchNotificationManagerRequestModel,
  NotiType,
  SendNotificationManagerModel,
} from "states/notification-manager/notification-manager.models";
import {
  removeNotificationManager,
  searchNotificationManager,
  sendNotificationManager,
} from "states/notification-manager/notification-manager.api";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { NotificationManagerModalComponent } from "./notification-manager-modal";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";
import { CustomerGroupSelectModalComponent } from "./customer-group-select-modal";

interface INotificationManagerProps {}
const { Option } = Select;
const NotificationManager: FC<INotificationManagerProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedNotificationManagerModel>({
    idList: [],
  });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<NotificationManagerModel>
  );
  const [isModal, setModal] = useState(false);
  const [onModalCustomerGroup, setModalCustomerGroup] = useState(false);
  const [notificationId, setNotificationId] = useState<string | number>("");
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<NotificationManagerModel>(
    {} as NotificationManagerModel
  );

  const [
    filterModel,
    setFilterModel,
  ] = useState<SearchNotificationManagerRequestModel>({
    page: 1,
    pageSize: 20,
  } as SearchNotificationManagerRequestModel);
  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Quản lí tin nhắn thông báo";
  }, []);
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleAddNotificationManager = () => {
    setModel({} as NotificationManagerModel);
    setModal(true);
    setUpdate(false);
  };

  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchNotificationManager({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeNotificationManager(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<NotificationManagerModel> = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string, item) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              setModel(item);
              setModal(true);
              setUpdate(true);
            }}
          >
            {title}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Tin nhắn",
      dataIndex: "message",
      key: "message",
      render: (message: string, item) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              setModel(item);
              setModal(true);
              setUpdate(true);
            }}
          >
            {message}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Nhóm khách hàng",
      key: "action",
      width: 200,
      render: (item: NotificationManagerModel) => (
        <React.Fragment>
          <Button
            type="primary"
            onClick={() => {
              setModalCustomerGroup(true);
              setNotificationId(item.id);
            }}
          >
            Gửi
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Tất cả",
      key: "action",
      width: 200,
      render: (item: NotificationManagerModel) => (
        <React.Fragment>
          <Button
            type="primary"
            danger={true}
            onClick={() => {
              Modal.confirm({
                title: "Thông báo",
                content:
                  "Bạn có muốn gửi thông báo đến tất cả khách hàng không?",
                onOk: async () => {
                  await sendNotificationManager({
                    id: item.id,
                  } as SendNotificationManagerModel);
                  Modal.success({
                    title: "Thành công",
                    content: UpdateDataSuccessfully,
                  });
                },
                onCancel: () => {
                  Modal.destroyAll();
                },
                cancelText: "Huỷ bỏ",
                okText: "Đồng ý",
              });
            }}
          >
            Gửi
          </Button>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="NotificationManager">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Select
            placeholder="Tìm loại thông báo"
            optionFilterProp="children"
            allowClear={true}
            className="w-100"
            value={filterModel.notiType}
            onChange={(e) => {
              setFilterModel({
                ...filterModel,
                notiType: e?.valueOf() ?? undefined,
              });
            }}
          >
            <Option value={NotiType.Normal}>Mặc định</Option>
            <Option value={NotiType.Promotion}>Chương trình khuyến mãi</Option>
          </Select>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddNotificationManager}
            icon={<PlusCircleOutlined />}
          >
            Thêm tin nhắn thông báo
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        loading={isLoadingList}
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <NotificationManagerModalComponent
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleSearch.callback}
        isUpdate={isUpdate}
      />
      <CustomerGroupSelectModalComponent
        notificationId={notificationId}
        onModal={onModalCustomerGroup}
        closeModal={()=>{
          setModalCustomerGroup(false);
        }}
      />
    </div>
  );
};

export default NotificationManager;
