import React, { FC,  useCallback } from "react";
import {
  Select,
  Tag,
} from "antd";
import {
  OrderStatus,
} from "states/order/order.models";

interface IOrderStatusProps {
  orderStatus?: OrderStatus;
  onChange?: (orderStatus: OrderStatus) => void;
}
const { Option } = Select;
export const OrderStatusComponent: FC<IOrderStatusProps> = (props) => {
  const {orderStatus, onChange} = props;
  const _handleOnChange = useCallback((value: number) =>{
    onChange && onChange(value);
  },[onChange])
  return (
    <Select
      className="w-100"
      placeholder="Trạng thái đơn hàng"
      onChange={_handleOnChange}
      allowClear
      value={orderStatus}
      showSearch
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={OrderStatus.Processing}>
        <Tag  color="#108ee9">Đợi duyệt</Tag>
      </Option>
      <Option value={OrderStatus.Shipping}>
        <Tag color="#faad14">Đang giao hàng</Tag>
      </Option>
      <Option value={OrderStatus.Completed}>
        <Tag color="#87d068">Hoàn thành</Tag>
      </Option>
      <Option value={OrderStatus.Cancel}>
        <Tag color="#f50">Hủy đơn hàng</Tag>
      </Option>
    </Select>
  );
};
