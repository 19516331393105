import { PRODUCT_LABEL_API } from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  RemovedProductLabelModel,
  ProductLabelModel,
} from "./product-label.models";

export const getProductLabelList = async () => {
  const createResponse = await getHttp<ProductLabelModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_LABEL_API}`
  );
  return createResponse;
};

export const removeProductLabel = async (
  removedModel: RemovedProductLabelModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_LABEL_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductLabelById = async (id: number) => {
  return await getHttp<ProductLabelModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_LABEL_API}/${id}`
  );
};

export const addProductLabel = async (model: ProductLabelModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_LABEL_API}`,
    model
  );
  return response;
};

export const updateProductLabel = async (model: ProductLabelModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_LABEL_API}`,
    model
  );
  return response;
};
