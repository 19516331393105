import {
  NOTIFICATION_MANAGER_API,
  NOTIFICATION_MANAGER_API_SEARCH,
  SEND_CG_NOTIFICATION_API
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateNotificationManagerModel,
  RemovedNotificationManagerModel,
  SearchNotificationManagerRequestModel,
  NotificationManagerModel,
  UpdatedNotificationManagerModel,
  SendNotificationManagerModel,
  SendNotificationCustomerGroupModel,
} from "./notification-manager.models";

export const searchNotificationManager = async (
  searchNotificationManagerRequestModel: SearchNotificationManagerRequestModel
) => {
  const createResponse = await postHttp<PagedResult<NotificationManagerModel>>(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API_SEARCH}`,
    searchNotificationManagerRequestModel
  );
  return createResponse;
};

export const removeNotificationManager = async (
  removedModel: RemovedNotificationManagerModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getNotificationManagerById = async (id: number) => {
  return await getHttp<NotificationManagerModel>(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API}/${id}`
  );
};

export const addNotificationManager = async (model: CreateNotificationManagerModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API}`,
    model
  );
  return response;
};


export const sendNotificationManager = async (model: SendNotificationManagerModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API}/send_notification`,
    model
  );
  return response;
};

export const sendNotificationCustomerGroup = async (model: SendNotificationCustomerGroupModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${SEND_CG_NOTIFICATION_API}`,
    model
  );
  return response;
};

export const updateNotificationManager = async (model: UpdatedNotificationManagerModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${NOTIFICATION_MANAGER_API}`,
    model
  );
  return response;
};
