export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ForgotPasswordModel {
  email: string;
}
export interface ResetPasswordModel {
  userId: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export interface AccountState {
  currentUser?: CurrentUser;
  isAuthentication?: boolean;
}

export interface LoginResponse {
  accessToken?: string;
  refreshToken?: string;
}

export interface CurrentUser {
  lockoutEnd?: string;
  phoneNumberConfirmed?: boolean;
  phoneNumber?: string;
  emailConfirmed?: false;
  normalizedEmail?: string;
  email?: string;
  normalizedUserName?: string;
  userName?: string;
  id: string;
  accessFailedCount?: number;
  firstName?: string;
  lastName?: string;
}

export interface LoginUserModel {
  userName: string;
  password: string;
}

export const Account_SetLoginUser = "Account_SetLoginUser";
interface AccountSetLoginUser {
  type: typeof Account_SetLoginUser;
  payload: CurrentUser;
}

export const Account_LogoutUser = "Account_LogoutUser";
interface AccountLogOutUser {
  type: typeof Account_LogoutUser;
  payload: undefined;
}

export type AccountActionType = AccountSetLoginUser | AccountLogOutUser;
