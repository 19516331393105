import {
  COUPON_API,
  COUPON_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateCouponModel,
  RemovedCouponModel,
  SearchCouponRequestModel,
  CouponModel,
  UpdatedCouponModel,
} from "./coupon.models";

export const searchCoupon = async (
  searchCouponRequestModel: SearchCouponRequestModel
) => {
  const createResponse = await postHttp<PagedResult<CouponModel>>(
    `${process.env.REACT_APP_API_URI}${COUPON_API_SEARCH}`,
    searchCouponRequestModel
  );
  return createResponse;
};

export const removeCoupon = async (
  removedModel: RemovedCouponModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${COUPON_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getCouponById = async (id: number) => {
  return await getHttp<CouponModel>(
    `${process.env.REACT_APP_API_URI}${COUPON_API}/${id}`
  );
};

export const addCoupon = async (model: CreateCouponModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${COUPON_API}`,
    model
  );
  return response;
};

export const updateCoupon = async (model: UpdatedCouponModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${COUPON_API}`,
    model
  );
  return response;
};
