import {
  ORDER_API,
  ORDER_API_ORDER_STATUS,
  ORDER_API_PAYMENT_STATUS,
  ORDER_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedOrderModel,
  SearchOrderRequestModel,
  OrderModel,
  UpdatedOrderModel,
  UpdatedPaymentStatusModel,
  UpdatedOrderStatusModel,
} from "./order.models";

export const searchOrder= async (
  searchOrderRequestModel: SearchOrderRequestModel
) => {
  const createResponse = await postHttp<PagedResult<OrderModel>>(
    `${process.env.REACT_APP_API_URI}${ORDER_API_SEARCH}`,
    searchOrderRequestModel
  );
  return createResponse;
};

export const removeOrder = async (
  removedModel: RemovedOrderModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${ORDER_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getOrderById = async (id: number) => {
  return await getHttp<OrderModel>(
    `${process.env.REACT_APP_API_URI}${ORDER_API}/${id}`
  );
};

export const updateOrder = async (model: UpdatedOrderModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${ORDER_API}`,
    model
  );
  return response;
};

export const updateOrderStatus = async (model: UpdatedOrderStatusModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${ORDER_API_ORDER_STATUS}`,
    model
  );
  return response;
};
export const updatePaymentStatus = async (model: UpdatedPaymentStatusModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${ORDER_API_PAYMENT_STATUS}`,
    model
  );
  return response;
};

