import React, { FC, useState, useEffect } from "react";
import { Input,  Modal, Form } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { TreatmentProductModel,  UpdatedTreatmentProductModel } from "states/treatment/treatment-product.models";
import { updateTreatmentProduct } from "states/treatment/treatment-product.api";
import { MceEditor } from "shared/components/tinymce/mce-editor";

interface ITreatmentProductModalProps {
  model: TreatmentProductModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  treatmentId: string;
  onSuccess: () => void;
}
export const TreatmentUpdateProductModalComponent: FC<ITreatmentProductModalProps> = (props) => {
  const { model, onModal,treatmentId, closeModal,onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [tutorial,setTutorial] = useState("");
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      let payload: UpdatedTreatmentProductModel = {
        ...values,
        id: values.id,
        treatmentId: Number(treatmentId),
        productId: values.productId,
        usedTime: values.status,
        tutorial: values.tutorial,
        sort : values.sort,
      };
      await updateTreatmentProduct(payload);
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onSubmit = () => {
    form.submit();
  };
  
  const onFill = () => {
    form.setFieldsValue({
      productId: model?.productId,
      treatmentId: model?.treatmentId,
      tutorial: model?.tutorial,
      sort: model?.sort,
      usedTime: model.usedTime,
    });
    setTutorial(model?.tutorial);
  };
  useEffect(() => {
    onFill();
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {`Cập nhật sản phẩm ${model?.productName} điều trị`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="treatmentModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Hướng dẫn"
          name="tutorial"
        >
          <MceEditor height={400} pageContent={tutorial} onChange={(value) =>{
            setTutorial(value)
            form.setFieldsValue({"tutorial":value});
          }} />
        </Form.Item>
       <Form.Item
          label="Số lần sử dụng"
          name="usedTime"
        >
          <Input  className="w-100"  type="number" />
        </Form.Item>      
        <Form.Item
          label="Thứ tự"
          name="sort"
        >
          <Input  className="w-100"  type="number" />
        </Form.Item>
        <Form.Item
          hidden={true}
          name="productId"
        >
           <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
