import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { LockOutlined } from "@ant-design/icons";
import { DefaultErrorMessage } from "shared/constants/message";
import { ResetPasswordModel } from "states/account/account.models";
import { resetPasswordApi } from "states/account/account.api";

interface ResetPasswordProps {
  userId: string;
  token: string;
}

export const ResetPasswordForm: FC<ResetPasswordProps> = (props) => {
  const { userId, token } = props;
  const [form] = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onSendResetPassword = async (values: any) => {
    setLoading(true);
    let payload: ResetPasswordModel = {
      ...values,
      userId: userId,
      token: token,
    };
    try {
      if (loading) {
        return;
      }
      await resetPasswordApi(payload);
      message.success("Reset mật khẩu thành công");
      setLoading(false);
      history.replace("/login");
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {}, []);
  return (
    <Form
      form={form}
      name="login-form"
      onFinish={onSendResetPassword}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}
      >
        <Input
          type="password"
          size={"large"}
          prefix={<LockOutlined />}
          placeholder="Mật khẩu mới"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[{ required: true, message: "Vui lòng nhập lại mật khẩu mới" }]}
      >
        <Input
          type="password"
          size={"large"}
          prefix={<LockOutlined />}
          placeholder="Nhập lại mật khẩu"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block={true}
          size="large"
          className="mb-2"
          loading={loading}
        >
          Xác nhận
        </Button>
        <Link className="login-form-forgot" to="/login">
          Quay lại
        </Link>
      </Form.Item>
    </Form>
  );
};
