import React, { FC, useState, useEffect } from "react";

import {
  Row,
  Col,
  Input,
  message,
  Card,
  Form,
  Modal,
  Button,
  Select,
} from "antd";
import { WebsiteConfigModel } from "states/website-config/website-config.models";
import { getWebsiteConfig, updateWebsiteConfig } from "states/website-config/website-config.api";
import { Store } from "antd/lib/form/interface";
import { SaveOutlined } from "@ant-design/icons";
import { getTimeZones } from "states/system-setting/system-setting.api";
import { TimezoneModel } from "states/system-setting/system-setting.models";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
interface IWebsiteConfigProps {}
const { TextArea } = Input;
const { Option } = Select;
const WebsiteConfig: FC<IWebsiteConfigProps> = () => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState<TimezoneModel[]>([]);
  const getConfig = async () => {
    try {
      const res = await getWebsiteConfig();
      form.setFieldsValue(res.data);
    
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const getTimezone = async () =>{
    try {
      const res = await getTimeZones();
      setTimeZoneList(res.data);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  }
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      let payload = {
        id: values?.id,
        privateGoogleCaptcha:values?.privateGoogleCaptcha,
        publicGoogleCaptcha: values?.publicGoogleCaptcha,
        timeZone: values?.timeZone,
        dateTimeFormat: values?.dateTimeFormat,
        dateFormat: values?. dateFormat,
        googleAnalytic: values?.googleAnalytic,
        webmasterTool: values?.webmasterTool,
        codeBody2: values?.codeBody2,
        customizeJs: values?.customizeJs,
        customizeCss: values?.customizeCss,
        metaTitle: values?.metaTitle,
        metaDescription: values?.metaDescription,
        metaKeyword: values?.metaKeyword,
        emailNotification: values?.emailNotification,
      } as WebsiteConfigModel;
      await updateWebsiteConfig(payload);
      setLoading(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: DefaultErrorMessage,
        content:
          error?.response?.data?.error?.message ??
          DefaultErrorMessage
      });
      setLoading(false);
    }
  };
  const onSaved = () =>{
    form.submit();
  }
  useEffect(() => {
    getConfig();
    getTimezone();
  }, []);

  
  return (
    
    <div className="website-config">
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between">
      <Col span={24}>
        <Card title={`Cấu hình website`} bordered={false}>
          <Form form={form} name="website-config" onFinish={onFinish} layout="vertical">
            <Form.Item
              label={`Meta Title`}
              name="metaTitle"
              rules={[{ required: true, message:"Yêu cầu nhập meta title" }]}
            >
              <Input className="w-100" placeholder={`Meta Title`} />
            </Form.Item>
            <Form.Item
              label={`Meta Description`}
              name="metaDescription"
              rules={[{ required: true, message:"Yêu cầu nhập meta description"}]}
            >
              <Input className="w-100" placeholder={`Meta Description`} />
            </Form.Item>
            <Form.Item
              label={`Meta Keyword`}
              name="metaKeyword"
              rules={[{ required: true, message:"Yêu cầu nhập meta keyword" }]}
            >
              <Input className="w-100" placeholder={`Meta Keyword`} />
            </Form.Item>
            <Form.Item
              label={`Múi giờ`}
              name="timeZone"
            >
              <Select
                className="w-100"
                onChange={(value: string)=>{
                  form.setFieldsValue({ note: value });
                }}
                placeholder={`Múi giờ`}
                allowClear
              >
                {timeZoneList && timeZoneList.map((m) => (
                    <Option value={m.id}>{m.displayName}</Option>
                ))}
               
              </Select>
            </Form.Item>
            <Form.Item
              label={`Định dạng ngày giờ`}
              name="dateTimeFormat"
              rules={[{ required: true, message:`Vui lòng nhập định dạng ngày giờ`}]}
            >
              <Input className="w-100" placeholder={`Định dạng ngày giờ`} />
            </Form.Item>
            <Form.Item
              label={`Định dạng ngày`}
              name="dateFormat"
              rules={[{ required: true, message:`Vui lòng nhập định dạng ngày`}]}
            >
              <Input className="w-100" placeholder={`Định dạng ngày`} />
            </Form.Item>
            <Form.Item
              label={`Chèn code ở trong thẻ <head>`}
              name="googleAnalytic"
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="w-100" placeholder={`Code`} />
            </Form.Item>
            <Form.Item
              label={`Chèn code ngay sau thẻ mở <body>`}
              name="webmasterTool"
            >
             <TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="w-100" placeholder={`Code`} />
            </Form.Item>
            <Form.Item
              label={`Chèn code ở cuối thẻ <body>`}
              name="codeBody2"
            >
             <TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="w-100" placeholder={`Code`} />
            </Form.Item>
            <Form.Item
              label={`Tùy chỉnh css`}
              name="customizeCss"
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="w-100" placeholder={`Tùy chỉnh css`} />
            </Form.Item>
            <Form.Item
              label={`Tùy chỉnh js`}
              name="customizeJs"
            >
               <TextArea autoSize={{ minRows: 3, maxRows: 5 }} className="w-100" placeholder={`Tùy chỉnh js`} />
            </Form.Item>
            <Form.Item
              label={`Public Google Captcha`}
              name="publicGoogleCaptcha"
            >
              <Input className="w-100" placeholder={`Public Google Captcha`} />
            </Form.Item>
            <Form.Item
              label={`Private Google Captcha`}
              name="privateGoogleCaptcha"
            >
              <Input className="w-100" placeholder={`Private Google Captcha`} />
            </Form.Item>
            <Form.Item hidden={true} name="id">
              <Input type="hidden" />
            </Form.Item>
        </Form>
        </Card>
      </Col>

      </Row>
    </div>
  );
};

export default WebsiteConfig;
