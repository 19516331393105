import { Modal, Form, Input, message, Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import {
  createUser,
  updateUser,
} from "states/user-management/user-management.api";
import { DefaultErrorMessage } from "shared/constants/message";
import {
  AppRoleModel,
  CreateUserManagementModel,
  UpdateUserManagementModel,
  UserManagementModel,
} from "states/user-management/user-management.models";

export interface IUserManagementProps {
  visible?: boolean;
  isUpdate?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onModifier?: () => void;
  model: UserManagementModel;
  roles: AppRoleModel[];
}

export function UserManagementFormModal(props: IUserManagementProps) {
  const { visible, isUpdate, model, onCancel, onClose, onModifier } = props;
  const [form] = useForm();
  const [addBtnLoading, setAddBtnLoading] = useState(false);

  const onFinish = async (values: any) => {
    const payload: CreateUserManagementModel = {
      ...values,
      userName: values.email,
      appRoles: props.roles.filter(
        (role) => values.appRoles?.indexOf(role.id) !== -1
      ),
    };
    try {
      setAddBtnLoading(true);
      if (isUpdate) {
        const payloadUpdated: UpdateUserManagementModel = {
          ...values,
          id: model.id,
          userName: values.email,
          appRoles: props.roles.filter(
            (role) => values.appRoles?.indexOf(role.id) !== -1
          ),
        };
        await updateUser(payloadUpdated);
        message.success("Cập nhật thành công");
      } else {
        await createUser(payload);
        message.success("Thêm thành công");
      }
      onModifier && onModifier();
      setAddBtnLoading(false);
      onClose && onClose();
    } catch (error) {
      setAddBtnLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const onSave = () => {
    form.submit();
  };

  useEffect(() => {
    form.resetFields(["firstName", "lastName", "email", "phoneNumber"]);
  }, [visible]);

  useEffect(() => {
    form.setFieldsValue({
      firstName: model?.firstName,
      lastName: model?.lastName,
      email: model?.email,
      phoneNumber: model?.phoneNumber,
      appRoles: model?.appRoles?.map((role) => role.id),
    });
  }, [model]);
  return (
    <div className="modal">
      <Modal
        title={`${isUpdate ? `Cập nhật` : `Thêm`} người dùng`}
        visible={visible}
        onOk={onSave}
        confirmLoading={addBtnLoading}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Hủy bỏ
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={addBtnLoading}
            onClick={onSave}
          >
            Lưu
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="lastName"
            label="Họ"
            rules={[{ required: true, message: "Vui lòng nhập họ" }]}
          >
            <Input
              type="text"
              placeholder="Họ"
              defaultValue={isUpdate ? model?.lastName : ""}
            ></Input>
          </Form.Item>
          <Form.Item
            name="firstName"
            label="Tên"
            rules={[{ required: true, message: "Vui lòng nhập tên" }]}
          >
            <Input
              type="text"
              placeholder="Tên"
              defaultValue={isUpdate ? model?.firstName : ""}
            ></Input>
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Vui lòng nhập email" }]}
          >
            <Input
              type="text"
              placeholder="Email"
              defaultValue={isUpdate ? model?.email : ""}
            ></Input>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Số điện thoại"
            rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
          >
            <Input
              type="text"
              placeholder="Số điện thoại"
              defaultValue={isUpdate ? model?.phoneNumber : ""}
            ></Input>
          </Form.Item>
          <Form.Item name="appRoles" label="Phân quyền" required={false}>
            <Select
              mode="multiple"
              className="w-100"
              placeholder="Phân quyền"
              defaultValue={model?.appRoles?.map((role) => role.id) || []}
              filterOption={(input, option) =>
                !!option?.label &&
                option?.label
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.roles.map((appRole) => (
                <Select.Option
                  key={appRole.id}
                  value={appRole.id}
                  label={appRole.name}
                >
                  {appRole.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
