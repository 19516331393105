export interface AppSettingState {
  language: LanguageType;
  currencyType: CurrencyType;
  logoUrl: string;
  activatedMenu: string;
}

export enum LanguageType {
  English,
  VietNamese,
}

export enum CurrencyType {
  USD,
  VND,
}

export const SET_DEFAULT_SETTING = "SET_DEFAULT_SETTING";
interface SetDefaultSetting {
  type: typeof SET_DEFAULT_SETTING;
}

export const SET_ACTIVE_SIDEBAR = "SET_ACTIVE_SIDEBAR";
interface SetActiveSidebar {
  type: typeof SET_ACTIVE_SIDEBAR;
  activeValue: string;
}

export type AppSettingActionType = SetDefaultSetting | SetActiveSidebar;
