import {
  PRODUCT_COMMENT_API,
  PRODUCT_LABEL_API,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  CreatedProductCommentModel,
  ProductCommentModel,
  ProductCommentStatusEnum,
} from "./product-comment.models";

export const getProductCommentList = async (productId: number) => {
  const createResponse = await getHttp<ProductCommentModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_COMMENT_API}?productId=${productId}`
  );
  return createResponse;
};

export const addProductComment = async (model: CreatedProductCommentModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_COMMENT_API}`,
    model
  );
  return response;
};

export const toggleProductCommentIsActive = async (commentId: number) => {
  return await putHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_COMMENT_API}/${commentId}/is_active`
  );
};

export const changeProductCommentStatus = async (
  commentId: number,
  status: ProductCommentStatusEnum
) => {
  return await putHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_COMMENT_API}/${commentId}/status`,
    status,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};
