import React, { FC, useState, useEffect } from "react";
import { Row, Col, Button, Input } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";
import "./styles/menu-page.less";
import {
  ISearchMenuSettingRequestModel,
  IMenuSettingModel,
} from "states/menu-setting/menu-setting.models";
import { searchMenuSetting } from "states/menu-setting/menu-setting.api";
import { MenuSettingItem } from "./components/menu-item";
import { AddMenuSettingModal } from "./components/menu-modal";
import { Sortable, SortItemModel } from "shared/components/sortable/sortable";

interface IMenuSettingProps {}

const MenuSetting: FC<IMenuSettingProps> = () => {
  const [isModal, setModal] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<IMenuSettingModel>
  );
  const [
    filterModel,
    setFilterModel,
  ] = useState<ISearchMenuSettingRequestModel>({
    page: 1,
    pageSize: 20,
  });

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  const handleToggleModal = () => {
    setModal(!isModal);
  };
  const gotoPage = async (page: number) => {
    const result = await searchMenuSetting({ ...filterModel, page });
    setPagedResults(result.data);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  return (
    <div className="menu-setting">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên menu cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleToggleModal}
            icon={<PlusCircleOutlined />}
          >
            Thêm Menu
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between">
        {pagedResults?.results?.map((model, index: number) => (
          <Col span={12} key={index}>
            <MenuSettingItem
              menuItem={model}
              onSuccess={handleSearch.callback}
            />
          </Col>
        ))}
      </Row>
      <AddMenuSettingModal
        onModal={isModal}
        closeModal={handleToggleModal}
        onSuccess={handleSearch.callback}
      />
    </div>
  );
};

export default MenuSetting;
