import {
  CheckOutlined,
  EnterOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Card,
  Col,
  List,
  Row,
  Comment,
  Space,
  Button,
  Tag,
  Modal,
  message,
} from "antd";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { redirectFileLocalUrl } from "shared/plugins/string-helper";
import { PagedResult } from "states/paged-result-model";
import {
  addProductComment,
  getProductCommentList,
} from "states/product-comment/product-comment.api";
import {
  CreatedProductCommentModel,
  ProductCommentModel,
  ProductCommentStatusEnum,
} from "states/product-comment/product-comment.models";
import { getProductHasNewComment } from "states/product/product.api";
import { ProductAndCommentModel } from "states/product/product.models";
import { ProductCommentItem } from "./components/product-comment-item";

export interface IProductCommentProps {}

export function ProductComment(props: IProductCommentProps) {
  const [products, setProducts] = useState({
    currentPage: 1,
    pageSize: 50,
  } as PagedResult<ProductAndCommentModel>);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>();
  const [
    replyCommentModel,
    setReplyCommentModel,
  ] = useState<CreatedProductCommentModel>({} as CreatedProductCommentModel);
  const [productComments, setProductComments] = useState<ProductCommentModel[]>(
    []
  );
  const [isReply, setIsReply] = useState(false);
  const [isAddingComment, setIsAddingComment] = useState(false);

  const gotoPage = async (page: number) => {
    setIsLoadingProducts(true);
    const res = await getProductHasNewComment(page);
    setProducts(res.data);
    setIsLoadingProducts(false);
  };

  const handleGetComment = async () => {
    if (selectedProduct) {
      setIsLoadingComments(true);
      const res = await getProductCommentList(selectedProduct);
      setProductComments(res.data);
      setIsLoadingComments(false);
    }
  };

  const onReplyBtnClicked = (commentId: number) => {
    setReplyCommentModel({
      productId: selectedProduct,
      parentId: commentId,
      message: "",
    } as CreatedProductCommentModel);
    setIsReply(true);
  };

  const handleCloseModal = () => {
    setIsReply(false);
  };

  const onSubmit = async () => {
    setIsAddingComment(true);
    try {
      await addProductComment(replyCommentModel);
      handleGetComment();
      message.success(UpdateDataSuccessfully);
      handleCloseModal();
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    setIsAddingComment(false);
  };

  const onUpdatedCommentItem = () => {
    handleGetComment();
  };

  useEffect(() => {
    gotoPage(1);
  }, []);

  useEffect(() => {
    handleGetComment();
  }, [selectedProduct]);

  return (
    <div className="product-comment">
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="Danh sách sản phẩm">
            <List
              loading={isLoadingProducts}
              size="small"
              dataSource={products.results}
              pagination={{
                total: products.totalItemCount,
                pageSize: products.pageSize,
                current: products.currentPage,
                onChange: gotoPage,
              }}
              renderItem={(product) => (
                <List.Item
                  key={product.id}
                  style={{
                    cursor: "pointer",
                    background:
                      product.id === selectedProduct ? "#d9d9d9" : "#fff",
                  }}
                  onClick={() => {
                    setSelectedProduct(product.id);
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Badge count={product.numberOfNewComments}>
                        <Avatar
                          className="bg-primary"
                          src={
                            product.images.length > 0
                              ? redirectFileLocalUrl(product.images[0])
                              : undefined
                          }
                        >
                          {product.websiteName && product.websiteName[0]}
                        </Avatar>
                      </Badge>
                    }
                    title={product.websiteName}
                  ></List.Item.Meta>
                </List.Item>
              )}
            ></List>
          </Card>
        </Col>
        <Col span={16}>
          <Card title="Danh sách bình luận / đánh giá">
            <List
              loading={isLoadingComments}
              size="small"
              dataSource={productComments}
              header={`${productComments.length} bình luận`}
              renderItem={(comment) => (
                <li>
                  <ProductCommentItem
                    model={comment}
                    onReplyBtnClicked={onReplyBtnClicked}
                    onUpdated={onUpdatedCommentItem}
                  ></ProductCommentItem>
                </li>
              )}
            ></List>
          </Card>
        </Col>
      </Row>
      <Modal
        width="800px"
        visible={isReply}
        title="Trả lời bình luận"
        onOk={onSubmit}
        onCancel={handleCloseModal}
        okText="Lưu"
        cancelText="Hủy bỏ"
        confirmLoading={isAddingComment}
      >
        <MceEditor
          pageContent={replyCommentModel.message}
          onChange={(e) => {
            setReplyCommentModel({ ...replyCommentModel, message: e });
          }}
        ></MceEditor>
      </Modal>
    </div>
  );
}
