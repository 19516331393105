export interface GlobalValueState {
  isLoading: boolean;
  toast: ToastModel;
  confirm: ConfirmModel;
}

export interface ToastModel {
  isShowToast: boolean;
  status: string;
  message: string;
  duration: number;
}

export interface ConfirmModel {
  isActive: boolean;
  message: string;
  yesBtnText: string;
  noBtnText: string;
  onYes: any;
  onClose: any;
  onNo: any;
}

export const GlobalValue_SetLoading = "GlobalValue_SetLoading";
interface GlobalValueSetLoading {
  type: typeof GlobalValue_SetLoading;
  payload: boolean;
}

export const GlobalValue_SetToast = "GlobalValue_SetToast";
interface GlobalValueSetToast {
  type: typeof GlobalValue_SetToast;
  payload: ToastModel;
}

export const GlobalValue_ShowSuccess = "GlobalValue_ShowSuccess";
interface GlobalValueShowSuccess {
  type: typeof GlobalValue_ShowSuccess;
  payload: string;
}

export const GlobalValue_ShowError = "GlobalValue_ShowError";
interface GlobalValueShowError {
  type: typeof GlobalValue_ShowError;
  payload: string;
}

export const GlobalValue_ShowWarning = "GlobalValue_ShowWarning";
interface GlobalValueShowWarning {
  type: typeof GlobalValue_ShowWarning;
  payload: string;
}

export const GlobalValue_ShowInfo = "GlobalValue_ShowInfo";
interface GlobalValueShowInfo {
  type: typeof GlobalValue_ShowInfo;
  payload: string;
}

export const GlobalValue_ShowConfirmModal = "GlobalValue_ShowConfirmModal";
interface GlobalValueShowConfirmModal {
  type: typeof GlobalValue_ShowConfirmModal;
  payload: ConfirmModel;
}

export const GlobalValue_CloseConfirmModal = "GlobalValue_CloseConfirmModal";
interface GlobalValueCloseConfirmModal {
  type: typeof GlobalValue_CloseConfirmModal;
}

export const GlobalValue_SetPageTitle = "GlobalValue_SetPageTitle";
interface GlobalValueSetPageTitle {
  type: typeof GlobalValue_SetPageTitle;
  payload: string;
}

export type GlobalValueActionType =
  | GlobalValueSetLoading
  | GlobalValueSetToast
  | GlobalValueShowSuccess
  | GlobalValueShowError
  | GlobalValueShowWarning
  | GlobalValueShowInfo
  | GlobalValueShowConfirmModal
  | GlobalValueCloseConfirmModal
  | GlobalValueSetPageTitle;
