import React, { useState } from "react";
import { Layout, Menu, Avatar, Dropdown, Button } from "antd";
import "./layout-app.less";
import { useDispatch } from "react-redux";
import { removeRefreshToken, removeToken } from "shared/plugins/http-service";
import { ChangePasswordModal } from "modules/account/components/change-password-modal";
import { setLogoutUser } from "states/account/account.action";

const { Header } = Layout;

const HeaderApp = () => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const onToggleModal = () => {
    setVisibleModal(false);
  };
  const accountMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Button
            type="text"
            block={true}
            onClick={() => setVisibleModal(true)}
          >
            Change password
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            type="text"
            block={true}
            onClick={() => {
              dispatch(setLogoutUser());
              removeToken();
              removeRefreshToken();
            }}
          >
            Log out
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Header className="main-header">
      <h1 className="title"></h1>
      <div className="tools">
        <Dropdown
          overlay={accountMenu}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Avatar className="avatar">N</Avatar>
        </Dropdown>
        <ChangePasswordModal
          visible={visibleModal}
          onCancel={() => onToggleModal()}
        />
      </div>
    </Header>
  );
};

export default HeaderApp;
