import React, { FC, useState, useEffect } from "react";
import {
  Input,
  Modal,
  Form,
  Upload,
  Button,
  Select,
  Space,
  Table,
  Typography,
  Col,
  Row,
  Drawer,
  notification,
} from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  CustomerGroupModel,
  CreateCustomerGroupModel,
  UpdatedCustomerGroupModel,
} from "states/customer-group/customer-group.models";
import { cleanUnicode } from "shared/plugins/string-helper";
import {
  addCustomerGroup,
  updateCustomerGroup,
} from "states/customer-group/customer-group.api";
import { searchCustomer } from "states/customer/customer.api";
import {
  CustomerModel,
  RemovedCustomerModel,
  SearchCustomerRequestModel,
} from "states/customer/customer.models";
import { useDebouncedCallback } from "use-debounce/lib";
import { PagedResult } from "states/paged-result-model";
import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
interface ICustomerGroupModalProps {
  model: CustomerGroupModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const CustomerGroupModalComponent: FC<ICustomerGroupModalProps> = (
  props
) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [customerGroupModel, setCustomerGroupModel] =
    useState<CustomerGroupModel>({} as CustomerGroupModel);
  const [isLoading, setLoading] = useState(false);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<any[]>([]);
  const onEmptySelection = () => {
    setSelectedRowsKeys([]);
  };
  const [filterModel, setFilterModel] = useState<SearchCustomerRequestModel>({
    page: 1,
    pageSize: 20,
  } as SearchCustomerRequestModel);
  const [customers, setCustomer] = useState<PagedResult<CustomerModel>>(
    {} as PagedResult<CustomerModel>
  );
  const rowSelection = {
    selectedRowsKeys: selectedRowsKeys,
    hideDefaultSelections: true,
    selections: false,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys(selectedRowKeys);
    },
  };
  const handleCloseModal = () => {
    closeModal();
    onEmptySelection();
  };
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedCustomerGroupModel = {
          ...values,
          id: values.id,
          name: values.name,
          customers: customerGroupModel.customers.map((m) => m.id),
        };
        await updateCustomerGroup(payload);
      } else {
        let payload: CreateCustomerGroupModel = {
          ...values,
          name: values.name,
          customers: customerGroupModel.customers.map((m) => m.id),
        };
        await addCustomerGroup(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };
  const onNameChange = (ev: any) => {
    if (!isUpdate) {
      form.setFieldsValue({
        metaTitle: ev.target.value,
        metaDescription: ev.target.value,
        metaKeyword: ev.target.value,
        slug: cleanUnicode(ev.target.value),
      });
    }
    form.setFieldsValue({ name: ev.target.value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      name: model?.name,
    });
    model.customers.forEach((m) => {
      m.key = m.id.toString();
    });
    setCustomerGroupModel({
      id: model?.id,
      name: model?.name,
      customers: model?.customers,
    } as CustomerGroupModel);
    onEmptySelection();
  };

  useEffect(() => {
    onReset();
    onFill();
  }, [onModal]);
  const gotoPage = async (page: number) => {
    const result = await searchCustomer({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    result.data.results = result.data.results.filter((m) => m.name !== null);
    setCustomer(result.data);
  };
  const columns: ColumnsType<CustomerModel> = [
    {
      title: "Mã KH",
      dataIndex: "id",
      key: "id",
      render: (id: number, item) => <Typography.Text>{id}</Typography.Text>,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber: string, item) => (
        <Typography.Text>{phoneNumber}</Typography.Text>
      ),
    },
  ];
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={() => {
          let customerTemps = [...customerGroupModel.customers];
          let customerResult = _.filter(customerTemps, (m) => {
            return !selectedRowsKeys.includes(m.id.toString());
          });
          setCustomerGroupModel({
            ...customerGroupModel,
            customers: customerResult,
          });
          onEmptySelection();
          notification.close(key);
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật nhóm khách hàng` : `Thêm nhóm khách hàng`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="CustomerGroupModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Tên nhóm khách hàng"
          name="name"
          rules={[
            { required: true, message: "Vui lòng nhập tên nhóm khách hàng!" },
          ]}
        >
          <Input
            onChange={onNameChange}
            className="w-100"
            placeholder="Tên nhóm khách hàng"
          />
        </Form.Item>
        <Form.Item label="Thêm khách hàng" name="customers">
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={24}>
              <Select
                className="w-100"
                placeholder="Chọn khách hàng"
                allowClear
                showSearch
                filterOption={false}
                value={filterModel?.name}
                onSearch={(value) => {
                  setFilterModel({ ...filterModel, keyword: value });
                }}
                onSelect={(value: string | number) => {
                  let findCustomer = _.find(
                    customers?.results,
                    (m) => m.id === value
                  );
                  let oldCustomer = _.filter(
                    customerGroupModel.customers,
                    (m) => m.id === value
                  );
                  if (oldCustomer.length > 0) {
                    return false;
                  }
                  let customerTemps = [...customerGroupModel.customers];
                  customerTemps.push(findCustomer as CustomerModel);
                  setCustomerGroupModel({
                    ...customerGroupModel,
                    customers: customerTemps,
                  });
                }}
              >
                {customers &&
                  customers?.results?.map((customer) => (
                    <Select.Option key={customer.id} value={customer.id}>
                      {customer?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Danh sách khách hàng" name="customers">
          <Table
            rowSelection={{ ...rowSelection }}
            columns={columns}
            dataSource={customerGroupModel.customers}
            size="small"
            pagination={false}
          ></Table>
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={selectedRowsKeys?.length > 0 ? true : false}
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.length} dòng đang chọn
        </Button>
      </Drawer>
    </Modal>
  );
};
