import {
  STATIC_PAGE_API,
  STATIC_PAGE_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateStaticPageModel,
  RemovedStaticPageModel,
  SearchStaticPageRequestModel,
  StaticPageModel,
  UpdatedStaticPageModel,
} from "./static-page.models";

export const searchStaticPage = async (
  searchStaticPageRequestModel: SearchStaticPageRequestModel
) => {
  const createResponse = await postHttp<PagedResult<StaticPageModel>>(
    `${process.env.REACT_APP_API_URI}${STATIC_PAGE_API_SEARCH}`,
    searchStaticPageRequestModel
  );
  return createResponse;
};

export const removeStaticPage = async (
  removedModel: RemovedStaticPageModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${STATIC_PAGE_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getStaticPageById = async (id: number) => {
  return await getHttp<StaticPageModel>(
    `${process.env.REACT_APP_API_URI}${STATIC_PAGE_API}/${id}`
  );
};

export const addStaticPage = async (model: CreateStaticPageModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${STATIC_PAGE_API}`,
    model
  );
  return response;
};

export const updateStaticPage = async (model: UpdatedStaticPageModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${STATIC_PAGE_API}`,
    model
  );
  return response;
};
