import React, { FC, useState, useEffect } from "react";
import { globalValueSetPageTitle } from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
} from "antd";

import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { UserManagementFormModal } from "./components/user-management-form-modal";
import {
  removeUser,
  getRoles,
  searchUser,
  getUserById,
} from "states/user-management/user-management.api";

import { useDebouncedCallback } from "use-debounce/lib";
import { DefaultErrorMessage } from "shared/constants/message";
import {
  AppRoleModel,
  RemovedUserManagementModel,
  UserManagementModel,
} from "states/user-management/user-management.models";

interface IUserManagementProps {}

const UserManagementPage: FC<IUserManagementProps> = () => {
  const [isModal, setIsModal] = useState(false);
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedUserManagementModel>({
    idList: [],
  });
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [userPagedResults, setUserPagedResults] = useState(
    {} as PagedResult<UserManagementModel>
  );
  const [model, setModel] = useState<UserManagementModel>(
    {} as UserManagementModel
  );
  const [filterModel, setFilterModel] = useState({
    name: "",
  });
  const [roles, setRoles] = useState<AppRoleModel[]>([]);

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalValueSetPageTitle("Danh sách người dùng"));
    getRoles().then((roles) => {
      setRoles(roles.data);
    });
  }, []);

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);

  const onUpdateUserBtn = (id: string) => {
    getUserById(id)
      .then((response) => {
        setIsModal(true);
        setIsUpdateUser(true);
        setModel(response?.data);
      })
      .catch((error) => {
        message.error(error?.response?.message ?? DefaultErrorMessage);
      });
  };
  const onAddDoctorBtn = () => {
    setIsModal(true);
    setIsUpdateUser(false);
  };
  const onCancelModal = () => {
    setIsModal(false);
  };
  const onCloseModal = () => {
    setIsModal(false);
  };
  const onModifier = () => handleSearch.callback();

  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchUser(filterModel.name, page);
    result.data.results.forEach((m) => (m.key = m.id));
    setUserPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={() => {
          removeUser(selectedRowsKeys)
            .then(() => {
              notification.close(key);
              setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
              handleSearch.callback();
              message.success("Xóa thành công");
            })
            .catch(() => {
              message.error("Xóa thất bại");
            });
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: "Bạn có chắc chắn muốn xóa không ?",
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<UserManagementModel> = [
    {
      title: "Họ và tên",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName: string, item: UserManagementModel) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              onUpdateUserBtn(item.id);
            }}
          >
            {item.lastName} {item.firstName}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Email / Username",
      dataIndex: "email",
      key: "email",
      render: (email: string) => <React.Fragment>{email}</React.Fragment>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber: string) => (
        <React.Fragment>{phoneNumber}</React.Fragment>
      ),
    },
  ];
  return (
    <div className="project-page">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên người dùng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={onAddDoctorBtn}
            icon={<PlusCircleOutlined />}
          >
            Thêm người dùng
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={userPagedResults.results}
        size="small"
        pagination={{
          total: userPagedResults.totalItemCount,
          pageSize: userPagedResults.pageSize,
          current: userPagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <UserManagementFormModal
        visible={isModal}
        isUpdate={isUpdateUser}
        onCancel={onCancelModal}
        onClose={onCloseModal}
        onModifier={onModifier}
        model={model}
        roles={roles}
      ></UserManagementFormModal>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default UserManagementPage;
