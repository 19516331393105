import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
interface ICustomerDetailMenuProps {
  customerName: string;
  className?: string;
}
export function CustomerDetailBreadcrumb(props: ICustomerDetailMenuProps) {
  const { customerName, className } = props;
  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item>
        <Link to="/customers">Khách hàng</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{customerName}</Breadcrumb.Item>
    </Breadcrumb>
  );
}
