import { PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { RcFile } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { FILE_API, UPLOAD_FILE_API } from "shared/constants/api-endpoint";
import { IMAGES_FOLDER_ID } from "shared/constants/google-drive";
import { postHttp } from "shared/plugins/http-service";
import { redirectFileLocalUrl, redirectFileUrl } from "shared/plugins/string-helper";

interface UploadFileProps {
  isUploadImage?: boolean;
  maxFileSize?: number; //MB
  album: string[];
  multiple?: boolean;
  maxFileCount?: number;
  folderId?: string;
  onChange: (newFileList: string[]) => void;
}
export function UploadFile(props: UploadFileProps) {
  const {
    album,
    isUploadImage,
    maxFileSize,
    multiple,
    maxFileCount,
    folderId,
    onChange,
  } = props;

  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = (file: RcFile) => {
    if (isUploadImage) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
    }
    if (maxFileSize) {
      const isLt2M = file.size / 1024 / 1024 < maxFileSize;
      if (!isLt2M) {
        message.error("Image size must be less than 2MB");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setFileList(
      album.map((m, index) => ({
        uid: index,
        name: m,
        status: "done",
        url: redirectFileUrl(m),
        response: redirectFileUrl(m),
      }))
    );
  }, [album]);

  return (
    <Upload
      name="uploadFile"
      customRequest={({ onSuccess, onError, file }) => {
        const formData = new FormData();
        formData.append("uploadedFiles", file);

        formData.append("folderId", folderId ?? IMAGES_FOLDER_ID);

        postHttp(`${process.env.REACT_APP_API_URI}${FILE_API}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }).then((result) => {
          onSuccess(result.data[0], file);
        });
      }}
      beforeUpload={beforeUpload}
      multiple={multiple}
      listType="picture-card"
      onRemove={(file) => {
        onChange(
          fileList
            .filter((m) => m.response !== file.response)
            .map((m) => m.response)
        );
      }}
      fileList={fileList}
      onChange={({ fileList }) => {
        setFileList(fileList.filter((m) => !!m.status));
        if (
          fileList.length === fileList.filter((m) => m.status === "done").length
        ) {
          onChange(fileList.map((m) => m.response));
        }
      }}
    >
      {(fileList.length === 0 ||
        !maxFileCount ||
        (maxFileCount && fileList.length < maxFileCount)) && (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      )}
    </Upload>
  );
}

export function UploadFileLocal(props: UploadFileProps) {
  const {
    album,
    isUploadImage,
    maxFileSize,
    multiple,
    maxFileCount,
    folderId,
    onChange,
  } = props;

  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = (file: RcFile) => {
    if (isUploadImage) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
    }
    if (maxFileSize) {
      const isLt2M = file.size / 1024 / 1024 < maxFileSize;
      if (!isLt2M) {
        message.error("Image size must be less than 2MB");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setFileList(
      album.map((m, index) => ({
        uid: index,
        name: m,
        status: "done",
        url: redirectFileLocalUrl(m),
        response: m,
      }))
    );
  }, [album]);

  return (
    <Upload
      name="uploadFile"
      customRequest={({ onSuccess, onError, file }) => {
        const formData = new FormData();
        formData.append("uploadedFiles", file);
        formData.append("folderId", folderId ?? IMAGES_FOLDER_ID);
        postHttp(`${process.env.REACT_APP_API_URI}${UPLOAD_FILE_API}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }).then((result) => {
          onSuccess(result.data[0], file);
        });
      }}
      beforeUpload={beforeUpload}
      multiple={multiple}
      listType="picture-card"
      onRemove={(file) => {
        onChange(
          fileList
            .filter((m) => m.response !== file.response)
            .map((m) => m.response)
        );
      }}
      fileList={fileList}
      onChange={({ fileList }) => {
        setFileList(fileList.filter((m) => !!m.status));
        if (
          fileList.length === fileList.filter((m) => m.status === "done").length
        ) {
          onChange(fileList.map((m) => m.response));
        }
      }}
    >
      {(fileList.length === 0 ||
        !maxFileCount ||
        (maxFileCount && fileList.length < maxFileCount)) && (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      )}
    </Upload>
  );
}

