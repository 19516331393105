import React, { FC, useState, useEffect } from "react";
import { Input, Modal, Form, Upload, Button, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  BlogCategoryModel,
  CreateBlogCategoryModel,
  UpdatedBlogCategoryModel,
} from "states/blog-category/blog-category.models";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import { cleanUnicode } from "shared/plugins/string-helper";
import {
  addBlogCategory,
  updateBlogCategory,
} from "states/blog-category/blog-category.api";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";
import { ThemeSettingModel } from "states/theme-setting/theme-setting.models";
import { searchThemeSetting } from "states/theme-setting/theme-setting.api";

interface IBlogCategoryModalProps {
  model: BlogCategoryModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const BlogCategoryModalComponent: FC<IBlogCategoryModalProps> = (
  props
) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [themeDropdown, setThemeDropdown] = useState<ThemeSettingModel[]>([]);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedBlogCategoryModel = {
          ...values,
          id: values.id,
          name: values.name,
          slug: values.slug,
          avatar: avatar,
          description: values.description,
          metaTitle: values.metaTitle,
          metaDescription: values.metaDescription,
          metaKeyword: values.metaKeyword,
          themeId: values.themeId,
        };
        await updateBlogCategory(payload);
      } else {
        let payload: CreateBlogCategoryModel = {
          ...values,
          name: values.name,
          slug: values.slug,
          avatar: avatar,
          description: values.description,
          metaTitle: values.metaTitle,
          metaDescription: values.metaDescription,
          metaKeyword: values.metaKeyword,
          themeId: values.themeId,
        };
        await addBlogCategory(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };
  const onEditorChange = (value: string) => {
    form.setFieldsValue({ description: value });
  };
  const onNameChange = (ev: any) => {
    if (!isUpdate) {
      form.setFieldsValue({
        metaTitle: ev.target.value,
        metaDescription: ev.target.value,
        metaKeyword: ev.target.value,
        slug: cleanUnicode(ev.target.value),
      });
    }
    form.setFieldsValue({ name: ev.target.value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    setDescription(model?.description);
    form.setFieldsValue({
      id: model?.id,
      name: model?.name,
      slug: model?.slug,
      avatar: model?.avatar,
      description: model?.description,
      metaTitle: model?.metaTitle,
      metaDescription: model?.metaDescription,
      metaKeyword: model?.metaKeyword,
      themeId: model?.themeId,
    });
    setAvatar(model?.avatar);
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);
  useEffect(() => {
    searchThemeSetting({ page: 1, pageSize: 10000 }).then((res) => {
      setThemeDropdown(res.data.results);
    });
  }, []);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          {isUpdate ? `Cập nhật danh mục tin tức` : `Thêm danh mục tin tức`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="blogCategoryModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Tên danh mục"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập tên danh mục!" }]}
        >
          <Input
            onChange={onNameChange}
            className="w-100"
            placeholder="Tên danh mục"
          />
        </Form.Item>
        <Form.Item name="avatar" label="Hình ảnh" valuePropName="fileList">
          <UploadFileLocal
            album={[avatar]}
            maxFileCount={2}
            multiple={false}
            onChange={(newFileList: string[]) => {
              setAvatar(newFileList[1]);
            }}
          />
        </Form.Item>
        <Form.Item label="Mô tả" name="description">
          <MceEditor
            height={200}
            pageContent={description}
            onChange={onEditorChange}
          />
        </Form.Item>
        <Form.Item label="Meta title" name="metaTitle">
          <Input className="w-100" placeholder="Meta title" />
        </Form.Item>
        <Form.Item label="Meta description" name="metaDescription">
          <Input className="w-100" placeholder="Meta description" />
        </Form.Item>
        <Form.Item label="Meta keyword" name="metaKeyword">
          <Input className="w-100" placeholder="Meta keyword" />
        </Form.Item>
        <Form.Item
          label="Đường dẫn"
          name="slug"
          rules={[{ required: true, message: "Vui lòng nhập đường dẫn!" }]}
        >
          <Input hidden readOnly className="w-100" placeholder="Đường dẫn" />
          <strong>
            {model.id
              ? `/danh-muc-tin-tuc/${model.slug}-${model.id}.html`
              : "Url chỉ hiển thị khi đã thêm danh mục"}
          </strong>
        </Form.Item>
        <Form.Item label="Theme" name="themeId">
          <Select
            className="w-100"
            placeholder="Chọn theme"
            allowClear
            value={model?.themeId}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {themeDropdown &&
              themeDropdown.map((theme) => (
                <Select.Option key={theme.id} value={theme.id}>
                  {theme.key}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
