import {
  RollbackOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Select,
  Tabs,
  Typography,
  Upload,
  Table,
  Popconfirm,
  Tag,
  DatePicker,
} from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { globalValueSetLoading } from "states/global-value/global-value.action";
import {
  getAllProductDropdown,
  getProductById,
  updateProduct,
} from "states/product/product.api";
import {
  CustomizeRelatedProductItem,
  ProductModel,
  SearchProductModel,
  SkuModel,
  UpdatedProductModel,
} from "states/product/product.models";
import { ProductCategoryModel } from "states/product-category/product-category.models";
import { searchProductCategory } from "states/product-category/product-category.api";
import { cleanUnicode, formatMoney } from "shared/plugins/string-helper";
import { UploadFileLocal } from "shared/components/upload-file/upload-file";
import { ColumnsType } from "antd/lib/table";
import { ProductLabelModel } from "states/product-label/product-label.models";
import { getProductLabelList } from "states/product-label/product-label.api";
import moment from "moment";
import { TagModel } from "states/tag/tag.models";
import { getTagList } from "states/tag/tag.api";
import { ProductAttributeTab } from "./components/product-attribute-tab";

interface RouteParams {
  productId: string;
}
interface IAddUpdateProductProps {}
const { Option } = Select;
export function AddUpdateProduct(props: IAddUpdateProductProps) {
  const history = useHistory();
  const { productId } = useParams<RouteParams>();
  const [model, setModel] = useState<ProductModel>({
    customizeRelatedProducts: [] as CustomizeRelatedProductItem[],
  } as ProductModel);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [productCategories, setProductCategories] = useState<
    ProductCategoryModel[]
  >([]);
  const [productLabelDropDown, setProductLabelDropDown] = useState<
    ProductLabelModel[]
  >([]);
  const [productDropdown, setProductDropdown] = useState<SearchProductModel[]>(
    []
  );
  const [tagDropDown, setTagDropDown] = useState<TagModel[]>([]);

  const dispatch = useDispatch();

  const getProduct = async () => {
    try {
      const res = await getProductById(Number(productId));
      setModel({
        ...res.data,
        customizeRelatedProducts: res.data.customizeRelatedProducts.sort(
          (a, b) => {
            if (!a.sort || !b.sort) {
              return 0;
            }
            return a.sort - b.sort;
          }
        ),
      });
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const getProductCategories = async () => {
    try {
      const res = await searchProductCategory({
        name: "",
        page: 1,
        pageSize: 1000,
      });
      setProductCategories(res.data.results);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const onSaved = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      if (isUpdateMode) {
        const payload = {
          id: model.id,
          metaTitle: model.metaTitle,
          images: model.images,
          slug: model.slug,
          description: model.description,
          mainCategoryId: model.mainCategoryId,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          relatedCategoryIds: model.relatedCategoryIds,
          shortDescription: model.shortDescription,
          websiteName: model.websiteName,
          skus: model.skus.map((m) => ({
            id: m.id,
            images: m.images,
            promotionPrice: m.promotionPrice,
            promotionFromDate: m.promotionFromDate,
            promotionToDate: m.promotionToDate,
          })),
          customizeRelatedProducts: model.customizeRelatedProducts,
          productLabels: model.productLabels.map((m) => m.id),
          tags: model.tags.map((m) => m.id),
          attributeGroups: model.attributeGroups,
        } as UpdatedProductModel;
        await updateProduct(payload);
        message.success(UpdateDataSuccessfully);
      }
      history.go(0);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };

  const getAllProducts = async () => {
    const res = await getAllProductDropdown();
    setProductDropdown(res.data);
  };

  const skuColumns: ColumnsType<SkuModel> = [
    {
      title: "Tên",
      dataIndex: "kiotVietFullName",
      key: "kiotVietFullName",
      render: (kiotVietFullName: string, item) => (
        <>
          {item.isMainSku === true && (
            <>
              <Tag color="red">Mặc định</Tag>
              <br />
            </>
          )}
          <Typography.Text>
            {kiotVietFullName} (tồn{" "}
            {item.inventories && item.inventories[0].onHand})
          </Typography.Text>{" "}
          <br />
          <Typography.Text>
            Giá kiotviet: {formatMoney(item.kiotVietPrice)}
          </Typography.Text>
        </>
      ),
    },
    {
      title: "Giá khuyến mãi",
      dataIndex: "promotionPrice",
      key: "promotionPrice",
      render: (promotionPrice: any, item) => (
        <>
          <Input.Group className="mb-3">
            <Typography.Text>Giá khuyến mãi: </Typography.Text>
            <Input
              type="number"
              value={item.promotionPrice}
              onChange={(e) => {
                let currentItem = model.skus.find((m) => m.id === item.id);
                if (currentItem) {
                  currentItem.promotionPrice = e.target.valueAsNumber;
                  setModel({ ...model });
                }
              }}
              placeholder="Giá khuyến mãi"
            ></Input>
          </Input.Group>
          <Input.Group className="mb-3">
            <Typography.Text>Bắt đầu: </Typography.Text>
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              className="w-100"
              placeholder="Chọn ngày bắt đầu"
              value={
                item.promotionFromDate
                  ? moment(item.promotionFromDate)
                  : undefined
              }
              onChange={(date) => {
                let currentItem = model.skus.find((m) => m.id === item.id);
                if (currentItem) {
                  currentItem.promotionFromDate = date?.toDate();
                  setModel({ ...model });
                }
              }}
            />
          </Input.Group>
          <Input.Group>
            <Typography.Text>Kết thúc: </Typography.Text>
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              className="w-100"
              placeholder="Chọn ngày kết thúc"
              value={
                item.promotionToDate ? moment(item.promotionToDate) : undefined
              }
              onChange={(date) => {
                let currentItem = model.skus.find((m) => m.id === item.id);
                if (currentItem) {
                  currentItem.promotionToDate = date?.toDate();
                  setModel({ ...model });
                }
              }}
            />
          </Input.Group>
        </>
      ),
    },
    {
      title: "Hình ảnh",
      dataIndex: "images",
      key: "images",
      render: (images: string[], item) => (
        <UploadFileLocal
          album={images}
          onChange={(newFileList) => {
            let currentItem = model.skus.find((m) => m.id === item.id);
            if (currentItem) {
              currentItem.images = newFileList;
              setModel({ ...model });
            }
          }}
          multiple={true}
          maxFileCount={10}
        ></UploadFileLocal>
      ),
    },
  ];
  const customRelatedProductColumns: ColumnsType<CustomizeRelatedProductItem> = [
    {
      title: "Sắp xếp",
      dataIndex: "sort",
      key: "sort",
      render: (sort: number, item, index) => (
        <Input
          placeholder="Nhập số thứ tự"
          type="number"
          value={sort}
          onChange={(e) => {
            model.customizeRelatedProducts[index].sort = e.target.valueAsNumber;
            setModel({ ...model });
          }}
        ></Input>
      ),
    },
    {
      title: "Sản phẩm",
      dataIndex: "productId",
      key: "productId",
      render: (productId: number, item, index) => (
        <Select
          className="w-100"
          placeholder="Chọn sản phẩm"
          onChange={(value) => {
            model.customizeRelatedProducts[index].productId = value;
            setModel({ ...model });
          }}
          allowClear
          value={productId}
          showSearch
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {productDropdown &&
            productDropdown.map((pro) => (
              <Option key={pro.id} value={pro.id}>
                {pro.websiteName}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string, item, index) => (
        <Input
          placeholder="Nhập tiêu đề"
          value={title}
          onChange={(e) => {
            model.customizeRelatedProducts[index].title = e.target.value;
            setModel({ ...model });
          }}
        ></Input>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (description: string, item, index) => (
        <Input.TextArea
          rows={3}
          placeholder="Nhập mô tả"
          value={description}
          onChange={(e) => {
            model.customizeRelatedProducts[index].description = e.target.value;
            setModel({ ...model });
          }}
        ></Input.TextArea>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (action: any, item, index) => (
        <Popconfirm
          title={QuestionRemove}
          onConfirm={() => {
            model.customizeRelatedProducts.splice(index, 1);
            setModel({
              ...model,
              customizeRelatedProducts: [...model.customizeRelatedProducts],
            });
          }}
          okText="Có"
          cancelText="Không"
        >
          <Button danger>Xóa</Button>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (document.location.href.indexOf("/add") > -1) {
      setIsUpdateMode(false);
      document.title = "Thêm sản phẩm";
    } else {
      setIsUpdateMode(true);
      getProduct();
      document.title = "Cập nhật sản phẩm";
    }
    getProductCategories();
    getAllProducts();
    getProductLabelList().then((res) => {
      setProductLabelDropDown(res.data);
    });
    getTagList().then((res) => {
      setTagDropDown(res.data);
    });
  }, []);

  return (
    <div className="add-update-product">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/product">Sản phẩm</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Sản phẩm</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Link to="/product" className="mr-2">
            <Button type="default" icon={<RollbackOutlined />}>
              Trở lại
            </Button>
          </Link>
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Tabs type="card">
        <Tabs.TabPane tab={<span>Thông tin cơ bản</span>} key="1">
          <Row gutter={[16, 16]}>
            <Col span="16">
              <Card title="Thông tin">
                <Input.Group className="mb-3">
                  <Typography.Text>Tên: </Typography.Text>
                  <Input
                    value={model.websiteName}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        websiteName: e.target.value,
                        metaTitle: e.target.value,
                        slug: cleanUnicode(e.target.value),
                      });
                    }}
                    placeholder="Tên sản phẩm"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Mô tả ngắn: </Typography.Text>
                  <MceEditor
                    pageContent={model.shortDescription}
                    onChange={(e) => {
                      setModel({ ...model, shortDescription: e });
                    }}
                  ></MceEditor>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Mô tả: </Typography.Text>
                  <MceEditor
                    pageContent={model.description}
                    onChange={(e) => {
                      setModel({ ...model, description: e });
                    }}
                  ></MceEditor>
                </Input.Group>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Hình ảnh" className="mb-3">
                <UploadFileLocal
                  album={model.images ?? []}
                  maxFileCount={1}
                  multiple={false}
                  onChange={(newFileList: string[]) => {
                    setModel({ ...model, images: newFileList });
                  }}
                />
              </Card>
              <Card title="Danh mục" className="mb-3">
                <Input.Group className="mb-3">
                  <Typography.Text>Danh mục chính</Typography.Text>
                  <Select
                    className="w-100"
                    placeholder="Chọn danh mục chính"
                    onChange={(item) => {
                      setModel({ ...model, mainCategoryId: item });
                    }}
                    allowClear
                    value={model?.mainCategoryId}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productCategories &&
                      productCategories.map((productCate) => (
                        <Option key={productCate.id} value={productCate.id}>
                          {productCate.name}
                        </Option>
                      ))}
                  </Select>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Danh mục phụ</Typography.Text>
                  <Select
                    className="w-100"
                    placeholder="Chọn danh mục phụ"
                    onChange={(item) => {
                      setModel({ ...model, relatedCategoryIds: item });
                    }}
                    allowClear
                    value={model?.relatedCategoryIds ?? []}
                    showSearch
                    mode="multiple"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productCategories &&
                      productCategories.map((productCate) => (
                        <Option key={productCate.id} value={productCate.id}>
                          {productCate.name}
                        </Option>
                      ))}
                  </Select>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Nhãn sản phẩm</Typography.Text>
                  <Select
                    className="w-100"
                    placeholder="Chọn nhãn sản phẩm"
                    onChange={(item) => {
                      setModel({
                        ...model,
                        productLabels: item.map((m) => ({
                          id: m,
                          key: m.toString(),
                          labelName: "",
                        })),
                      });
                    }}
                    allowClear
                    value={model?.productLabels?.map((m) => m.id) ?? []}
                    showSearch
                    mode="multiple"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {productLabelDropDown &&
                      productLabelDropDown.map((productLabel) => (
                        <Option key={productLabel.id} value={productLabel.id}>
                          {productLabel.labelName}
                        </Option>
                      ))}
                  </Select>
                </Input.Group>
                <Input.Group>
                  <Typography.Text>Tag sản phẩm</Typography.Text>
                  <Select
                    className="w-100"
                    placeholder="Chọn tag sản phẩm"
                    onChange={(item) => {
                      setModel({
                        ...model,
                        tags: item.map((m) => ({
                          id: m,
                          key: m.toString(),
                          tagName: "",
                        })),
                      });
                    }}
                    allowClear
                    value={model?.tags?.map((m) => m.id) ?? []}
                    showSearch
                    mode="multiple"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {tagDropDown &&
                      tagDropDown.map((tag) => (
                        <Option key={tag.id} value={tag.id}>
                          {tag.tagName}
                        </Option>
                      ))}
                  </Select>
                </Input.Group>
              </Card>
              <Card title="SEO">
                <Input.Group className="mb-3">
                  <Typography.Text>Tiêu đề: </Typography.Text>
                  <Input
                    value={model.metaTitle}
                    onChange={(e) => {
                      setModel({ ...model, metaTitle: e.target.value });
                    }}
                    placeholder="Tiêu đề"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Mô tả: </Typography.Text>
                  <Input.TextArea
                    value={model.metaDescription}
                    onChange={(e) => {
                      setModel({ ...model, metaDescription: e.target.value });
                    }}
                    rows={5}
                    placeholder="Mô tả"
                  ></Input.TextArea>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Từ khóa: </Typography.Text>
                  <Input
                    value={model.metaKeyword}
                    onChange={(e) => {
                      setModel({ ...model, metaKeyword: e.target.value });
                    }}
                    placeholder="Từ khóa"
                  ></Input>
                </Input.Group>
                <Input.Group className="mb-3">
                  <Typography.Text>Slug: </Typography.Text>
                  <Input
                    readOnly={true}
                    value={
                      model.mainCategoryUrl
                        ? `/${model.mainCategoryUrl}/${model.slug}-${model.id}.html`
                        : "Chọn danh mục để hiện url"
                    }
                    placeholder="Slug"
                  ></Input>
                </Input.Group>
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Sku sản phẩm</span>} key="2">
          <Table dataSource={model.skus} columns={skuColumns}></Table>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Sản phẩm cùng liệu trình</span>} key="3">
          <Button
            type="primary"
            onClick={() => {
              setModel({
                ...model,
                customizeRelatedProducts: [
                  ...model.customizeRelatedProducts,
                  { sort: model.customizeRelatedProducts?.length ?? 0 },
                ],
              });
            }}
            className="mb-3"
          >
            Thêm sản phẩm
          </Button>
          <Table
            columns={customRelatedProductColumns}
            dataSource={model.customizeRelatedProducts}
          ></Table>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span>Thuộc tính sản phẩm</span>} key="4">
          <ProductAttributeTab
            attributeGroups={model.attributeGroups}
            onUpdated={(attributeGroups) => {
              setModel({ ...model, attributeGroups });
            }}
          ></ProductAttributeTab>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
