import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
  message,
} from "antd";
import _ from "lodash";
import {  DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedTreatmentModel,
  SearchTreatmentRequestModel,
  TreatmentModel,
  TreatmentStatus,
  UpdatedTreatmentStatusModel,
} from "states/treatment/treatment.models";
import {
  removeTreatment,
  searchTreatment,
  updateTreatmentStatus,
} from "states/treatment/treatment.api";
import { TreatmentModalComponent } from "./components/treatment-modal";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { Link, useParams } from "react-router-dom";
import { CustomerDetailBreadcrumb } from "modules/customer/components/customer-detail-breadcrumb";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
const { Option } = Select;
interface ParamsRoute{
  customerId: string;
}
interface ITreatmentProps {

};
const Treatment: FC<ITreatmentProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedTreatmentModel>({
    idList: [],
  });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<TreatmentModel>
  );
  const [isModal,setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] =  useState<TreatmentModel>({} as TreatmentModel);
  const params = useParams<ParamsRoute>();
  const [filterModel, setFilterModel] = useState<SearchTreatmentRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách liệu trình";
    console.log(Number(params.customerId));
    getCustomerById(Number(params.customerId)).then(
      (customerResponse) => {
        setCustomerModel(customerResponse.data);
      }
    );
  }, []);
  const handleCloseModal = () =>{
    setModal(false);
  }
  const handleAddTreatment = () => {
    setModel({} as TreatmentModel);
    setModal(true);
    setUpdate(false);
  }
  const gotoPage = async (page: number) => {
    try{
      setIsLoadingList(true);
      if(!_.isEmpty(params)){
        filterModel.customerId = params.customerId;
      }
      const result = await searchTreatment({ ...filterModel, page });
      result.data.results.forEach((m) => (m.key = m.id.toString()));
      setPagedResults(result.data);
      setIsLoadingList(false);
    }catch(error){
      setIsLoadingList(false);
      message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
    }
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const handleChangeStatus = async (model: UpdatedTreatmentStatusModel) => {
    setIsLoadingList(true);
    try {
      await updateTreatmentStatus(model);
      setIsLoadingList(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
        onOk: () => {
          handleSearch.callback();
        },
      });
    } catch (e) {
      setIsLoadingList(false);
      Modal.error({
        title: "Thông báo",
        content: e?.response?.data?.error?.message ?? DefaultErrorMessage,
        onOk: () => {
          handleSearch.callback();
        },
      });
    }
  };
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeTreatment(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<TreatmentModel> = [
    {
      title: "Tên liệu trình",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <Link to={`/customers/${params.customerId}/treatments/${item.id}`}>
        {name}
      </Link>
      ),  
    },
    {
      title: "Số hợp đồng",
      dataIndex: "contractNumber",
      key: "contractNumber",
      render: (contractNumber: string, item) => (
        <React.Fragment>
          {contractNumber}
        </React.Fragment>
      ),  
    },
    { 
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status: TreatmentStatus,item) => (
        <React.Fragment>
        <Select
          placeholder="Chọn trạng thái"
          optionFilterProp="children"
          value={status}
          onChange={(e) => {
            let modal = Modal.confirm({
              title: "Xác nhận",
              icon: <ExclamationCircleOutlined />,
              content: "Bạn có muốn thay đổi trạng thái không?",
              okText: "Đồng ý",
              visible: false,
              onOk: () => {
                handleChangeStatus({id: item.id, status:e.valueOf()});
                modal.destroy();
              },
              cancelText: "Huỷ bỏ",
            });
          }}
        >
            <Option value={TreatmentStatus.New}>
            <Tag color="#108ee9">Đang điều trị</Tag>
          </Option>
          <Option value={TreatmentStatus.Pending}>
            <Tag color="#9254de">Chờ duyệt </Tag>
          </Option>
          <Option value={TreatmentStatus.Done}>
            <Tag color="#87d068">Đã hoàn thành</Tag>
          </Option>
          <Option value={TreatmentStatus.Cancel}>
            <Tag color="#f50">Hủy </Tag>
          </Option>
        </Select>
      </React.Fragment>
      ),
    }
  ];
  return (
    <div className="treatment-page">
      <CustomerDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
      ></CustomerDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="3"
        customerId={params.customerId}
      ></CustomerDetailMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên liệu trình cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.contractNumber}
            placeholder="Nhập số hợp đồng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, contractNumber: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Chọn trạng thái"
            optionFilterProp="children"
            allowClear={true}
            className="w-100"
            mode="multiple"
            value={filterModel.status}
            onChange={(value) => {
              setFilterModel({ ...filterModel, status: value });
            }}
          >
             <Option value={TreatmentStatus.New}>
            <Tag color="#108ee9">Đang điều trị</Tag>
          </Option>
          <Option value={TreatmentStatus.Pending}>
            <Tag color="#9254de">Chờ duyệt </Tag>
          </Option>
          <Option value={TreatmentStatus.Done}>
            <Tag color="#87d068">Đã hoàn thành</Tag>
          </Option>
          <Option value={TreatmentStatus.Cancel}>
            <Tag color="#f50">Hủy </Tag>
          </Option>
          </Select>
        </Col>
        <Col span={6} className="d-flex justify-content-end">
            <Button type="primary" onClick={handleAddTreatment} icon={<PlusCircleOutlined />}>
              Thêm liệu trình mới
            </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>    
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <TreatmentModalComponent customerId={params.customerId} model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} />
    </div>
  );
};

export default Treatment;

