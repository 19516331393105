import React, { FC, useState, useEffect } from "react";
import {
  globalValueSetPageTitle,
  globalValueShowErrorToast,
  globalValueShowWarningToast,
} from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
} from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedBlogModel,
  SearchBlogRequestModel,
  BlogModel,
} from "states/blog/blog.models";
import { removeBlog, searchBlog } from "states/blog/blog.api";
import moment from "moment";
import { Link } from "react-router-dom";
import { BlogCategoryModel } from "states/blog-category/blog-category.models";
import { searchBlogCategory } from "states/blog-category/blog-category.api";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";

interface IBlogProps {}
const { Option } = Select;
const Blog: FC<IBlogProps> = () => {
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<RemovedBlogModel>({
    idList: [],
  });
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<BlogModel>
  );

  const [filterModel, setFilterModel] = useState<SearchBlogRequestModel>({
    page: 1,
    pageSize: 20,
  });
  const [blogCategories, setBlogCategories] = useState<BlogCategoryModel[]>([]);
  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const getBlogCategoryies = async () => {
    try {
      const res = await searchBlogCategory({
        name: "",
        page: 1,
        pageSize: 1000,
      });
      setBlogCategories(res.data.results);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    getBlogCategoryies();
    document.title = "Danh sách bài viết";
  }, []);
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchBlog({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeBlog(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<BlogModel> = [
    {
      title: "Bài viết",
      dataIndex: "title",
      key: "title",
      render: (title: string, item) => (
        <React.Fragment>
          <Link to={`/blog/update/${item.id}`}>{title}</Link>
        </React.Fragment>
      ),
    },
    {
      title: "Đường dẫn",
      dataIndex: "slug",
      key: "slug",
      render: (slug: string) => <React.Fragment>{slug}</React.Fragment>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (creationTime: Date) => (
        <React.Fragment>
          {moment(creationTime).format("YYYY-MM-DD hh:mm:ss A")}
        </React.Fragment>
      ),
    },
    {
      title: "Lần cập nhật cuối cùng",
      dataIndex: "lastModificationTime",
      key: "lastModificationTime",
      render: (lastModificationTime: Date) => (
        <React.Fragment>
          {moment(lastModificationTime).format("YYYY-MM-DD hh:mm:ss")}
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="blog">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.title}
            placeholder="Nhập tên bài viết cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, title: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Select
            className="w-100"
            placeholder="Chọn danh mục"
            onChange={(value: number) => {
              setFilterModel({ ...filterModel, blogCategoryId: value });
            }}
            allowClear
            value={filterModel?.blogCategoryId}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {blogCategories &&
              blogCategories.map((blogCate) => (
                <Option key={blogCate.id} value={blogCate.id}>
                  {blogCate.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Link to="/blog/add">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Thêm tin tức
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default Blog;
