

export interface OrderModel {
  key: string;
  id: number | string;
  orderNumber: string;
  totalAmount: number;
  shippingFee: number;
  orderStatus: OrderStatus;
  orderItems: OrderItemModel[];
  customerName: string;
  shipName:string;
  shipPhoneNumber: string;
  couponCode: string;
  discountAmount: number;
  phoneNumber: string;
  email: string;
  name: string;
  address: string;
  districtId: number | string;
  districtName: string;
  provinceId: number | string;
  provinceName: string;
  wardId: number | string;
  wardName: string;
  paymentStatus: PaymentStatus;
  subTotalAmount: number;
  kiotVietId: number |string;
  creationTime: Date;
  lastModificationTime?: Date;
}
export enum PaymentStatus{
  Unpaid,
  Paid
}
export enum OrderStatus{
  Processing,
  Shipping,
  Completed,
  Cancel
}
export interface OrderItemModel{
  skuId: number | string;
  orderId: number | string;
  price: number;
  quantity: number;
  skuName: string;
}
export interface UpdatedOrderModel {
  id: number;
  paymentStatus: PaymentStatus;
  orderStatus: OrderStatus;
}

export interface UpdatedOrderStatusModel {
  id: number;
  orderStatus: OrderStatus;
}


export interface UpdatedPaymentStatusModel {
  id: number;
  paymentStatus: PaymentStatus;
}

export interface SearchOrderRequestModel {
  orderNumber?: string;
  orderStatus?: number;
  paymentStatus?: number;
  fromDate?: string;
  toDate?: string;
  name?: string;
  page: number;
  pageSize: number;
}

export interface RemovedOrderModel {
  idList: number[];
}
