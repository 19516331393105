export interface TreatmentModel {
  key: string;
  id: number;
  name: string;
  customerId: number;
  contractId: number;
  status: TreatmentStatus;
  contractNumber: string;
}
export interface CreatedTreatmentModel {
  name: string;
  customerId: number;
  contractId: number;
  status: TreatmentStatus;
}
export interface UpdatedTreatmentModel {
  id: number;
  name: string;
  customerId: number;
  contractId: number;
  status: TreatmentStatus;
}
export interface UpdatedTreatmentStatusModel{
  id: number;
  status: TreatmentStatus;
}
export interface SearchTreatmentRequestModel {
  name?: string;
  contractNumber?: string;
  customerId?: string;
  status?: number[];
  page: number;
  pageSize: number;
}

export interface RemovedTreatmentModel {
  idList: number[];
}

export enum TreatmentStatus{
  New,
  Done,
  Cancel,
  Pending,
}
