import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Space,
  Divider,
  
} from "antd";
import _ from "lodash";
import {  DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedTreatmentModel,
  TreatmentModel,
  TreatmentStatus,
} from "states/treatment/treatment.models";
import {
  getTreatmentById,
} from "states/treatment/treatment.api";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { useParams } from "react-router-dom";
import { CustomerDetailMenu } from "modules/customer/components/customer-menu";
import { getCustomerById } from "states/customer/customer.api";
import { CustomerModel } from "states/customer/customer.models";
import { TreatmentDetailBreadcrumb } from "./components/treatment-detail-breadcrumb";
import { SearchTreatmentImageRequestModel, TreatmentImageModel,  } from "states/treatment/treatment-Image.models";
import { TreatmentDetailMenu } from "./components/treatment-menu";
import moment from "moment";
import { removeTreatmentImage, searchTreatmentImage } from "states/treatment/treatment-image.api";
import { env } from "process";
import { redirectFileLocalUrl } from "shared/plugins/string-helper";
const { Option } = Select;
interface ParamsRoute{
  customerId: string;
  treatmentId: string;
}
interface ITreatmentImageProps {

};
const TreatmentImage: FC<ITreatmentImageProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedTreatmentModel>({
    idList: [],
  });
  const [customerModel, setCustomerModel] = useState<CustomerModel>(
    {} as CustomerModel
  );
  const [treatmentModel, setTreatmentModel] = useState<TreatmentModel>(
    {} as TreatmentModel
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<TreatmentImageModel>
  );
  const params = useParams<ParamsRoute>();
  const [filterModel, setFilterModel] = useState<SearchTreatmentImageRequestModel>({
    treatmentId: params?.treatmentId,
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
 const _handleGetSetupData = () =>{
  getCustomerById(Number(params?.customerId)).then(
    (customerResponse) => {
      setCustomerModel(customerResponse.data);
    }
  );
  getTreatmentById(Number(params?.treatmentId)).then(
    (response) => {
      setTreatmentModel(response.data);
    }
  );
 }
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách liệu trình";
    _handleGetSetupData();
  }, []);
 
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchTreatmentImage({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try{
            await removeTreatmentImage(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const expandedRowRender = (data: TreatmentImageModel) => {
    return  (
      <Space size="small">
      {
        data?.images.map((img,index) => (
          <a href={redirectFileLocalUrl(img)}>
          <img
             key={index}
             width={200}
             src={redirectFileLocalUrl(img)}
             alt={redirectFileLocalUrl(img)}
           />
          </a>
       ))
      }
    </Space>
    );
  };
  const columns: ColumnsType<TreatmentImageModel> = [
    {
      title: "Ngày chụp hình",
      dataIndex: "captureDate",
      key: "captureDate",
      render: (captureDate: Date, item) => (
        <Typography.Text>
           {moment(captureDate).format("DD-MM-YYYY")}
        </Typography.Text>
      ),  
    }
  ];
  return (
    <div className="Treatment-page">
      <TreatmentDetailBreadcrumb
        className="mb-4"
        customerName={customerModel.name}
        customerId={customerModel.id}
        treatmentName={treatmentModel.name}
      ></TreatmentDetailBreadcrumb>
      <CustomerDetailMenu
        activeKey="3"
        customerId={params.customerId}
      ></CustomerDetailMenu>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={20} className="d-flex justify-content-start">
            <TreatmentDetailMenu activeKey="4" customerId={params.customerId} treatmentId={params.treatmentId} />
        </Col>
        <Col span={4} className="d-flex justify-content-end">
            {/* <Button type="primary" onClick={handleAddTreatmentImage} icon={<PlusCircleOutlined />}>
              Thêm kết quả mới
            </Button> */}
        </Col>
      </Row>
      <Table
        expandable={{expandedRowRender: record => expandedRowRender(record)}}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      {/* <TreatmentImageModalComponent treatmentId={params.treatmentId} model={model} onModal={isModal} closeModal={handleCloseModal} onSuccess={handleSearch.callback} isUpdate={isUpdate} /> */}
    </div>
  );
};

export default TreatmentImage;

