import * as React from "react";
import { FC } from "react";
import { Button, Form, Input, message } from "antd";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "./login-page.less";
import { setRefreshToken, setToken } from "shared/plugins/http-service";
import { RootState } from "app.store";
import { Link, Redirect } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { DefaultErrorMessage } from "shared/constants/message";
import { setLoginUser } from "states/account/account.action";
import { getProfile, login } from "states/account/account.api";

type LoginPageProps = any;

const LoginPage: FC<LoginPageProps> = () => {
  document.title = "Admin Login";
  const isLogin = useSelector(
    (state: RootState) => state.account.isAuthentication,
    shallowEqual
  );

  const dispatch = useDispatch();
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);

  const onLoginSubmit = async (values: any) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const loginResponse = await login(values);
      setRefreshToken(loginResponse.refreshToken);
      setToken(loginResponse.accessToken);
      dispatch(setLoginUser(await getProfile()));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  return isLogin ? (
    <Redirect to="/"></Redirect>
  ) : (
    <div className="loginlayout">
      <div className="header-login">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
        </div>
      </div>
      <div className="body-login">
        <Form form={form} name="login-form" onFinish={onLoginSubmit}>
          <h1>Sign In</h1>
          <Form.Item
            name="userName"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input
              size={"large"}
              prefix={<UserOutlined />}
              placeholder="Username or email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              size={"large"}
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block={true}
              size="large"
              className="mb-2"
              loading={loading}
            >
              Sign In
            </Button>
            <Link className="login-form-forgot" to="/forgot-password">
              Forgot password
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
