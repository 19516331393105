export function groupBy<T>(
  list: T[],
  keyGetter: (i: T) => string
): GroupByModel<T>[] {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map, ([name, value]) => ({ name, value }));
}

export interface GroupByModel<T> {
  name: any;
  value: T[];
}
