import {
  PRODUCT_ATTRIBUTE_API,
  PRODUCT_ATTRIBUTE_GROUP_API,
  PRODUCT_ATTRIBUTE_VALUE_API,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  RemovedProductAttributeGroupModel,
  ProductAttributeGroupModel,
  ProductAttributeModel,
  RemovedProductAttributeModel,
  ProductAttributeValueModel,
  RemovedProductAttributeValueModel,
} from "./product-attribute.models";

export const getProductAttributeGroupList = async () => {
  const createResponse = await getHttp<ProductAttributeGroupModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_GROUP_API}`
  );
  return createResponse;
};

export const removeProductAttributeGroup = async (
  removedModel: RemovedProductAttributeGroupModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_GROUP_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductAttributeGroupById = async (id: number) => {
  return await getHttp<ProductAttributeGroupModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_GROUP_API}/${id}`
  );
};

export const addProductAttributeGroup = async (
  model: ProductAttributeGroupModel
) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_GROUP_API}`,
    model
  );
  return response;
};

export const updateProductAttributeGroup = async (
  model: ProductAttributeGroupModel
) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_GROUP_API}`,
    model
  );
  return response;
};

export const getProductAttributeList = async () => {
  const createResponse = await getHttp<ProductAttributeModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_API}`
  );
  return createResponse;
};

export const removeProductAttribute = async (
  removedModel: RemovedProductAttributeModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductAttributeById = async (id: number) => {
  return await getHttp<ProductAttributeModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_API}/${id}`
  );
};

export const addProductAttribute = async (model: ProductAttributeModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_API}`,
    model
  );
  return response;
};

export const updateProductAttribute = async (model: ProductAttributeModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_API}`,
    model
  );
  return response;
};

export const getProductAttributeValueList = async () => {
  const createResponse = await getHttp<ProductAttributeValueModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_VALUE_API}`
  );
  return createResponse;
};

export const removeProductAttributeValue = async (
  removedModel: RemovedProductAttributeValueModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_VALUE_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductAttributeValueById = async (id: number) => {
  return await getHttp<ProductAttributeValueModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_VALUE_API}/${id}`
  );
};

export const addProductAttributeValue = async (
  model: ProductAttributeValueModel
) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_VALUE_API}`,
    model
  );
  return response;
};

export const updateProductAttributeValue = async (
  model: ProductAttributeValueModel
) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_ATTRIBUTE_VALUE_API}`,
    model
  );
  return response;
};
