import { postHttp, getHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  USER_MANAGEMENT_API,
  USER_MANAGEMENT_API_ROLE,
} from "shared/constants/api-endpoint";
import {
  CreateUserManagementModel,
  AppRoleModel,
  UserManagementModel,
  RemovedUserManagementModel,
  UpdateUserManagementModel,
} from "./user-management.models";

export const createUser = async (
  createUserManagementModel: CreateUserManagementModel
) => {
  const createResponse = await postHttp<string>(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API}`,
    createUserManagementModel
  );
  return createResponse;
};

export const searchUser = async (name?: string, page = 1) => {
  const createResponse = await getHttp<PagedResult<UserManagementModel>>(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API}`,
    {
      params: {
        page,
        name,
        pageSize: 1000,
      },
    }
  );
  return createResponse;
};

export const getUserById = async (id: string) => {
  return await getHttp<UserManagementModel>(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API}/${id}`
  );
};

export const updateUser = async (updateModel: UpdateUserManagementModel) => {
  const response = await putHttp(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API}`,
    updateModel
  );
  return response;
};
export const removeUser = async (removedModel: RemovedUserManagementModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getRoles = async () => {
  return await getHttp<AppRoleModel[]>(
    `${process.env.REACT_APP_API_URI}${USER_MANAGEMENT_API_ROLE}`
  );
};
