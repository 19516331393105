import React, { FC, useState, useEffect, useCallback } from "react";
import { Input, Select, Modal, Form, DatePicker } from "antd";
import {
  TreatmentStatus,
} from "states/treatment/treatment.models";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { CreatedTreatmentScheduleModel, TreatmentScheduleModel, TreatmentScheduleStatus, UpdatedTreatmentScheduleModel } from "states/treatment/treatment-schedule.models";
import { addTreatmentSchedule, updateTreatmentSchedule } from "states/treatment/treatment-schedule.api";
import moment from "moment";
import { MceEditor } from "shared/components/tinymce/mce-editor";

interface ITreatmentScheduleModalProps {
  model: TreatmentScheduleModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  treatmentId: string;
  onSuccess: () => void;
}
const { Option } = Select;
export const TreatmentScheduleModalComponent: FC<ITreatmentScheduleModalProps> = (props) => {
  const { model, onModal, treatmentId, closeModal, onSuccess, isUpdate } = props;
  const [description, setDescription] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedTreatmentScheduleModel = {
          ...values,
          id: values.id,
          fromDate: values.fromDate,
          toDate:values.toDate,
          title:values.title,
          description: values.description,
          status: values.status,
          treatmentId: Number(treatmentId),
        };
        await updateTreatmentSchedule(payload);
      } else {
        let payload: CreatedTreatmentScheduleModel = {
          ...values,
          fromDate: values.fromDate,
          toDate:values.toDate,
          title:values.title,
          description: values.description,
          status: values.status,
          treatmentId: Number(treatmentId),
        };
        await addTreatmentSchedule(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onStatusChange = (value: TreatmentStatus) => {
    form.setFieldsValue({ status: value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      fromDate: moment(model?.fromDate??new Date()),
      toDate: moment(model?.toDate ??new Date()),
      title: model?.title,
      description: model?.description,
      status: model?.status,
      treatmentId: treatmentId,
    });
  };
  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => { }}
        // end
        >
          {isUpdate ? `Cập nhật lịch điều trị` : `Thêm lịch điều trị mới`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="treatmentModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Từ ngày"
          name="fromDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày điều trị!" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày điều trị"
          />
        </Form.Item>
        <Form.Item
          label="Đến ngày"
          name="toDate"
          rules={[{ required: true, message: "Vui lòng chọn ngày điều trị!" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Chọn ngày điều trị"
          />
        </Form.Item>
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[{ required: true, message: "Vui lòng nhập tiêu đề" }]}>
          <Input placeholder="Nhập tiêu đề"></Input>
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
        >
          <MceEditor height={400} pageContent={description} onChange={(value) => {
            setDescription(value)
            form.setFieldsValue({ "description": value });
          }} />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
        >
          <Select
            className="w-100"
            placeholder="Chọn trạng thái"
            onChange={onStatusChange}
            allowClear
          >
            <Option value={TreatmentScheduleStatus.New}>Mới </Option>
            <Option value={TreatmentScheduleStatus.Done}>Đã hoàn thành</Option>
            <Option value={TreatmentScheduleStatus.Cancel}>Hủy bỏ</Option>
          </Select>
        </Form.Item>
        <Form.Item
          hidden={true}
          name="id"
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
