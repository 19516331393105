import React, { FC, useState, useEffect } from "react";
import {
  globalValueSetLoading,
  globalValueSetPageTitle,
  globalValueShowErrorToast,
  globalValueShowWarningToast,
} from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
  DatePicker,
  Breadcrumb,
  Descriptions,
  Divider,
  Card,
} from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  OrderModel,
  OrderItemModel,
  OrderStatus,
  UpdatedOrderModel,
  PaymentStatus,
  UpdatedOrderStatusModel,
  UpdatedPaymentStatusModel,
} from "states/order/order.models";
import { getOrderById, updateOrder, updateOrderStatus, updatePaymentStatus, } from "states/order/order.api";
import moment from "moment";
import { Link,useParams } from "react-router-dom";
import {
  DefaultErrorMessage,

} from "shared/constants/message";
import { OrderStatusComponent } from "./components/order-status";
import { PaymentStatusComponent } from "./components/payment-status";
import { formatMoney } from "shared/plugins/string-helper";

interface IOrderDetailProps {}
interface RouteParams {
  orderId: string;
}
const OrderDetail: FC<IOrderDetailProps> = () => {
  const { orderId } = useParams<RouteParams>();
  const [model, setModel] = useState<OrderModel>({} as OrderModel);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const dispatch = useDispatch(); 
  const getOrder = async () => {
    setIsLoadingList(true);
    try {
      const res = await getOrderById(Number(orderId));
      setModel(res.data);
      setIsLoadingList(false);
    } catch (error) {
      setIsLoadingList(false);
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const _handleUpdateOrderStatus = async (status: OrderStatus) =>{
    dispatch(globalValueSetLoading(true));
    try{
      await updateOrderStatus({id: model.id,
        orderStatus:status} as UpdatedOrderStatusModel);
      await handleGetOrder.callback();
      dispatch(globalValueSetLoading(false));
    }catch (error) {
      dispatch(globalValueSetLoading(false));
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  }
  const  _handleUpdatePaymentStatus = async (status: PaymentStatus) =>{
    dispatch(globalValueSetLoading(true));
    try{
      await updatePaymentStatus({id: model.id,paymentStatus:status} as UpdatedPaymentStatusModel);
      await handleGetOrder.callback();
      dispatch(globalValueSetLoading(false));
    }catch (error) {
      dispatch(globalValueSetLoading(false));
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  }
  const handleGetOrder = useDebouncedCallback(() => {
    getOrder();
  },1000)
  useEffect(() => {
    handleGetOrder.callback();
    document.title = "Thông tin đơn hàng";
  }, []);
  const columns: ColumnsType<OrderItemModel> = [
    {
      title: "Hình ảnh",
      dataIndex: "skuImage",
      key: "skuImage",
      render: (skuImage: string, item) => (
        <React.Fragment>
          <img src={skuImage} alt="" />
        </React.Fragment>
      ),
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "skuName",
      key: "skuName",
      render: (skuName: string) => <Typography.Text>{skuName}</Typography.Text>,
    },
    {
      title: "Giá gốc",
      dataIndex: "price",
      key: "price",
      render: (price: string | number,item: OrderItemModel) => (
        <React.Fragment>{formatMoney(Number(price) / item.quantity)}</React.Fragment>
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string) => <React.Fragment>{quantity}</React.Fragment>,
    },
    {
      title: "Thành tiền",
      key: "abc",
      render: (item: OrderItemModel) => (
        <React.Fragment>
          {formatMoney(item.price)}
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="order-detail">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/orders">Danh sách đơn hàng</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Mã đơn hàng #{model.orderNumber}</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={16}>
      <Col span={8}>
      <Card size="small" title="Thông tin khách hàng"  >
        <p>
        <Typography.Text>Tên khách hàng : <strong>{model.shipName}</strong></Typography.Text>
        </p>
        <p>
        <Typography.Text>Số điện thoại : <strong>{model.shipPhoneNumber}</strong></Typography.Text>
        </p>
        <p>
        <Typography.Text>Địa chỉ email : <strong>{model.email}</strong></Typography.Text>
        </p>
        <p>
        <Typography.Text>Địa chỉ giao hàng : <strong>{model.address}
            {model.wardName !== null ? `, ${model.wardName}` : ``},{" "}
            {model.districtName}, {model.provinceName}</strong></Typography.Text>
        </p>
        <p>
        <Typography.Text>Tên người mua : <strong>{model.name}</strong></Typography.Text>
        </p>
        <p>
        <Typography.Text>Số điện thoại người mua : <strong>{model.phoneNumber}</strong></Typography.Text>
        </p>
      </Card>
      </Col>
      <Col span={8}>
        <Card  size="small" title="Thông tin đơn hàng" >
          <p>
          <Typography.Text>Tạm tính : <strong>{formatMoney(model.subTotalAmount)}</strong></Typography.Text>
          </p>
          <p>
          <Typography.Text>Giảm giá : <strong>{formatMoney(model.discountAmount)}</strong></Typography.Text>
          </p>
          <p>
          <Typography.Text>Phí giao hàng : <strong>{formatMoney(model.shippingFee)}</strong></Typography.Text>
          </p>
          <p>
          <Typography.Text>Thành tiền : <strong>{formatMoney(model.totalAmount)}</strong></Typography.Text>
          </p>
        </Card>
      </Col> 
      <Col span={8}>
        <Card  size="small" title="Xử lí đơn hàng" >
          <p>
          <Typography.Text>Trạng thái đơn hàng : </Typography.Text>
          <OrderStatusComponent orderStatus={model?.orderStatus} onChange={_handleUpdateOrderStatus} />
          </p>
          <p>
          <Typography.Text>Trạng thái thanh toán :</Typography.Text>
          <PaymentStatusComponent paymentStatus={model?.paymentStatus}  onChange={_handleUpdatePaymentStatus}/>
          </p>
          </Card>
      </Col>
      </Row>
      <Divider />
      <Table
        columns={columns}
        dataSource={model.orderItems}
        size="small"
        pagination={false}
        loading={isLoadingList}
      />
    </div>
  );
};

export default OrderDetail;
