import {
  ADDRESS_API_DISTRICT,
  ADDRESS_API_PROVINCE,
  ADDRESS_API_WARD,
} from "./../../shared/constants/api-endpoint";
import { getHttp } from "shared/plugins/http-service";
import { DistrictModel, ProvinceModel, WardModel } from "./address.models";

export const getProvinceList = async () => {
  return await getHttp<ProvinceModel[]>(
    `${process.env.REACT_APP_API_URI}${ADDRESS_API_PROVINCE}`
  );
};

export const getDistrictList = async (provinceId: number) => {
  return await getHttp<DistrictModel[]>(
    `${process.env.REACT_APP_API_URI}${ADDRESS_API_DISTRICT}?provinceId=${provinceId}`
  );
};

export const getWardList = async (districtId: number) => {
  return await getHttp<WardModel[]>(
    `${process.env.REACT_APP_API_URI}${ADDRESS_API_WARD}?districtId=${districtId}`
  );
};
