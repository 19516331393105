import React, { FC, useState, useEffect } from "react";
import { globalValueSetPageTitle } from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
} from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedBlogCategoryModel,
  SearchBlogCategoryRequestModel,
  BlogCategoryModel,
} from "states/blog-category/blog-category.models";
import {
  removeBlogCategory,
  searchBlogCategory,
} from "states/blog-category/blog-category.api";
import { BlogCategoryModalComponent } from "./blog-category-modal";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";

interface IBlogCategoryProps {}
const BlogCategory: FC<IBlogCategoryProps> = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedBlogCategoryModel>({
    idList: [],
  });
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<BlogCategoryModel>
  );
  const [isModal, setModal] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [model, setModel] = useState<BlogCategoryModel>(
    {} as BlogCategoryModel
  );

  const [
    filterModel,
    setFilterModel,
  ] = useState<SearchBlogCategoryRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  const handleCloseModal = () => {
    setModal(false);
    // if(!isUpdate){
    //   handleSearch.callback();
    // }
  };
  const handleAddBlogCategory = () => {
    setModel({} as BlogCategoryModel);
    setModal(true);
    setUpdate(false);
  };
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchBlogCategory({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeBlogCategory(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<BlogCategoryModel> = [
    {
      title: "Tên danh mục",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              setModel(item);
              setModal(true);
              setUpdate(true);
            }}
          >
            {name}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: "Đường dẫn",
      dataIndex: "slug",
      key: "slug",
      render: (slug: string) => <React.Fragment>{slug}</React.Fragment>,
    },
  ];

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    dispatch(globalValueSetPageTitle("Danh mục bài viết"));
  }, []);

  return (
    <div className="BlogCategory">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên danh mục cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={handleAddBlogCategory}
            icon={<PlusCircleOutlined />}
          >
            Thêm danh mục
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
      <BlogCategoryModalComponent
        model={model}
        onModal={isModal}
        closeModal={handleCloseModal}
        onSuccess={handleSearch.callback}
        isUpdate={isUpdate}
      />
    </div>
  );
};

export default BlogCategory;
