import React, { FC, useState, useEffect } from "react";
import { Input, Select, Modal, Form } from "antd";
import {
  TreatmentModel,
  TreatmentStatus,
  CreatedTreatmentModel,
  UpdatedTreatmentModel,
} from "states/treatment/treatment.models";
import { addTreatment, updateTreatment } from "states/treatment/treatment.api";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import {
  ContractModel,
  SearchContractRequestModel,
} from "states/contract/contract.models";
import { useDebouncedCallback } from "use-debounce/lib";
import { searchContract } from "states/contract/contract.api";
import { PagedResult } from "states/paged-result-model";

interface ITreatmentModalProps {
  model: TreatmentModel;
  onModal: boolean;
  closeModal: () => void;
  isUpdate: boolean;
  customerId: string;
  onSuccess: () => void;
}
const { Option } = Select;
export const TreatmentModalComponent: FC<ITreatmentModalProps> = (props) => {
  const { model, onModal, customerId, closeModal, onSuccess, isUpdate } = props;
  const [form] = Form.useForm();
  const [filterModel, setFilterModel] = useState<SearchContractRequestModel>({
    page: 1,
    pageSize: 20,
  });
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<ContractModel>
  );
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedTreatmentModel = {
          ...values,
          id: values.id,
          name: values.name,
          status: values.status,
          contractId: values.contractId,
          customerId: Number(customerId),
        };
        await updateTreatment(payload);
      } else {
        let payload: CreatedTreatmentModel = {
          ...values,
          name: values.name,
          status: values.status,
          contractId: values.contractId,
          customerId: Number(customerId),
        };
        await addTreatment(payload);
      }
      handleCloseModal();
      setLoading(false);
      onSuccess();
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onStatusChange = (value: TreatmentStatus) => {
    form.setFieldsValue({ status: value });
  };
  const onContractChange = (value: string) => {
    form.setFieldsValue({ contractId: value });
  };
  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      name: model?.name,
      status: model?.status,
      contractId: model?.contractId,
      customerId: customerId,
    });
  };
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  const gotoPage = async (page: number) => {
    filterModel.customerId = customerId;
    const result = await searchContract({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate ? `Cập nhật liệu trình` : `Thêm liệu trình mới`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="treatmentModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Tên liệu trình"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập tên liệu trình!" }]}
        >
          <Input className="w-100" placeholder="Tên liệu trình" />
        </Form.Item>
        <Form.Item
          label="số hợp đồng"
          name="contractId"
          rules={[{ required: true, message: "Vui lòng chọn số hợp đồng!" }]}
        >
          <Select
            className="w-100"
            placeholder="Chọn số hợp đồng"
            onChange={onContractChange}
            showSearch
            onSearch={(value) => {
              setFilterModel({ ...filterModel, contractNumber: value });
            }}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {pagedResults?.results?.map((m) => (
              <Option value={m.id}>{m.contractNumber}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Trạng thái" name="status">
          <Select
            disabled={!isUpdate}
            className="w-100"
            placeholder="Chọn trạng thái"
            onChange={onStatusChange}
            allowClear
          >
            <Option value={TreatmentStatus.New}>Đang điều trị</Option>
            <Option value={TreatmentStatus.Pending}>Chờ duyệt</Option>
            <Option value={TreatmentStatus.Done}>Đã hoàn thành</Option>
            <Option value={TreatmentStatus.Cancel}>Hủy bỏ</Option>
          </Select>
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
