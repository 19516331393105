import React, { useEffect, useState} from "react";
import { addThemeSetting, getThemeSettingById, updateThemeSetting } from "states/theme-setting/theme-setting.api";
import { CreateThemeSettingModel, IThemeConfigModel, ThemeType, UpdatedThemeSettingModel } from "states/theme-setting/theme-setting.models";
import { Breadcrumb, Button, Col, message, Modal, notification, Row } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import { ThemeConfigItem } from "./theme-config-item";
import _ from "lodash";
import { UpdateDataSuccessfully } from "shared/constants/message";

type TParams = { themeSettingId: string };

const AddUpdateThemeConfig = () => {
  const {themeSettingId}  = useParams<TParams>();
  const history = useHistory();
  const [model, setModel] = useState<IThemeConfigModel>({
    name: "",
    slug: "",
    themeType: ThemeType.ThemeConfigModel,
    data: [],
  } as IThemeConfigModel);
  const [action, setAction] = useState<string>("");
  //#region Toggle
  //#endregion
  const _handleGetUpdate = async() => {
    try{
        let response = await getThemeSettingById(Number(themeSettingId));
        setModel(response.data.data);
    }
    catch(error){
     notification.error(error?.response?.data?.error?.message)
    }
  };
  const onSaved = async () => {
    if(model.name === null || model.name === ""){
      Modal.error({title:"Thông báo",content:"Vui lòng nhập tên giao diện"})
      return false;
    }
    if(model.slug === null || model.slug === ""){
      Modal.error({title:"Thông báo",content:"Vui lòng nhập key giao diện"})
      return false;
    }
    try{
      if(action === "add"){
        let payload: CreateThemeSettingModel ={
          key: model.slug,
          data: model
        }
        await addThemeSetting(payload);
      }else{
        let payload: UpdatedThemeSettingModel ={
          id: Number(themeSettingId),
          key: model.slug,
          data: model
        }
        await updateThemeSetting(payload);
      }
      message.success(UpdateDataSuccessfully);
      history.go(0);
    }catch(error){
      notification.error(error?.response?.data?.error?.message);
    }
   
  };

  useEffect(() => {
    if (document.location.href.indexOf("/theme-setting/add") > 0) {
      setAction("add");
    } else {
      setAction("edit");
      _handleGetUpdate();
    }
    document.title = "Quản lí giao diện";
  }, []);

  return (
    <div className="theme-config-update">
       <Breadcrumb className="mb-4">
          <Breadcrumb.Item>
            <Link to="/theme-setting">Quản lí giao diện</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Quản lí giao diện</Breadcrumb.Item>
        </Breadcrumb>
       <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Link to="/theme-setting" className="mr-2">
            <Button type="default" icon={<RollbackOutlined />}>
              Quay lại
            </Button>
          </Link>
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
        <ThemeConfigItem
            model={model}
            onChange={newModel => {
              setModel(newModel);
            }}
            isShowKeyInput={action === "add"}
          />
    </div>
  );
};

export default AddUpdateThemeConfig;
