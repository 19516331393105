import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  ACCOUNT_API_PASSWORD,
  ACCOUNT_API_FORGOT_PASSWORD,
  ACCOUNT_API_RESET_PASSWORD,
  ACCOUNT_API_PROFILE,
  ACCOUNT_API_LOGIN,
} from "shared/constants/api-endpoint";
import {
  ChangePasswordModel,
  CurrentUser,
  ForgotPasswordModel,
  LoginResponse,
  LoginUserModel,
  ResetPasswordModel,
} from "./account.models";

export const changePasswordApi = async (
  changePasswordModel: ChangePasswordModel
) => {
  const response = await putHttp(
    `${process.env.REACT_APP_API_URI}${ACCOUNT_API_PASSWORD}`,
    changePasswordModel
  );
  return response;
};
export const forgotPasswordApi = async (
  forgotPasswordModel: ForgotPasswordModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${ACCOUNT_API_FORGOT_PASSWORD}`,
    forgotPasswordModel
  );
  return response;
};
export const resetPasswordApi = async (
  resetPasswordModel: ResetPasswordModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${ACCOUNT_API_RESET_PASSWORD}`,
    resetPasswordModel
  );
  return response;
};

export const login = async (loginModel: LoginUserModel) => {
  const loginResponse = await postHttp<LoginResponse>(
    `${process.env.REACT_APP_API_URI}${ACCOUNT_API_LOGIN}`,
    loginModel
  );
  return loginResponse.data;
};

export const getProfile = async () => {
  const profileResponse = await getHttp<CurrentUser>(
    `${process.env.REACT_APP_API_URI}${ACCOUNT_API_PROFILE}`
  );
  return profileResponse.data;
};
