import { Spin } from "antd";
import { RootState } from "app.store";
import * as React from "react";
import { useSelector } from "react-redux";

export function Loading(props: any) {
  const isLoading = useSelector(
    (state: RootState) => state.globalValue.isLoading
  );
  return <Spin spinning={isLoading}>{props.children}</Spin>;
}
