import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { MceEditor } from "shared/components/tinymce/mce-editor";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { globalValueSetLoading } from "states/global-value/global-value.action";
import {
  addStaticPage,
  getStaticPageById,
  updateStaticPage,
} from "states/static-page/static-page.api";
import {
  CreateStaticPageModel,
  StaticPageModel,
  UpdatedStaticPageModel,
} from "states/static-page/static-page.models";

interface RouteParams {
  staticPageId: string;
}
interface IAddUpdateStaticPageProps {}

export function AddUpdateStaticPage(props: IAddUpdateStaticPageProps) {
  const history = useHistory();
  const { staticPageId } = useParams<RouteParams>();
  const [model, setModel] = useState<StaticPageModel>({} as StaticPageModel);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const dispatch = useDispatch();

  const getStaticPage = async () => {
    try {
      const res = await getStaticPageById(Number(staticPageId));
      setModel(res.data);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };

  const onSaved = async () => {
    dispatch(globalValueSetLoading(true));
    try {
      if (isUpdateMode) {
        const payload = {
          id: model.id,
          isCustomizePage: model.isCustomizePage,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          metaTitle: model.metaTitle,
          pageContent: model.pageContent,
          title: model.title,
        } as UpdatedStaticPageModel;
        await updateStaticPage(payload);
        message.success(UpdateDataSuccessfully);
      } else {
        const payload = {
          isCustomizePage: model.isCustomizePage,
          metaDescription: model.metaDescription,
          metaKeyword: model.metaKeyword,
          metaTitle: model.metaTitle,
          pageContent: model.pageContent,
          title: model.title,
        } as CreateStaticPageModel;
        await addStaticPage(payload);
        message.success(UpdateDataSuccessfully);
      }
      history.go(0);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
    dispatch(globalValueSetLoading(false));
  };

  useEffect(() => {
    if (document.location.href.indexOf("/add") > -1) {
      setIsUpdateMode(false);
    } else {
      setIsUpdateMode(true);
      getStaticPage();
    }
  }, []);

  return (
    <div className="add-update-static-page">
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/static-page">Trang tĩnh</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Trang tĩnh</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={[16, 16]}>
        <Col flex="auto"></Col>
        <Col flex="auto" className="d-flex justify-content-end">
          <Link to="/static-page" className="mr-2">
            <Button type="default" icon={<RollbackOutlined />}>
              Trở lại
            </Button>
          </Link>
          <Button onClick={onSaved} type="primary" icon={<SaveOutlined />}>
            Lưu lại
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span="16">
          <Card title="Thông tin">
            <Input.Group className="mb-3">
              <Typography.Text>Tiêu đề: </Typography.Text>
              <Input
                value={model.title}
                onChange={(e) => {
                  setModel({
                    ...model,
                    title: e.target.value,
                    metaTitle: e.target.value,
                  });
                }}
                placeholder="Tiêu đề"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Nội dung: </Typography.Text>
              <MceEditor
                pageContent={model.pageContent}
                onChange={(e) => {
                  setModel({ ...model, pageContent: e });
                }}
              ></MceEditor>
            </Input.Group>
            <Input.Group className="mb-3">
              <Checkbox
                checked={model.isCustomizePage}
                onChange={(e) => {
                  setModel({ ...model, isCustomizePage: e.target.checked });
                }}
              >
                Trang customize
              </Checkbox>
            </Input.Group>
          </Card>
        </Col>
        <Col span="8">
          <Card title="SEO">
            <Input.Group className="mb-3">
              <Typography.Text>Tiêu đề: </Typography.Text>
              <Input
                value={model.metaTitle}
                onChange={(e) => {
                  setModel({ ...model, metaTitle: e.target.value });
                }}
                placeholder="Tiêu đề"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Mô tả: </Typography.Text>
              <Input.TextArea
                value={model.metaDescription}
                onChange={(e) => {
                  setModel({ ...model, metaDescription: e.target.value });
                }}
                rows={5}
                placeholder="Mô tả"
              ></Input.TextArea>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Từ khóa: </Typography.Text>
              <Input
                value={model.metaKeyword}
                onChange={(e) => {
                  setModel({ ...model, metaKeyword: e.target.value });
                }}
                placeholder="Từ khóa"
              ></Input>
            </Input.Group>
            <Input.Group className="mb-3">
              <Typography.Text>Slug: </Typography.Text>
              <Input
                readOnly={true}
                value={
                  model.id
                    ? `/${model.slug}-p${model.id}.html`
                    : "Url chỉ hiển thị khi đã thêm trang tĩnh"
                }
                placeholder="Slug"
              ></Input>
            </Input.Group>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
