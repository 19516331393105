import React, { FC, useEffect, useState } from "react";
import "./login-page.less";
import { useLocation } from "react-router-dom";
import { SendEmailPasswordForm } from "./components/send-mail-form";
import { ResetPasswordForm } from "./components/reset-password-form";
type ForgotPasswordProps = any;

const ForgotPasswordPage: FC<ForgotPasswordProps> = () => {
  document.title = "Forgot password";
  let location = useLocation();
  const [actionResetPassword, setActionResetPassword] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (location.search !== null && location.search !== "") {
      let url = JSON.parse(
        '{"' +
          decodeURI(location.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      setActionResetPassword(true);
      setToken(url.code);
      setUserId(url.userId);
    }
  }, []);
  return (
    <div className="loginlayout">
      <div className="header-login">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
        </div>
      </div>
      <div className="body-login">
        {actionResetPassword ? (
          <ResetPasswordForm userId={userId} token={token} />
        ) : (
          <SendEmailPasswordForm />
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
