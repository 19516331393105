import {
  CONTRACT_API,
  CONTRACT_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedContractModel,
  SearchContractRequestModel,
  ContractModel,
  UpdatedContractModel,
  CreatedContractModel,
  UpdatedContractStatusModel,
} from "./contract.models";

export const searchContract = async (
  searchContractRequestModel: SearchContractRequestModel
) => {
  const createResponse = await postHttp<PagedResult<ContractModel>>(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API_SEARCH}`,
    searchContractRequestModel
  );
  return createResponse;
};

export const removeContract = async (
  removedModel: RemovedContractModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addContract = async (model: CreatedContractModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API}`,
    model
  );
  return response;
};

export const getContractById = async (id: number) => {
  return await getHttp<ContractModel>(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API}/${id}`
  );
};

export const updateContract = async (model: UpdatedContractModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API}`,
    model
  );
  return response;
};

export const updateContractStatus = async (model: UpdatedContractStatusModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${CONTRACT_API}/update_status`,
    model
  );
  return response;
};
