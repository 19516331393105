import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface ICustomerDetailMenuProps {
  activeKey: string;
  customerId: string;
}

export function CustomerDetailMenu(props: ICustomerDetailMenuProps) {
  const { activeKey, customerId } = props;
  return (
    <Menu selectedKeys={[activeKey]} mode="horizontal" className="mb-4">
      <Menu.Item key="1" icon={<MailOutlined />}>
        <Link to={`/customers/${customerId}`}>Thông tin khách hàng</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        <Link to={`/customers/${customerId}/contracts`}>Hợp đồng</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<AppstoreOutlined />}>
        <Link to={`/customers/${customerId}/treatments`}>Liệu trình</Link>
      </Menu.Item>
    </Menu>
  );
}
