import { combineReducers } from "redux";
import { appSettingReducer } from "./states/app-setting/app-setting.reducer";
import { globalValueReducer } from "./states/global-value/global-value.reducer";
import { accountReducer } from "states/account/account.reducer";

export const rootReducer = combineReducers({
  appSetting: appSettingReducer,
  globalValue: globalValueReducer,
  account: accountReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
