import { FC, useCallback, useEffect } from "react";
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { getToken, postHttp } from "shared/plugins/http-service";
import { FILE_API, UPLOAD_FILE_API } from "shared/constants/api-endpoint";
import { redirectFileLocalUrl, redirectFileUrl } from "shared/plugins/string-helper";
import { IMAGES_FOLDER_ID } from "shared/constants/google-drive";

interface TinyMCEProps {
  pageContent?: string;
  height?: number;
  cssCustom?: string;
  onChange?: (e: any) => void;
}
export const MceEditor: FC<TinyMCEProps> = (props) => {
  const { pageContent, height,cssCustom, onChange } = props;

  const handleEditorChange = useCallback(
    (content: any, editor: any) => {
      onChange && onChange(content);
    },
    [onChange]
  );

  return (
    <React.Fragment>
      <Editor
        initialValue={pageContent ?? ""}
        value={pageContent}
        outputFormat="html"
        plugins="code"
        apiKey="5pxmhov97hcxec4gsw88rlylmkvdi6jospcfscfxnf5rzvdr"
        textareaName=""
        init={{
          width: "100%",
          height: height ?? 500,
          menu: {
            file: { title: "File", items: "newdocument" },
            edit: {
              title: "Edit",
              items: "undo redo | cut copy paste pastetext | selectall",
            },
            insert: { title: "Insert", items: "link media | template hr" },
            view: { title: "View", items: "visualaid visualblocks" },
            format: {
              title: "Format",
              items:
                "bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat"
                //"bold italic underline strikethrough superscript subscript | formats | removeformat",
              },
            table: {
              title: "Table",
              items: "inserttable tableprops deletetable | cell row column",
            },
            tools: { title: "Tools", items: "spellchecker code" },
          },
          tinydrive_token_provider: function (success: any, failure: any) {
            success({ token: getToken() });
            // failure('Could not create a jwt token')
          },
          plugins: [
            "autosave advlist autolink lists link image charmap print preview hr anchor pagebreak codesample",
            "searchreplace visualblocks code fullscreen save contextmenu emoticons template textcolor",
            "insertdatetime media table paste code help wordcount",
            "media imagetools importcss tinydrive paste toc",
          ],
          statusbar: true,
          //insertfile
          toolbar:
            "undo redo | formatselect  | bold italic underline code backcolor | \
                      alignleft aligncenter alignright alignjustify |emoticons | preview | anchor image link openlink unlink media| spellchecker | fullscreen |   \
                      bullist numlist outdent indent | removeformat | help a11ycheck addcomment showcomments casechange checklist formatpainter pageembed permanentpen toc table",
          a11y_advanced_options: true,
          file_picker_types: "file image media",
          image_caption: true,
          image_advtab: true,
          icons: "jam",
          resize: true,
          target_list: [
            { title: "None", value: "" },
            { title: "Same page", value: "_self" },
            { title: "New page", value: "_blank" },
            { title: "Parent frame", value: "_parent" },
          ],
          file_picker_callback: (callback: any, value: any, meta: any) => {
            // Provide file and text for the link dialog
            // Provide image and alt text for the image dialog
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            if (meta.filetype === "image") {
              input.click();
              input.onchange = () => {
                const formData = new FormData();
                const files = input.files;
                if (files && files.length > 0) {
                  for (var i = 0; i < files.length; i++) {
                    formData.append("uploadedFiles", files[i]);
                  }
                }
                postHttp(`${process.env.REACT_APP_API_URI}${UPLOAD_FILE_API}`, formData,{
                  headers: {
                    "content-type": "multipart/form-data",
                  },
                }).then(
                  (response: any) => {
                    callback(`${process.env.REACT_APP_API_URI}/${response.data[0]}`, {
                      alt: files && files.item.name,
                    });
                  }
                );
              };
            }
            // Provide alternative source and posted for the media dialog
            // if (meta.filetype == 'media') {
            //   callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
            // }
          },
          images_upload_handler: function (
            blobInfo: any,
            success: any,
            failure: any,
            progress: any
          ) {
            let formData: any;
            formData = new FormData();
            formData.append(
              "uploadedFiles",
              blobInfo.blob(),
              blobInfo.filename()
            );
            formData.append("folderId", IMAGES_FOLDER_ID);
            postHttp(`${process.env.REACT_APP_API_URI}${UPLOAD_FILE_API}`, formData, {
              headers: {
                "content-type": "multipart/form-data",
              },
            }).then((response: any) => {
              success(redirectFileLocalUrl(response.data[0]));
            });
          },
          font_formats: "Roboto Slab=Roboto Slab,sans-serif; Montserrat=Montserrat,sans-serif; Cosmetic=Cosmetic,sans-serif; SP3 - Traveling Typewriter=SP3 - Traveling Typewriter,sans-serif; Playfair Display=Playfair Display, serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          images_upload_url: "",
          image_title: true,
          images_upload_credentials: true,
          paste_data_images: true,
          typeahead_urls: true,
          content_css: [
            `${process.env.REACT_APP_API_URI}/minfile/tynick.min.css`,
            `${process.env.REACT_APP_API_URI}/fonts/fonts.css`,
            `${cssCustom && `${process.env.REACT_APP_API_URI}${cssCustom}`} }`,
          ],
          content_style: "@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap'); body { font-family: Roboto Slab; }",
          templates: [
            {
              title: "Đoạn trích dẫn đầu trang",
              description: "1 đoạn nội dung trích dẫn đầu trang, nền xám bo góc",
              content:
                '<div class="blog-content__notebox">'+
                '<div class="content-replacement">[selected content will insert here]</div>' +
                '</div>'+
                '<p></p>'
            },
            
            {
              title: "Đoạn quote chính giữa",
              description: "Đoạn quote chính giữa",
              content:
                '<div class="quote">'+
                  '<div class="quote-content">'+
                    '<p>Lorem ipsum, dolor sit amet consectetur, adipisicing elit. Voluptate, vero!</p>'+
                  '</div>'+
                '</div>'+
                '<p></p>'
            },
            {
              title: "Đoạn quote 2 cột",
              description: "1 đoạn quote bên trái và 1 đoạn nội dung bên phải",
              content:
                '<div class="blog-content__quote2col clearfix">'+
                  '<div class="blog-content__quote2col--col">'+
                    '<div><p>Nội dung quote</p></div>'+
                  '</div>'+
                  '<div class="blog-content__quote2col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                '</div>'+
                '<p></p>'
            },
            {
              title: "Đoạn quote 2 cột (Phải)",
              description: "1 đoạn quote bên phải và 1 đoạn nội dung bên trái",
              content:
                '<div class="blog-content__quote2col__right clearfix">'+
                  '<div class="blog-content__quote2col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                  '<div class="blog-content__quote2col--col">'+
                    '<div><p>Nội dung quote</p></div>'+
                  '</div>'+
                '</div>'+
                '<p></p>'
            },
            {
              title: "Chia 2 cột",
              description: "Đoạn code chia 2 cột",
              content:
                '<div class="blog-content__2col">'+
                  '<div class="blog-content__2col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                  '<div class="blog-content__2col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                '</div>'+
                '<p></p>'
            },
            {
              title: "Chia 3 cột",
              description: "Đoạn code chia 3 cột",
              content:
                '<div class="blog-content__3col">'+
                  '<div class="blog-content__3col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                  '<div class="blog-content__3col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                  '<div class="blog-content__3col--col">'+
                    '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ipsam doloremque asperiores alias ex temporibus eligendi deleniti optio, fugiat corrupti repudiandae minus nam, ipsum autem provident voluptatum tempora tempore minima deserunt libero omnis at debitis aspernatur! Odit qui et minima rem alias dolorum delectus quidem. Sint aspernatur hic esse soluta quidem accusamus quas officia ducimus repudiandae, illum consequatur itaque ut. Hic odio reprehenderit laboriosam tenetur provident sequi cupiditate totam! Ab cupiditate blanditiis nemo beatae, iure, quisquam ipsam impedit earum quasi quaerat, molestiae ullam! Vel quo ipsum consequatur asperiores dicta maiores ratione, nesciunt dolore? Molestiae, ad perspiciatis. Autem iste sed est!</p>'+
                  '</div>'+
                '</div>'+
                '<p></p>'
            },
            {
              title: "Tạo form liên hệ",
              description: "Tạo form liên hệ",
              content:
                '<form action="" class="blog-form" onsubmit="return false;">'+
                    '<div class="error-lst alert-danger" style="display: none">'+
                        '<ul>'+
                            '<li></li>'+
                        '</ul>'+
                    '</div>'+
                    '<input type="text" class="form-control" placeholder="Họ và tên" name="Name">'+
                    '<input type="text" class="form-control" placeholder="Email" name="Email">'+
                    '<input type="text" class="form-control" placeholder="Số điện thoại" name="PhoneNumber">'+
                    '<textarea class="form-control" placeholder="Nôi dung" name="Message"></textarea>'+
                    '<button type="button" onclick="addContact(\'.blog-form\')" class="btn btn-primary m20--top btn-submit">Gửi nội dung</button>'+
                    '<input type="hidden" value="Liên hệ từ trang liên hệ" name="Title">'+
                '</form>'
            },
            {
              title: "Tạo nút liên hệ",
              description: "Tạo nut liên hệ",
              content:
                '<div class="btn-container text-center">'+
                  '<a class="btn btn-primay btn-big btn-contact" href="javascript:">Liên hệ</a>'+
                '</div>'
            },
            // {title: 'Nút xanh', description: 'Nút xanh.', content: '<a class="btn btn-green" href="#">Tra cứu forex broker</a>'},
            // {title: 'Khung vàng 2 màu', description: 'Khung vàng 2 màu với chữ bên trong.', content: '<div class="box-yellow-2-line"><p>Khung vàng 2 màu</p></div>'},
            // {title: 'Nút vàng', description: 'Nút vàng.', content: '<a class="btn-yellow" data-toggle="modal" href="#registration-modal"><b>Đặt mua ngay</b><span>Để được tặng quà</span></a>'}
          ],
          template_selected_content_classes: "content-replacement",
        }}
        onEditorChange={handleEditorChange}
      />
    </React.Fragment>
  );
};
