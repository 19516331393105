import React, { FC, useCallback } from "react";
import { Select, Tag } from "antd";
import {  PaymentStatus } from "states/order/order.models";

interface IPaymentStatusProps {
  paymentStatus?: PaymentStatus;
  onChange?: (paymentStatus: PaymentStatus) => void;
}
const { Option } = Select;
export const PaymentStatusComponent: FC<IPaymentStatusProps> = (props) => {
  const { paymentStatus, onChange } = props;
  const _handleOnChange = useCallback(
    (value: number) => {
      onChange && onChange(value);
    },
    [onChange]
  );
  return (
    <Select
      className="w-100"
      placeholder="Trạng thái thanh toán"
      onChange={_handleOnChange}
      allowClear
      value={paymentStatus}
      showSearch
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={PaymentStatus.Unpaid}>
        <Tag color="#f50">Chưa thanh toán</Tag>
      </Option>
      <Option value={PaymentStatus.Paid}>
        <Tag color="#108ee9">Đã thanh toán</Tag>
      </Option>
    </Select>
  );
};
