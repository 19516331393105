import {
  TIMEZONE_API,
} from "shared/constants/api-endpoint";
import { getHttp } from "shared/plugins/http-service";
import {
  TimezoneModel,
} from "./system-setting.models";
export const getTimeZones = async () => {
  return await getHttp<TimezoneModel[]>(`${process.env.REACT_APP_API_URI}${TIMEZONE_API}`);
};
