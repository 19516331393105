import { Empty } from "antd";
import React,{ FC, useEffect } from "react";

const EmptyPage: FC = (props) => {
   useEffect(() => {
       document.title = "Empty";
   },[])
  return <Empty />;
};

export default EmptyPage;
