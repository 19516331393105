import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, notification, Popconfirm } from "antd";
import React from "react";

import { UploadFileLocal } from "shared/components/upload-file/upload-file";
import { IThemeConfigImageItem } from "states/theme-setting/theme-setting.models";

interface ThemeConfigProps {
  model: IThemeConfigImageItem;
  onChange: (model: IThemeConfigImageItem) => void;
  onRemove?: (index: number) => void;
  index?: number;
  name?: string;
  isHideLabel?: boolean;
  isHideRemove?: boolean;
}

export const ThemeImageItem = (props: ThemeConfigProps) => {
  const {
    model,
    index,
    name,
    isHideLabel,
    isHideRemove,
    onChange,
    onRemove,
  } = props;
  const onRemoveBtn = () => {
    onRemove && onRemove(index || 0);
  };
  return (
    <div className="theme-image-item">
      <div className="pb-2 btn-w-child d-flex justify-content-between align-items-center flex-auto ">
        {!isHideRemove && (
          <React.Fragment>
            <Button icon={<CopyOutlined />} type="default" className="mr-2" >{model.name}</Button>
            <Popconfirm
              placement="topLeft"
              onConfirm={onRemoveBtn}
              title={`Bạn có chắc chắn muốn xóa không?`}
              okText="Đồng ý"
              cancelText="Hủy bỏ"
            >
              <Button type="primary" danger={true} icon={<DeleteOutlined />}>
                Xóa phần tử
              </Button>
            </Popconfirm>
          </React.Fragment>
        )}
      </div>
      <div className="pt-2"></div>
      <UploadFileLocal
        album={model.src !== undefined ? [model.src] : []}
        maxFileCount={1}
        multiple={false}
        onChange={(newFileList: string[]) => {
          onChange({ ...model, src: newFileList[0] });
        }}
      />
    </div>
  );
};
