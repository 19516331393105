export interface ContactModel {
  key: string;
  id: number;
  title: string;
  message: string;
  name: string;
  phoneNumber: string;
  email: string;
  creationTime: Date;
  status: ContactStatus;
  lastModificationTime: Date;  
}

export interface UpdatedContactModel {
  id: number;
  status: ContactStatus;
}

export interface SearchContactRequestModel {
  name?: string;
  phone?: string;
  email?: string;
  status?: ContactStatus;
  page: number;
  pageSize: number;
}

export interface RemovedContactModel {
  idList: number[];
}

export enum ContactStatus{
  NeverSeen,
  Seen,
  Done,
}
