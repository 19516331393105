import React, { FC, useState, useEffect } from "react";
import { globalValueSetPageTitle } from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import { Table, Row, Col, Button, message, Drawer, notification } from "antd";

import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { DefaultErrorMessage } from "shared/constants/message";
import {
  getAppPermissions,
  getRoleById,
  getRoles,
  removeRole,
} from "states/role-management/role-management.api";
import { RoleFormModal } from "./components/role-form-modal";
import {
  AppPermissionModel,
  RemovedRoleManagementModel,
  RoleManagementModel,
} from "states/role-management/role-management.models";

interface IRoleManagementProps {}

const RoleManagementPage: FC<IRoleManagementProps> = () => {
  const [isModal, setIsModal] = useState(false);
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedRoleManagementModel>({
    idList: [],
  });
  const [isUpdateRole, setIsUpdateRole] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [rolePagedResults, setRolePagedResults] = useState(
    [] as RoleManagementModel[]
  );
  const [model, setModel] = useState<RoleManagementModel>({
    appPermissions: [] as AppPermissionModel[],
  } as RoleManagementModel);

  const [permissions, setPermissions] = useState<AppPermissionModel[]>([]);

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };
  const dispatch = useDispatch();

  const onUpdateRoleBtn = async (id: string) => {
    try {
      const roleModelResponse = await getRoleById(id);
      setIsModal(true);
      setIsUpdateRole(true);
      setModel(roleModelResponse?.data);
    } catch (error) {
      message.error(error?.response?.message ?? DefaultErrorMessage);
    }
  };
  const onAddBtnClicked = () => {
    setIsModal(true);
    setIsUpdateRole(false);
    setModel({
      appPermissions: [] as AppPermissionModel[],
    } as RoleManagementModel);
  };

  const getAppRoles = async () => {
    setIsLoadingList(true);
    const result = await getRoles();
    result.data.forEach((m) => (m.key = m.id));
    setRolePagedResults(result.data);
    setIsLoadingList(false);
  };

  const onCancelModal = () => {
    setIsModal(false);
  };
  const onCloseModal = () => {
    setIsModal(false);
  };

  const onModifier = () => getAppRoles();

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={() => {
          removeRole(selectedRowsKeys)
            .then(() => {
              notification.close(key);
              setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
              getAppRoles();
              message.success("Xóa thành công");
            })
            .catch(() => {
              message.error("Xóa thất bại");
            });
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: "Bạn có chắc chắn muốn xóa không ?",
      key,
      btn,
      placement: "bottomLeft",
    });
  };
  const columns: ColumnsType<RoleManagementModel> = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (name: string, item: RoleManagementModel) => (
        <React.Fragment>
          <Button
            type="text"
            onClick={() => {
              onUpdateRoleBtn(item.id);
            }}
          >
            {name}
          </Button>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    dispatch(globalValueSetPageTitle("Phân quyền"));
    getAppPermissions().then((permissions) => {
      setPermissions(permissions.data);
    });
    getAppRoles();
  }, []);

  return (
    <div className="role-management-page">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}></Col>
        <Col span={12} className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={onAddBtnClicked}
            icon={<PlusCircleOutlined />}
          >
            Thêm phân quyền mới
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={rolePagedResults}
        size="small"
        loading={isLoadingList}
      ></Table>
      <RoleFormModal
        visible={isModal}
        isUpdate={isUpdateRole}
        onCancel={onCancelModal}
        onClose={onCloseModal}
        onModifier={onModifier}
        model={model}
        permissions={permissions}
      ></RoleFormModal>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default RoleManagementPage;
