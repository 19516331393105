export const IsDuplicateObject = (arr: [], name: string) => {
  let valueArr = arr.map(function (item: any) {
    return item[name];
  });
  let isDuplicate = valueArr.some(function (item: any, index: number) {
    return valueArr.indexOf(item) != index;
  });
  return isDuplicate;
};
export const makeId = (length: number) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const limitStringLength = (
  str: string,
  length: number,
  lengthLimit: number
) => {
  if (lengthLimit <= 0) lengthLimit = 100;
  if (str.length > lengthLimit) {
    str = str.substring(0, length) + "...";
  }
  return str;
};
export const priceWithCommas = (price: string) => {
  return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
/** Chuyển thành dạng URL string*/
export const cleanUnicode = (str: string) => {
  var str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(
    /!|@@|\$|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\'| |\"|\&|\#|\[|\]|\;|\||\{|\}|\~|\“|\”|\™|\–|\-/g,
    "-"
  );
  str = str.replace(/^\-+|\-+$/g, "");
  str = str.replace(/\\/g, "");
  str = str.replace(/-+-/g, "-");
  return str;
};

/**Kiểm tra 1 chuỗi có phải email hay không */
export const isEmail = (email: string) => {
  var str = email.toLowerCase();
  var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(str);
};

/**Tạo random string bất kì */
export const randomString = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

/**Format kiểu tiền tệ
 * @param {Number} c - Con số làm tròn mặc là 0
 * @param {String} d - Ký tự phân cách thập phân
 * @param {String} t - Ký tự phân cách hàng nghìn
 * @param {String} f - Ký tự đơn vị tiền tệ
 */
export const formatMoney = (
  n: any,
  c: number = 0,
  d: string = ",",
  t: string = ".",
  f: string = "₫"
) => {
 
  let j = 0;
  c = Math.abs(c);
  let s = n < 0 ? "-" : "";
  let i: any = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "";
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (f !== "₫" ? f : "") +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "") +
    (f === "₫" ? " " + f : "")
  );
};

export function vnMoneyToNumber(money: any) {
  return money.replace(/\D/g, "");
}

export function redirectFileUrl(fileId: string) {
  return `${process.env.REACT_APP_API_URI}/api/v1/files/directs/${fileId}`;
}

export function redirectFileLocalUrl(filePath: string) {
  return `${process.env.REACT_APP_API_URI}/${filePath}`;
}
