import {
  TREATMENT_API,
  TREATMENT_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedTreatmentModel,
  SearchTreatmentRequestModel,
  TreatmentModel,
  UpdatedTreatmentModel,
  CreatedTreatmentModel,
  UpdatedTreatmentStatusModel,
} from "./treatment.models";

export const searchTreatment = async (
  searchTreatmentRequestModel: SearchTreatmentRequestModel
) => {
  const createResponse = await postHttp<PagedResult<TreatmentModel>>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API_SEARCH}`,
    searchTreatmentRequestModel
  );
  return createResponse;
};
export const updateTreatmentStatus = async (model: UpdatedTreatmentStatusModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API}/update_status`,
    model
  );
  return response;
};


export const removeTreatment = async (
  removedModel: RemovedTreatmentModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addTreatment = async (model: CreatedTreatmentModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API}`,
    model
  );
  return response;
};

export const getTreatmentById = async (id: number) => {
  return await getHttp<TreatmentModel>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API}/${id}`
  );
};

export const updateTreatment = async (model: UpdatedTreatmentModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_API}`,
    model
  );
  return response;
};
