import { TAG_API } from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { RemovedTagModel, TagModel } from "./tag.models";

export const getTagList = async () => {
  const createResponse = await getHttp<TagModel[]>(
    `${process.env.REACT_APP_API_URI}${TAG_API}`
  );
  return createResponse;
};

export const removeTag = async (removedModel: RemovedTagModel) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TAG_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getTagById = async (id: number) => {
  return await getHttp<TagModel>(
    `${process.env.REACT_APP_API_URI}${TAG_API}/${id}`
  );
};

export const addTag = async (model: TagModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${TAG_API}`,
    model
  );
  return response;
};

export const updateTag = async (model: TagModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TAG_API}`,
    model
  );
  return response;
};
