import React, { FC, useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
} from "antd";

import {  DeleteOutlined,  PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  RemovedThemeSettingModel,
  SearchThemeSettingRequestModel,
  ThemeSettingModel,
} from "states/theme-setting/theme-setting.models";
import {
  removeThemeSetting,
  searchThemeSetting,
} from "states/theme-setting/theme-setting.api";
import { DefaultErrorMessage, QuestionRemove, UpdateDataSuccessfully } from "shared/constants/message";
import { Link } from "react-router-dom";
const ThemeSetting: FC = () => {
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<RemovedThemeSettingModel>({
    idList: [],
  });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<ThemeSettingModel>
  );
  const [filterModel, setFilterModel] = useState<SearchThemeSettingRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách giao diện";
  }, []);
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchThemeSetting({ ...filterModel, page });
    result.data.results.forEach((model) =>{
      model.keyName = model.key;
      model.key = model.id.toString();
    })
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async() => {
          try{
            await removeThemeSetting(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          }catch(error){
            message.error(error?.response?.data?.error?.message ?? DefaultErrorMessage);
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<ThemeSettingModel> = [
    {
      title: "Tên giao diện",
      key: "abc1",
      render: (item: ThemeSettingModel) => (
        <React.Fragment>
          <Link to={`/theme-setting/update/${item.id}`}>{item.data.name}</Link>
        </React.Fragment>
      ),  
    },
    {
      title: "THEME_KEY",
      key: "abc",
      render:(item: ThemeSettingModel) => (
        <React.Fragment>
          <Link to={`/theme-setting/update/${item.id}`}>{item.keyName}</Link>
        </React.Fragment>
      ),  
    }
  ];
  return (
    <div className="theme-setting">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.key}
            placeholder="Nhập tên giao diện cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, key: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12} className="d-flex justify-content-end">
            <Link to="/theme-setting/add">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Thêm giao diện
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default ThemeSetting;

