import {
  AccountState,
  AccountActionType,
  Account_SetLoginUser,
  Account_LogoutUser,
} from "./account.models";

export function accountReducer(
  state = {} as AccountState,
  action: AccountActionType
): AccountState {
  switch (action.type) {
    case Account_SetLoginUser:
      return {
        ...state,
        currentUser: action.payload,
        isAuthentication: !!action.payload,
      };
    case Account_LogoutUser:
      return {
        ...state,
        currentUser: action.payload,
        isAuthentication: false,
      };
    default:
      return state;
  }
}
