import React, { FC, useState, useEffect, } from "react";
import { Input, Select, Modal, Typography, Row, Col } from "antd";
import _ from "lodash";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { CreatedMultipleTreatmentProductModel, CreatedTreatmentProductModel, TreatmentProductModel,  UpdatedTreatmentProductModel } from "states/treatment/treatment-product.models";
import { addMultipleTreatmentProduct, updateTreatmentProduct } from "states/treatment/treatment-product.api";
import { ProductModel, RemovedProductModel, SearchProductRequestModel } from "states/product/product.models";
import { PagedResult } from "states/paged-result-model";
import { searchProduct } from "states/product/product.api";
import { useDebouncedCallback } from "use-debounce/lib";
import Table, { ColumnsType } from "antd/lib/table";
import { searchProductCategory } from "states/product-category/product-category.api";
import { ProductCategoryModel, SearchProductCategoryRequestModel } from "states/product-category/product-category.models";
import ProductCategory from "modules/product-category/product-category";
const { Option} = Select;
interface ITreatmentAddProductModalProps {
  treatmentProducts: TreatmentProductModel[];
  onModal: boolean;
  closeModal: () => void;
  treatmentId: string;
  onSuccess: () => void;
}
export const TreatmentAddProductModalComponent: FC<ITreatmentAddProductModalProps> = (props) => {
  const { treatmentProducts, onModal,treatmentId, closeModal,onSuccess} = props;
  const [categories, setCategories] = useState<PagedResult<ProductCategoryModel>>({} as PagedResult<ProductCategoryModel>)
  const [filterModel, setFilterModel] = useState<SearchProductRequestModel>({
    page: 1,
    pageSize: 20,
  });
  const [
    selectedRowsKeys,
    setSelectedRowsKeys,
  ] = useState<CreatedMultipleTreatmentProductModel>({
    createTreatmentProducts: [],
  });
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<ProductModel>
  );
  const [isLoading, setLoading] = useState(false);
  const handleCloseModal = () => closeModal();
  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any[]) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, createTreatmentProducts: selectedRowKeys.map((productId: string, index: number)=>{return {
        productId: Number(productId),
        treatmentId: Number(treatmentId),
        sort: index,
        tutorial: "",
        usedTime: 0,
      } as CreatedTreatmentProductModel}) });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, createTreatmentProducts: [] });
  };
  
  const onSubmit = () => {
    onFinish();
  };
  useEffect(() => {
    handleSearch.callback();
  }, [filterModel,treatmentProducts]);
  useEffect(() => {
    searchProductCategory({
      name: "",
      page:1,
      pageSize: 100,
    } as SearchProductCategoryRequestModel)
    .then((response) =>{
      setCategories(response.data);
    });
   
  }, []);

  const gotoPage = async (page: number) => {
    setLoading(true);
    const result = await searchProduct({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setLoading(false);
    setPagedResults(result.data);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);
  const onFinish = async () => {
    setLoading(true);
    try {
      await addMultipleTreatmentProduct(selectedRowsKeys);
      handleCloseModal();
      setLoading(false);
      onSuccess();
      onEmptySelection();
      Modal.success({
        title: 'Thành công',
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: 'Thông báo',
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const columns: ColumnsType<ProductModel> = [
    {
      title: "Tên sản phẩm",
      dataIndex: "websiteName",
      key: "websiteName",
      render: (websiteName: string, item) => (
        <Typography.Text>
        {websiteName}
      </Typography.Text>
      ),  
    }
  ];

  return (
    <Modal
      width="1000px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {`Thêm sản phẩm điều trị`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={12}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên sản phẩm cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={12}>
          <Select
            placeholder="Chọn danh mục"
            optionFilterProp="children"
            className="w-100"
            allowClear={true}
            value={filterModel.categoryId}
            onChange={(e) => {
              setFilterModel({ ...filterModel, categoryId: e?.valueOf() ?? undefined });
            }}
          >
            {categories.results?.map((m) => (
              <Option value={m.id}>
                {m.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        rowKey={record => record.key}
        scroll={{y: 400}}
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoading}
      ></Table>
      
    </Modal>
  );
};
