import React, { FC, useState, useEffect } from "react";
import {
  globalValueSetPageTitle,
  globalValueShowErrorToast,
  globalValueShowWarningToast,
} from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
} from "antd";

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedContactModel,
  SearchContactRequestModel,
  ContactModel,
  ContactStatus,
} from "states/contact/contact.models";
import {
  removeContact,
  searchContact,
  updateContact,
} from "states/contact/contact.api";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import moment from "moment";

interface IContactProps {}
const { Option } = Select;
const Contact: FC<IContactProps> = () => {
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<RemovedContactModel>(
    {
      idList: [],
    }
  );
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<ContactModel>
  );
  const [filterModel, setFilterModel] = useState<SearchContactRequestModel>({
    page: 1,
    pageSize: 20,
  });

  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);

  useEffect(() => {
    document.title = "Liên hệ";
  }, []);
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchContact({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleChangeStatus = async (id: number, status: ContactStatus) => {
    setIsLoadingList(true);
    try {
      await updateContact({ id: id, status: status });
      setIsLoadingList(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
        onOk: () => {
          handleSearch.callback();
        },
      });
    } catch (e) {
      setIsLoadingList(false);
      Modal.error({
        title: "Thông báo",
        content: e?.response?.data?.error?.message ?? DefaultErrorMessage,
        onOk: () => {
          handleSearch.callback();
        },
      });
    }
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeContact(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const expandedRowRender = (data: ContactModel) => {
    return <Typography.Text>{data.message}</Typography.Text>;
  };
  const columns: ColumnsType<ContactModel> = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (title: string) => <Typography.Text>{title}</Typography.Text>,
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phone: string) => (
        <Typography.Text>{phone}</Typography.Text>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
        <Typography.Text>{email}</Typography.Text>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (creationTime: Date) => (
        <Typography.Text>{moment(creationTime).format("YYYY/MM/DD hh:mm:ss A")}</Typography.Text>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status: ContactStatus, item) => (
        <React.Fragment>
          <Select
            placeholder="Chọn trạng thái"
            optionFilterProp="children"
            value={status}
            onChange={(e) => {
              let modal = Modal.confirm({
                title: "Xác nhận",
                icon: <ExclamationCircleOutlined />,
                content: "Bạn có muốn thay đổi trạng thái không?",
                okText: "Đồng ý",
                visible: false,
                onOk: (ev) => {
                  handleChangeStatus(item.id, e.valueOf());
                  modal.destroy();
                },
                cancelText: "Huỷ bỏ",
              });
            }}
          >
            <Option value={ContactStatus.NeverSeen}>
              <Tag color="#f50">Chưa xem</Tag>
            </Option>
            <Option value={ContactStatus.Seen}>
              <Tag color="#108ee9">Đã xem</Tag>
            </Option>
            <Option value={ContactStatus.Done}>
              <Tag color="#87d068">Hoàn thành</Tag>
            </Option>
          </Select>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="contact">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={6}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.email}
            placeholder="Nhập email cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, email: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Input.Search
            value={filterModel.phone}
            placeholder="Nhập phone cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, phone: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Select
            placeholder="Chọn trạng thái"
            optionFilterProp="children"
            allowClear={true}
            value={filterModel.status}
            onChange={(e) => {
              setFilterModel({ ...filterModel, status: e?.valueOf() ?? undefined });
            }}
          >
           <Option value={ContactStatus.NeverSeen}>
              <Tag color="#f50">Chưa xem</Tag>
            </Option>
            <Option value={ContactStatus.Seen}>
              <Tag color="#108ee9">Đã xem</Tag>
            </Option>
            <Option value={ContactStatus.Done}>
              <Tag color="#87d068">Hoàn thành</Tag>
            </Option>
          </Select>
        </Col>
      </Row>
      <Table
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table>
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default Contact;
