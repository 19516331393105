import {
  AppSettingState,
  CurrencyType,
  LanguageType,
  AppSettingActionType,
  SET_DEFAULT_SETTING,
  SET_ACTIVE_SIDEBAR,
} from "./app-setting.models";

const initialState: AppSettingState = {
  currencyType: CurrencyType.VND,
  language: LanguageType.English,
  logoUrl: "/cms-app/images/logo.png",
  activatedMenu: "",
};

export function appSettingReducer(
  state = initialState,
  action: AppSettingActionType
): AppSettingState {
  switch (action.type) {
    case SET_DEFAULT_SETTING:
      return {
        ...state,
        currencyType: CurrencyType.USD,
      };
    case SET_ACTIVE_SIDEBAR:
      return {
        ...state,
        activatedMenu: action.activeValue,
      };
    default:
      return state;
  }
}
