import React, { FC, useState, useEffect } from "react";
import {
  globalValueSetPageTitle,
  globalValueShowErrorToast,
  globalValueShowWarningToast,
} from "states/global-value/global-value.action";
import { useDispatch } from "react-redux";

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  message,
  Drawer,
  notification,
  Select,
  Tag,
  Typography,
  Modal,
  DatePicker,
} from "antd";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { PagedResult } from "states/paged-result-model";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  RemovedOrderModel,
  SearchOrderRequestModel,
  OrderModel,
  OrderStatus,
  PaymentStatus,
} from "states/order/order.models";
import { removeOrder, searchOrder } from "states/order/order.api";
import moment from "moment";
import { Link } from "react-router-dom";
import { BlogCategoryModel } from "states/blog-category/blog-category.models";
import { searchBlogCategory } from "states/blog-category/blog-category.api";
import {
  DefaultErrorMessage,
  QuestionRemove,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { OrderStatusComponent } from "./components/order-status";
import { PaymentStatusComponent } from "./components/payment-status";

interface IOrderProps {}
const { Option } = Select;
const Order: FC<IOrderProps> = () => {
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<RemovedOrderModel>({
    idList: [],
  });
  const dispatch = useDispatch();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<OrderModel>
  );

  const [filterModel, setFilterModel] = useState<SearchOrderRequestModel>({
    page: 1,
    pageSize: 20,
  });
  const [blogCategories, setBlogCategories] = useState<BlogCategoryModel[]>([]);
  const rowSelection = {
    selectedRowsKeys,
    onChange: (selectedRowKeys: any) => {
      setSelectedRowsKeys({ ...selectedRowsKeys, idList: selectedRowKeys });
    },
  };
  const getBlogCategoryies = async () => {
    try {
      const res = await searchBlogCategory({
        name: "",
        page: 1,
        pageSize: 1000,
      });
      setBlogCategories(res.data.results);
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? DefaultErrorMessage
      );
    }
  };
  const onEmptySelection = () => {
    setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
  };

  useEffect(() => {
    handleSearch.callback();
  }, [filterModel]);
  useEffect(() => {
    document.title = "Danh sách đơn hàng";
  }, []);
  const gotoPage = async (page: number) => {
    setIsLoadingList(true);
    const result = await searchOrder({ ...filterModel, page });
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
    setIsLoadingList(false);
  };
  const handleSearch = useDebouncedCallback(() => {
    gotoPage(1);
  }, 500);

  const onRemoveBtn = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        danger={true}
        onClick={async () => {
          try {
            await removeOrder(selectedRowsKeys);
            notification.close(key);
            setSelectedRowsKeys({ ...selectedRowsKeys, idList: [] });
            handleSearch.callback();
            message.success(UpdateDataSuccessfully);
          } catch (error) {
            message.error(
              error?.response?.data?.error?.message ?? DefaultErrorMessage
            );
          }
        }}
      >
        Xóa
      </Button>
    );
    notification.open({
      message: QuestionRemove,
      key,
      btn,
      placement: "bottomLeft",
    });
  };

  const columns: ColumnsType<OrderModel> = [
    {
      title: "Mã đơn hàng",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (orderNumber: string, item) => (
        <React.Fragment>
          <Link to={`/order/update/${item.id}`}>{orderNumber}</Link>
        </React.Fragment>
      ),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (creationTime: string) => (
        <React.Fragment>
          {moment(creationTime).format("YYYY-MM-DD hh:mm:ss A")}
        </React.Fragment>
      ),
    },
    {
      title: "Lần cập nhật cuối cùng",
      dataIndex: "lastModificationTime",
      key: "lastModificationTime",
      render: (lastModificationTime: string) => (
        <React.Fragment>
          {lastModificationTime !== null ? moment(lastModificationTime).format("YYYY-MM-DD hh:mm:ss A"): ""}
        </React.Fragment>
      ),
    },
    {
      title: "Trạng thái đơn hàng",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (orderStatus: OrderStatus) => (
        <OrderStatusComponent orderStatus={orderStatus} />
      ),
    },
    {
      title: "Trạng thái thanh toán",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (paymentStatus: PaymentStatus) => (
        <PaymentStatusComponent paymentStatus={paymentStatus} />
      ),
    },
  ];
  return (
    <div className="blog">
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={4}>
          <Input.Search
            value={filterModel.orderNumber}
            placeholder="Nhập mã đơn hàng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, orderNumber: e.target.value });
            }}
          ></Input.Search>
        </Col>
        <Col span={4}>
          <Input.Search
            value={filterModel.name}
            placeholder="Nhập tên khách hàng cần tìm..."
            onChange={(e) => {
              setFilterModel({ ...filterModel, name: e.target.value });
            }}
          ></Input.Search>
        </Col>

        <Col span={4}>
          <OrderStatusComponent
            orderStatus={filterModel?.orderStatus}
            onChange={(value: number) => {
              setFilterModel({ ...filterModel, orderStatus: value });
            }}
          />
        </Col>
        <Col span={4}>
          <PaymentStatusComponent
            paymentStatus={filterModel?.paymentStatus}
            onChange={(value: number) => {
              setFilterModel({ ...filterModel, paymentStatus: value });
            }}
          />
        </Col>
        <Col span={4}>
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Đơn hàng từ ngày"
            onChange={(value) => {
              setFilterModel({
                ...filterModel,
                fromDate:
                  value === null ? "" : moment(value).format("DD/MM/YYYY"),
              });
            }}
          />
        </Col>
        <Col span={4}>
          <DatePicker
            format="DD-MM-YYYY"
            className="w-100"
            placeholder="Đơn hàng đến ngày"
            onChange={(value) => {
              setFilterModel({
                ...filterModel,
                toDate:
                  value === null ? "" : moment(value).format("DD/MM/YYYY"),
              });
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={pagedResults.results}
        size="small"
        pagination={{
          total: pagedResults.totalItemCount,
          pageSize: pagedResults.pageSize,
          current: pagedResults.currentPage,
          onChange: gotoPage,
        }}
        loading={isLoadingList}
      ></Table> 
      <Drawer
        height="70px"
        mask={false}
        placement="bottom"
        closable={true}
        onClose={onEmptySelection}
        visible={
          selectedRowsKeys !== undefined &&
          selectedRowsKeys.idList !== undefined &&
          selectedRowsKeys.idList.length > 0
            ? true
            : false
        }
      >
        <Button
          type="primary"
          danger={true}
          onClick={onRemoveBtn}
          icon={<DeleteOutlined />}
        >
          Xóa {selectedRowsKeys.idList.length} dòng đang chọn
        </Button>
      </Drawer>
    </div>
  );
};

export default Order;
