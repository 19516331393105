import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface IShippingFeeMenuProps {
  activeKey: string;
}

export function ShippingFeeMenu(props: IShippingFeeMenuProps) {
  const { activeKey } = props;
  return (
    <Menu selectedKeys={[activeKey]} mode="horizontal" className="mb-4">
      <Menu.Item key="1" icon={<MailOutlined />}>
        <Link to={`/shipping-fee/default`}>Phí ship mặc định</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        <Link to={`/shipping-fee/by-address`}>Phí ship theo quận huyện</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<AppstoreOutlined />}>
        <Link to={`/shipping-fee/by-order-amount`}>
          Phí ship theo tiền hàng
        </Link>
      </Menu.Item>
    </Menu>
  );
}
