import {
  PRODUCT_CATEGORY_API,
  PRODUCT_CATEGORY_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  CreateProductCategoryModel,
  RemovedProductCategoryModel,
  SearchProductCategoryRequestModel,
  ProductCategoryModel,
  UpdatedProductCategoryModel,
} from "./product-category.models";

export const searchProductCategory = async (
  searchProductCategoryRequestModel: SearchProductCategoryRequestModel
) => {
  const createResponse = await postHttp<PagedResult<ProductCategoryModel>>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_CATEGORY_API_SEARCH}`,
    searchProductCategoryRequestModel
  );
  return createResponse;
};

export const removeProductCategory = async (
  removedModel: RemovedProductCategoryModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_CATEGORY_API}/remove_list`,
    removedModel
  );
  return response;
};

export const getProductCategoryById = async (id: number) => {
  return await getHttp<ProductCategoryModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_CATEGORY_API}/${id}`
  );
};

export const addProductCategory = async (model: CreateProductCategoryModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_CATEGORY_API}`,
    model
  );
  return response;
};

export const updateProductCategory = async (
  model: UpdatedProductCategoryModel
) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_CATEGORY_API}`,
    model
  );
  return response;
};
