import {
  PRODUCT_PRICE_FILTER_API,
  PRODUCT_PRICE_FILTER_API_REMOVE,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import {
  RemovedProductPriceFilterModel,
  ProductPriceFilterModel,
} from "./product-price-filter.models";

export const getProductPriceFilterList = async () => {
  const createResponse = await getHttp<ProductPriceFilterModel[]>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_PRICE_FILTER_API}`
  );
  return createResponse;
};

export const removeProductPriceFilter = async (
  removedModel: RemovedProductPriceFilterModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${PRODUCT_PRICE_FILTER_API_REMOVE}`,
    removedModel
  );
  return response;
};

export const getProductPriceFilterById = async (id: number) => {
  return await getHttp<ProductPriceFilterModel>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_PRICE_FILTER_API}/${id}`
  );
};

export const addProductPriceFilter = async (model: ProductPriceFilterModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_PRICE_FILTER_API}`,
    model
  );
  return response;
};

export const updateProductPriceFilter = async (
  model: ProductPriceFilterModel
) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${PRODUCT_PRICE_FILTER_API}`,
    model
  );
  return response;
};
