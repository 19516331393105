import React, { FC, useState, useEffect } from "react";
import { Input, Select, Modal, Form, Switch } from "antd";

import {
  CreateStaffModel,
  StaffModel,
  UpdatedStaffModel,
} from "states/staff/staff.models";
import { addStaff, updateStaff } from "states/staff/staff.api";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { useForm } from "antd/lib/form/Form";
import { AppRoleModel } from "states/user-management/user-management.models";
import { getRoles } from "states/user-management/user-management.api";

interface IStaffModalProps {
  model: StaffModel;
  onModal: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  isUpdate: boolean;
}
export const StaffModalComponent: FC<IStaffModalProps> = (props) => {
  const { model, onModal, closeModal, onSuccess, isUpdate } = props;
  const [form] = useForm();
  const [isLoading, setLoading] = useState(false);
  const [roleDropdown, setRoleDropdown] = useState<AppRoleModel[]>([]);

  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      if (isUpdate) {
        let payload: UpdatedStaffModel = {
          ...values,
          id: values.id,
          name: values.name,
          phone: values.phone,
          email: values.email,
          isActive: values.isActive,
          roleIds: values.roleIds,
          isAccessAll: values.isAccessAll,
        };
        await updateStaff(payload);
      } else {
        let payload: CreateStaffModel = {
          ...values,
          name: values.name,
          phone: values.phone,
          email: values.email,
          isActive: values.isActive,
          roleIds: values.roleIds,
          isAccessAll: values.isAccessAll,
        };
        await addStaff(payload);
      }
      handleCloseModal();
      setLoading(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
      onSuccess();
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = () => {
    form.submit();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      id: model?.id,
      email: model?.email,
      phone: model?.phone,
      name: model?.name,
      isActive: model?.isActive,
      roleIds: model?.roleIds,
      isAccessAll: model?.isAccessAll,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      onReset();
    } else {
      onFill();
    }
  }, [onModal]);

  useEffect(() => {
    getRoles().then((res) => {
      setRoleDropdown(res.data);
    });
  }, []);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onBlur={() => {}}
          // end
        >
          {isUpdate ? `Cập nhật nhân viên` : `Thêm nhân viên`}
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="StaffModal"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        initialValues={{ isActive: true }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Tên nhân viên"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập tên nhân viên!" }]}
        >
          <Input className="w-100" placeholder="Tên nhân viên" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Vui lòng nhập địa chỉ email!" }]}
        >
          <Input className="w-100" placeholder="Địa chỉ email" />
        </Form.Item>
        <Form.Item label="Số điện thoại" name="phone">
          <Input className="w-100" placeholder="Số điện thoại" />
        </Form.Item>
        <Form.Item label="Phân quyền" name="roleIds">
          <Select
            mode="multiple"
            className="w-100"
            placeholder="Phân quyền"
            defaultValue={model?.roleIds ?? []}
            filterOption={(input, option) =>
              !!option?.label &&
              option?.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {roleDropdown.map((appRole) => (
              <Select.Option
                key={appRole.id}
                value={appRole.id}
                label={appRole.name}
              >
                {appRole.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Kích hoạt" name="isActive" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Quyền truy cập" name="isAccessAll" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item hidden={true} name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
