import {
  TREATMENT_SCHEDULE_API,
  TREATMENT_SCHEDULE_API_SEARCH,
} from "shared/constants/api-endpoint";
import { getHttp, postHttp, putHttp } from "shared/plugins/http-service";
import { PagedResult } from "states/paged-result-model";
import {
  RemovedTreatmentScheduleModel,
  SearchTreatmentScheduleRequestModel,
  TreatmentScheduleModel,
  UpdatedTreatmentScheduleModel,
  CreatedTreatmentScheduleModel,
  UpdatedTreatmentScheduleStatusModel,
} from "./treatment-schedule.models";

export const searchTreatmentSchedule = async (
  searchTreatmentScheduleRequestModel: SearchTreatmentScheduleRequestModel
) => {
  const createResponse = await postHttp<PagedResult<TreatmentScheduleModel>>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API_SEARCH}`,
    searchTreatmentScheduleRequestModel
  );
  return createResponse;
};
export const removeTreatmentSchedule = async (
  removedModel: RemovedTreatmentScheduleModel
) => {
  const response = await postHttp(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API}/remove_list`,
    removedModel
  );
  return response;
};

export const addTreatmentSchedule = async (model: CreatedTreatmentScheduleModel) => {
  const response = await postHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API}`,
    model
  );
  return response;
};
export const updateTreatmentScheduleStatus = async (model: UpdatedTreatmentScheduleStatusModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API}/update_status`,
    model
  );
  return response;
};
export const getTreatmentScheduleById = async (id: number) => {
  return await getHttp<TreatmentScheduleModel>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API}/${id}`
  );
};

export const updateTreatmentSchedule = async (model: UpdatedTreatmentScheduleModel) => {
  const response = await putHttp<number>(
    `${process.env.REACT_APP_API_URI}${TREATMENT_SCHEDULE_API}`,
    model
  );
  return response;
};
