import React, { FC, useState, useEffect } from "react";
import { Modal, Form, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import {
  DefaultErrorMessage,
  UpdateDataSuccessfully,
} from "shared/constants/message";
import { SendNotificationCustomerGroupModel } from "states/notification-manager/notification-manager.models";
import { sendNotificationCustomerGroup } from "states/notification-manager/notification-manager.api";
import { PagedResult } from "states/paged-result-model";
import {
  CustomerGroupModel,
  SearchCustomerGroupRequestModel,
} from "states/customer-group/customer-group.models";
import { searchCustomerGroup } from "states/customer-group/customer-group.api";
import _ from "lodash";

interface ICustomerGroupSelectModalProps {
  notificationId: number | string;
  onModal: boolean;
  closeModal: () => void;
}
const { Option } = Select;
export const CustomerGroupSelectModalComponent: FC<ICustomerGroupSelectModalProps> = (
  props
) => {
  const { notificationId, onModal, closeModal } = props;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [customerGroupId, setCustomerGroupId] = useState<number | string>("");
  const [pagedResults, setPagedResults] = useState(
    {} as PagedResult<CustomerGroupModel>
  );
  const handleCloseModal = () => closeModal();
  const onFinish = async (values: Store) => {
    setLoading(true);
    try {
      let payload: SendNotificationCustomerGroupModel = {
        id: notificationId,
        customerGroupId: customerGroupId,
      };
      await sendNotificationCustomerGroup(payload);
      handleCloseModal();
      setLoading(false);
      Modal.success({
        title: "Thành công",
        content: UpdateDataSuccessfully,
      });
    } catch (error) {
      Modal.error({
        title: "Thông báo",
        content: error?.response?.data?.error?.message ?? DefaultErrorMessage,
      });
      setLoading(false);
    }
  };
  const onSubmit = () => {
    form.submit();
  };
  const onFill = () => {};
  const getCustomerGroups = async () => {
    const result = await searchCustomerGroup({
      page: 1,
      pageSize: 1000,
    } as SearchCustomerGroupRequestModel);
    result.data.results.forEach((m) => (m.key = m.id.toString()));
    setPagedResults(result.data);
  };
  useEffect(() => {
    onFill();
  }, [onModal]);
  useEffect(() => {
    getCustomerGroups();
  }, []);
  return (
    <Modal
      width="500px"
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
        >
          Gửi thông báo đến nhóm khách hàng
        </div>
      }
      confirmLoading={isLoading}
      centered={true}
      visible={onModal}
      onOk={onSubmit}
      onCancel={handleCloseModal}
      okText="Lưu"
      cancelText="Hủy bỏ"
    >
      <Form
        form={form}
        name="notificationManagerModal"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label="Nhóm khách hàng"
          name="customerGroup"
          rules={[
            { required: true, message: "Vui lòng chọn nhóm khách hàng!" },
          ]}
        >
          <Select
            className="w-100"
            placeholder="Chọn nhóm khách hàng"
            onChange={(value: number | string) => {
              setCustomerGroupId(value);
            }}
            allowClear
          >
            {_.map(pagedResults?.results, (m) => (
              <Option value={m.id}>{m.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
